/* eslint-disable react-hooks/exhaustive-deps */
import LoadingContent from 'components/LoadingContent';
import { useFormik } from 'formik';
import React from 'react';
import TopProcessBar from 'screen/selectCollege/TopProcessBar';
import { Container, Form, Row, Spinner, Button } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { routers } from 'router/router';
import { logout } from 'app/basicInformationSlice/basicInformationSlice';
import { authenticateSettings } from 'components/helper/appConfig';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import { getGradeAndCourse, updateGradeAndCourse } from 'app/course/courseSlice';
import CheckboxInput from 'components/input/CheckboxInput';
import CourseConfig from '../CourseConfig';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { HtmlTooltip } from 'screen/selectCollege/SelecCollegePage';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { debounce } from 'lodash';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { reorder } from 'components/helper/helper';
import { getHighSchoolData } from 'app/highSchoolSlice/highSchoolSlice';
import { chkSameGrade, countSameGrade, getSchoolGradeItems } from '../SubjectConfig';

interface Props {}

const NinthGrade = (props: Props) => {
  const [loading, setLoading] = React.useState(false);
  const [showWarning, setShowWarning] = React.useState(false);
  const [errorArr, setErrorArr] = React.useState<any[]>([]);
  const course = useAppSelector((store) => store.course);
  const dispatch = useAppDispatch();
  const history = useHistory();
  let studentId = Cookies.get('userId');
  let token = Cookies.get(authenticateSettings.tokenName);

  const [courseData, setCourseData] = React.useState<any[]>([]);
  const formik = useFormik({
    initialValues: {
      grade_9_course_array: '',
      is_reported_all_grade_9: false,
    },
    onSubmit: async (values) => {
      studentId = Cookies.get('userId');
      token = Cookies.get(authenticateSettings.tokenName);
      if (token && studentId) {
        try {
          const newValues = {
            ...values,
            student_id: studentId,
            grade_9_course_array: JSON.stringify(courseData),
          };
          await dispatch(updateGradeAndCourse(newValues));
        } catch (ex) {
          console.log('Error:', ex);
        }
      } else {
        handleLogout();
      }
    },
  });
  const handleLogout = () => {
    dispatch(logout());
    Cookies.remove(authenticateSettings.tokenName);
    Cookies.remove('userId');
    history.push(routers.login.path);
  };

  const handleContinue = () => {
    formik.handleSubmit();
    history.push(routers.grade10.path);
  };
  const getDetail = async () => {
    const gradeLevel = '9th';
    const gradeMaxCount = 4;
    studentId = Cookies.get('userId');
    token = Cookies.get(authenticateSettings.tokenName);
    if (token && studentId) {
      try {
        setLoading(true);
        let arr:any[] = [];
        const resHighSchool = await dispatch(getHighSchoolData(studentId)).unwrap();
        if (resHighSchool) {
          let highschoolData:any[] = resHighSchool.highschoolData;
          let itemArr:any[] | null = getSchoolGradeItems(highschoolData, gradeLevel); 
  
          if (itemArr === null) {
            arr.push({ value: 'AddHighSchool', name: 'Please add high school on the High School page.' });
          }
          else {
            if (itemArr && itemArr.length>1) {
              for (let j=0; j< itemArr.length; j++) {
                let item:any = itemArr[j].item;
                let item_idx:number = itemArr[j].index;
                if (item) {
                  let countArr = countSameGrade(item, gradeLevel);
    
                  if (countArr && countArr.length>1) {
                    let gradeObj = countArr[countArr.length-1];
                    arr.push({ value: `remove_term${item_idx}_${gradeObj?.index}`, name: `Please remove asleast one term/year has same ${gradeLevel} grade on the High School page.` });
                  }
                } 
              }
            } 
            //else
            if (highschoolData && highschoolData.length>0) {
              let obj:any = null;
              for (let i=0; i< highschoolData.length; i++ ) {
                let item:any = highschoolData[i];
                obj = chkSameGrade(item, gradeLevel);
                if (obj) {
                    let gradeIdx:number = obj.index;
                    //console.log('item:', item);
                    if (!item.high_school_object) {
                      arr.push({value: `cardItem${i}`, name: 'Please input high school information on the High School page.'});
                    }
                    else
                    if (item.high_school_object && item.high_school_object.school_name === "") {
                      arr.push({value: `cardItem${i}`, name: 'Please input high school information on the High School page.'});
                    }
                    else
                    if (!item.start_date) {
                      arr.push({ value: `start_date${i}`, name: 'Please enter the Start date on the High School page.'});
                    } 
                    else
                    if (!item.end_date) {
                      arr.push({ value: `end_date${i}`, name: 'Please input the End date on the High School page.' });
                    }
                    if (!item.academic_year[gradeIdx] || item.academic_year[gradeIdx]=== '- Select -') {
                      arr.push({ value: `academic_year${i}_${gradeIdx}`, name: 'Please enter the Academic Year on the High School page.'});
                    }
                    if (arr.length>0) {
                      break;
                    }
                }
                else {
                  if (item.grade && item.grade.length>0 ) {
                    if (item.grade.length===gradeMaxCount) {
                      arr.push({ value: `grade${i}_${item.grade.length-1}`, name: `Please select the ${gradeLevel} Grade on the High School page.` });
                    }
                    else {
                      arr.push({ value: `AddTermYear${i}`, name: `Please add another term/year and then select the ${gradeLevel} Grade on the High School page.` });
                    }
                  }
                  else {
                    arr.push({ value: `AddTermYear${i}`, name: `Please add another term/year and then select the ${gradeLevel} Grade on the High School page.` });
                  }     
                }
              }
            }
            else {
              arr.push({ value: 'AddHighSchool', name: 'Please add high school on the High School page.' });
            }
          }
  
          if (arr.length>0) {
            setShowWarning(true);
            setErrorArr(arr);
          }
        }
        
        const res = await dispatch(getGradeAndCourse(studentId));
        const dataLoaded = res.payload;
        if (dataLoaded) {
          formik.setValues({
            ...dataLoaded,
            is_reported_all_grade_9: dataLoaded.is_reported_all_grade_9,
          });
          setCourseData(JSON.parse(dataLoaded.grade_9_course_array) || []);
        }
        setLoading(false);
        formik.setStatus('idle');
      } catch (ex) {
        console.log('Error:', ex);
      }
      setLoading(false);
    } else {
      handleLogout();
    }
  };
  React.useEffect(() => {
    getDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddCourse = () => {
    if (courseData.length > 8) return;
    setCourseData([...courseData, {}]);
  };
  // Auto save function ==========
  const notInitialRender = React.useRef(false);
  React.useEffect(() => {
    debounceSaving();
  }, [formik.values, courseData]);
  const debounceSaving = React.useCallback(
    debounce(() => {
      if (notInitialRender.current) {
        if (!loading) formik.handleSubmit();
      } else {
        notInitialRender.current = true;
      }
    }, 1500),
    []
  );
  // Auto save function ==========
  const [forceRenderKey, setForceRenderKey] = React.useState(0);

  const onDragEnd = async (result: any) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }
    const dataList = reorder(courseData, result.source.index, result.destination.index);
    setCourseData([...dataList]);
    setForceRenderKey(forceRenderKey + 1);
  };
  return loading ? (
    <LoadingContent />
  ) : (
    <>
      <TopProcessBar
        stepProcess={[{ percentOnProcess: 30, title: '' }]}
        title="Courses & Grades"
        process={1}
        icon={<StackedLineChartIcon />}
      />

      <Container className="main-container">
        <div className="px-2">
          <Form style={{ maxWidth: '900px' }} onSubmit={formik.handleSubmit}>
            <HtmlTooltip
              title={
                <React.Fragment>
                  <div className="fs-6 text-dark my-2 fw-bold">
                    <InfoOutlinedIcon fontSize="small" className="mx-1" />
                    Courses & Grades
                  </div>
                  <div className="d-flex flex-wrap text-field-light">
                    Many college applications will want you to list your Courses and Grades and/or
                    GPA in various formats. Enter your information as you complete each of your high
                    school courses to make it easy for you during college application season.
                  </div>
                </React.Fragment>
              }
              placement="right-start"
            >
              <Row className="m-0">
                <div className="text-field-mid fs-3 mb-4">9th Grade</div>
                {showWarning && (
                  <div className="row">
                    <div className="col-md-12 mb-4">
                        <div className="alert alert-danger" role="alert">
                          {errorArr && errorArr.length>0 && (
                            <>
                           Prior to entering your coursework by grade year, go to Education &gt;
                           <a href={"/#/application/high-school/#"+ errorArr[0].value} title='click to return the high school page'> High School </a>
                           &nbsp; and enter the following information for each of the high school(s) you attended. 
                           {` ${errorArr[0].name}`}
                           </>
                          )}
                        </div>
                    </div>
                  </div>
                )}

                <DragDropContext onDragEnd={onDragEnd} key={forceRenderKey}>
                  <Droppable droppableId="list">
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {(courseData || []).map((item, index) => (
                          <div key={index + '-' + courseData.length}>
                            <Draggable draggableId={index + '-' + courseData.length} index={index}>
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <div
                                    key={index + '-' + courseData.length}
                                    className="position-relative"
                                  >
                                    <CourseConfig
                                      defaultGradeValue="11th Grade"
                                      handleSave={(values) => {
                                        const newCourseData = [...courseData];
                                        newCourseData.splice(index, 1, values);
                                        setCourseData(newCourseData);
                                      }}
                                      item={item}
                                      total={courseData.length}
                                      index={index + 1}
                                    />
                                    <RemoveCircleOutlineIcon
                                      className="position-absolute text-field-light pointer"
                                      style={{ right: '-24px', top: '12px' }}
                                      onClick={() => {
                                        const newCourseData = [...courseData];
                                        newCourseData.splice(index, 1);
                                        setCourseData(newCourseData);
                                      }}
                                    />
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          </div>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                <div className="my-3 w-100 d-flex flex-column align-items-center">
                  <div className="text-field-light sized70">You can add up to 9 high schools</div>
                  <Button variant="primary" className="p-2 px-5" onClick={handleAddCourse}>
                    Add Additional High Schools
                  </Button>
                </div>
                <div className="common-flag pt-3">
                  <div className="text-field-light">
                    Please indicate that you have reported all courses and grades for 9th grade.*
                  </div>
                  <CheckboxInput
                    name="is_reported_all_grade_9"
                    values={formik.values.is_reported_all_grade_9}
                    onChange={formik.handleChange}
                    label="I have reported all of my courses for this grade."
                  />
                </div>
              </Row>
            </HtmlTooltip>

            <div className="my-3 d-flex justify-content-end border-top pt-3">
              <div className="col col-md-6 text-center text-md-end">
                <Button variant="primary" type="submit" className="p-2 px-5 mx-2">
                  {course.status === 'loading' ? (
                    <Spinner size="sm" animation="border" variant="light" />
                  ) : (
                    'Save'
                  )}
                </Button>
                <Button variant="primary" className="p-2 px-5" onClick={() => handleContinue()}>
                  Continue
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Container>
    </>
  );
};

export default NinthGrade;
