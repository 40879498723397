/* eslint-disable react-hooks/exhaustive-deps */
import LoadingContent from 'components/LoadingContent';
import { useFormik } from 'formik';
import React from 'react';
import TopProcessBar from 'screen/selectCollege/TopProcessBar';
import { Container, Form, Row, Spinner, Button } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { routers } from 'router/router';
import { logout } from 'app/basicInformationSlice/basicInformationSlice';
import { authenticateSettings } from 'components/helper/appConfig';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import YesNoSwitch from 'components/input/YesNoSwitch';
import { getGeneral, updateGeneral } from 'app/course/courseSlice';
import CheckboxInput from 'components/input/CheckboxInput';
import { BoolToString, checkEnableParseJSON, formatBoolean } from 'components/helper/helper';
import TextInput from 'components/input/textInput';
import SelectInput from 'components/input/SelectInput';
import { debounce } from 'lodash';
import MiddleCourseWork, {
  TAdditionalInfoCourse,
  initialValuesMiddleCourseWork,
} from './components/MiddleCourseWork';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { getCollegeData, getHighSchoolData } from 'app/highSchoolSlice/highSchoolSlice';

interface Props {}

const GeneralInfo = (props: Props) => {
  const [loading, setLoading] = React.useState(false);
  const course = useAppSelector((store) => store.course);
  const dispatch = useAppDispatch();
  const history = useHistory();
  let studentId = Cookies.get('userId');
  let token = Cookies.get(authenticateSettings.tokenName);

  const formik = useFormik({
    initialValues: {
      is_able_obtain_copy_transcript: '',
      is_counselor_submit_transcript: false,
      is_transcript_show_grade_completed: '',
      is_counselor_submit_transcript_2: false,
      is_take_high_school_math_in_grade_7_or_8: '',
      take_high_school_math_array: '',
      is_take_high_school_english_in_grade_7_or_8: '',
      take_high_school_english_array: '',
      is_attend_school_outside_us_6_through_8: '',
      language_instruction: '',
      specify_language_instruction: '',
      number_of_course_to_report: '',
      course_scheduling_system_is_using: '',
      course_array: '',
      additional_info: {
        completed_high_school_outside_US: '',
        high_school_academic_performance: '',
        is_take_course_in_middle_school: '',
        is_have_course_issued_official_transcript_grades: '',
        degree_or_diploma_name: '',
        course_work: [],
      } as TAdditionalInfoCourse,
    },
    onSubmit: async (values) => {
      if (token && studentId) {
        try {
          let additional_info: any = values.additional_info;

          if (additional_info && additional_info.course_work) {
            // fix grades[] and credits[]
            for (let i = 0; i < additional_info.course_work.length; i++) {
              if (additional_info.course_work[i]?.schedule_transcript === 'One final Grade') {
                additional_info.course_work[i].grades = [];
                additional_info.course_work[i].credits = [];
              } else if (
                additional_info.course_work[i]?.schedule_transcript === 'One grade per term'
              ) {
                additional_info.course_work[i].final_grade = '';
                additional_info.course_work[i].final_credit = '';
              }
            }
          }

          const newValues = {
            ...values,
            student_id: studentId,
            is_able_obtain_copy_transcript: formatBoolean(values.is_able_obtain_copy_transcript),
            is_transcript_show_grade_completed: formatBoolean(
              values.is_transcript_show_grade_completed
            ),
            is_take_high_school_math_in_grade_7_or_8: formatBoolean(
              values.is_take_high_school_math_in_grade_7_or_8
            ),
            is_take_high_school_english_in_grade_7_or_8: formatBoolean(
              values.is_take_high_school_english_in_grade_7_or_8
            ),

            is_attend_school_outside_us_6_through_8: formatBoolean(
              values.is_attend_school_outside_us_6_through_8
            ),

            additional_info: JSON.stringify(additional_info),
          };

          await dispatch(updateGeneral(newValues)).unwrap();
        } catch (ex) {
          console.log('Error:', ex);
        }
      } else {
        handleLogout();
      }
    },
  });
  const handleLogout = () => {
    dispatch(logout());
    Cookies.remove(authenticateSettings.tokenName);
    Cookies.remove('userId');
    history.push(routers.login.path);
  };

  const handleContinue = () => {
    formik.handleSubmit();
    history.push(routers.grade9.path);
  };
  const getDetail = async () => {
    if (token && studentId) {
      try {
        setLoading(true);
        const res = await dispatch(getGeneral(studentId)).unwrap();
        const dataLoaded = res; //res.payload;
        if (dataLoaded) {
          formik.resetForm({
            values: {
              ...dataLoaded,
              additional_info: checkEnableParseJSON(dataLoaded.additional_info),
              is_able_obtain_copy_transcript: BoolToString(
                dataLoaded.is_able_obtain_copy_transcript
              ),
              is_transcript_show_grade_completed: BoolToString(
                dataLoaded.is_transcript_show_grade_completed
              ),
              is_take_high_school_english_in_grade_7_or_8: BoolToString(
                dataLoaded.is_take_high_school_english_in_grade_7_or_8
              ),
              is_take_high_school_math_in_grade_7_or_8: BoolToString(
                dataLoaded.is_take_high_school_math_in_grade_7_or_8
              ),
              is_attend_school_outside_us_6_through_8: BoolToString(
                dataLoaded.is_attend_school_outside_us_6_through_8
              ),
            },
          });
        }

        await dispatch(getCollegeData(studentId)).unwrap();
        await dispatch(getHighSchoolData(studentId)).unwrap();
        setLoading(false);
      } catch (ex) {
        console.log('Error: ', ex);
        setLoading(false);
      }
    }
  };
  React.useEffect(() => {
    getDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddCourse = () => {
    if (formik.values.additional_info?.course_work?.length > 5) return;
    formik.setFieldValue(
      'additional_info.course_work',
      formik.values.additional_info?.course_work
        ? [...formik.values.additional_info?.course_work, initialValuesMiddleCourseWork]
        : [initialValuesMiddleCourseWork]
    );
  };

  // Auto save function ==========
  React.useEffect(() => {
    formik.dirty && debounceSaving();
  }, [formik.values]);
  const debounceSaving = React.useCallback(
    debounce(() => {
      if (!loading) formik.handleSubmit();
    }, 1500),
    []
  );
  // Auto save function ==========
  return loading ? (
    <LoadingContent />
  ) : (
    <>
      <TopProcessBar
        stepProcess={[
          { percentOnProcess: 25, title: 'Declarations' },
          { percentOnProcess: 50, title: 'Academic History' },
          { percentOnProcess: 75, title: 'International School Experience' },
        ]}
        title="Courses & Grades"
        process={1}
        icon={<StackedLineChartIcon />}
      />

      <Container className="main-container">
        <div className="px-2">
          <Form style={{ maxWidth: '900px' }} onSubmit={formik.handleSubmit}>
            <Row className="m-0">
              <div className="text-field-mid fs-3 mb-4" id="Declarations">
                General Info
              </div>
              <YesNoSwitch
                label="I am able to obtain a copy of my transcript(s)*"
                className="common-flag"
                name="is_able_obtain_copy_transcript"
                values={formik.values.is_able_obtain_copy_transcript}
                onChange={formik.handleChange}
              />
              {formik.values.is_able_obtain_copy_transcript === 'false' && (
                <div className="w-100 bg-light p-3 common-flag">
                  <div className="text-field-light m-3">
                    Based on your resspanonses, you are not required to complete this section.
                    Colleges who are listed above as requiring this information will not penalize
                    you. Please have your counselor submit your transcripts as per their usual
                    methods*
                  </div>
                  <div className="m-3">
                    <CheckboxInput
                      name="is_counselor_submit_transcript"
                      values={formik.values.is_counselor_submit_transcript}
                      onChange={formik.handleChange}
                      label="I understand"
                    />
                  </div>
                </div>
              )}
              <YesNoSwitch
                label="My transcript shows grades for courses I've completed*"
                className="common-flag my-3"
                name="is_transcript_show_grade_completed"
                values={formik.values.is_transcript_show_grade_completed}
                onChange={formik.handleChange}
              />
              {formik.values.is_transcript_show_grade_completed === 'false' && (
                <div className="w-100 bg-light p-3 common-flag">
                  <div className="text-field-light m-3">
                    Based on your responses, you are not required to complete this section. Colleges
                    who are listed above as requiring this information will not penalize you. Please
                    have your counselor submit your transcripts as per their usual methods*
                  </div>
                  <div className="m-3">
                    <CheckboxInput
                      name="is_counselor_submit_transcript_2"
                      values={formik.values.is_counselor_submit_transcript_2}
                      onChange={formik.handleChange}
                      label="I understand"
                    />
                  </div>
                </div>
              )}
              <div className="border w-100 my-3" id="AcademicHistory" />
              <div className="text-dark fs-5 p-1 mb-3 uc-flag push-flag-up">
                Middle School - Academic History
              </div>
              <div className="text-field-light">
                Mathematics or foreign language courses are common middle courses that count toward
                your high school requirements.
              </div>
              <div className="text-field-light">
                If any of your middle school courses are listed on your transcript, be sure to list
                them here, too.
              </div>
              <div className="text-field-light">
                Note: On the college applications, you do not need to list the courses under your
                middle school name, they will be listed under your high school
              </div>
              <YesNoSwitch
                label="Did you take any course(s) in middle school that fulfilled your high school subject requirements?"
                logo="span-uc-csu-logo"
                className="my-3"
                name="additional_info.is_take_course_in_middle_school"
                values={formik.values.additional_info?.is_take_course_in_middle_school}
                onChange={formik.handleChange}
              />
              {formik.values.additional_info?.is_take_course_in_middle_school === 'true' && (
                <>
                  <YesNoSwitch
                    logo="span-cm-logo"
                    label="Do you have middle school courses listed on your transcript that have been issued official transcript grades?*"
                    className="my-3"
                    name="additional_info.is_have_course_issued_official_transcript_grades"
                    values={
                      formik.values.additional_info
                        ?.is_have_course_issued_official_transcript_grades
                    }
                    onChange={formik.handleChange}
                  />
                  <div className="my-3 fs-5">Middle School Coursework</div>
                  {(formik.values.additional_info?.course_work || []).map((item, index) => (
                    <div key={index} className="position-relative">
                      <MiddleCourseWork formik={formik} index={index} />
                      <RemoveCircleOutlineIcon
                        className="position-absolute text-field-light pointer"
                        style={{ right: '-24px', top: '30px' }}
                        onClick={() => {
                          const newCourseData = [...formik.values.additional_info?.course_work];
                          newCourseData.splice(index, 1);
                          formik.setFieldValue('additional_info.course_work', newCourseData);
                        }}
                      />
                    </div>
                  ))}
                  <div className="my-3 w-100 d-flex flex-column align-items-center">
                    <div className="text-field-light sized70">You can add up to 6 courses</div>
                    <Button variant="primary" className="p-2 px-5" onClick={handleAddCourse}>
                      Add another Course
                    </Button>
                  </div>
                </>
              )}
              <div id="InternationalSchoolExperience" />
              <div className="fs-5 p-1 py-3">International school experience</div>
              <YesNoSwitch
                label="Did you attend school outside the United States for any part of 6th through 8th grade?"
                className="span-uc-logo"
                name="is_attend_school_outside_us_6_through_8"
                values={formik.values.is_attend_school_outside_us_6_through_8}
                onChange={formik.handleChange}
              />
              {formik.values.is_attend_school_outside_us_6_through_8 === 'true' && (
                <>
                  <SelectInput
                    option={[
                      '- Select -',
                      'Arabic',
                      'Armenian',
                      'Bengali',
                      'Chinese',
                      'English',
                      'French',
                      'German',
                      'Greek',
                      'Hebrew',
                      'Hindi/Urdu',
                      'Hmong',
                      'Hungarian',
                      'Italian',
                      'Japanese',
                      'Italian',
                      'Lao',
                      'Malay',
                      'Mon-Khmer',
                      'Navajo',
                      'Persian(Farsi)',
                      'Polish',
                      'Portugese',
                      'Punjabi',
                      'Russian',
                      'Spanish',
                      'Tagalog',
                      'Thai',
                      'Turkish',
                      'Vietnamese',
                      'Other',
                    ]}
                    className="uc-flag"
                    label="What was the language of instruction? *"
                    name="language_instruction"
                    values={formik.values.language_instruction}
                    onChange={formik.handleChange}
                  />
                  {formik.values.language_instruction === 'Other' && (
                    <TextInput
                      label="Specify the Language"
                      name="specify_language_instruction"
                      values={formik.values.specify_language_instruction}
                      onChange={formik.handleChange}
                      countText={30}
                    />
                  )}
                </>
              )}
              <YesNoSwitch
                label="Did you complete high school/secondary school outside of the United States?	"
                className="span-csu-logo py-3"
                name="additional_info.completed_high_school_outside_US"
                values={formik.values.additional_info?.completed_high_school_outside_US}
                onChange={formik.handleChange}
              />
              {formik.values.additional_info?.completed_high_school_outside_US === 'true' && (
                <>
                  <TextInput
                    label="Academic Performance (High School GPA, Standing, Percentage, etc.)*"
                    countText={49}
                    name="additional_info.high_school_academic_performance"
                    values={formik.values.additional_info?.high_school_academic_performance}
                    onChange={formik.handleChange}
                    mdCol={12}
                  />

                  <TextInput
                    label="Name of Degree/Diploma (e.g. Diploma, A-Level, Abitur, Certificado)*"
                    name="additional_info.degree_or_diploma_name"
                    countText={49}
                    values={formik.values.additional_info?.degree_or_diploma_name}
                    onChange={formik.handleChange}
                    mdCol={12}
                  />
               </>
              )}
            </Row>
            <div className="my-3 d-flex justify-content-end border-top pt-3">
              <div className="col col-md-6 text-center text-md-end">
                <Button variant="primary" type="submit" className="p-2 px-5 mx-2">
                  {course.status === 'loading' ? (
                    <Spinner size="sm" animation="border" variant="light" />
                  ) : (
                    'Save'
                  )}
                </Button>
                <Button variant="primary" className="p-2 px-5" onClick={() => handleContinue()}>
                  Continue
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Container>
    </>
  );
};

export default GeneralInfo;
