/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Col, Row, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import TextInput from 'components/input/textInput';
import DatePickerInput from 'components/input/DatePicker';
import YesNoSwitch from 'components/input/YesNoSwitch';
import { HtmlTooltip } from 'screen/selectCollege/SelecCollegePage';
import { Chip, Typography } from '@mui/material';
import GroupCheckbox from 'components/input/GroupCheckbox';
import CheckboxInput from 'components/input/CheckboxInput';
import SelectInput from 'components/input/SelectInput';
import { checkDecimalInRange, schoolGradingScaleOptions } from 'components/helper/helper';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import moment from 'moment';
import HighSchoolInfo from './HighSchoolInfo';
import GroupRadioButton from 'components/input/GroupRadioButton';
import DateReceiveInfo from './DateReceiveInfo';
import { gradeOptions, academicOptions } from './education.config';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface Props {
  handleSave: (value: any) => void;
  item: any;
  index: number;
  indexText: string;
  onRemove: () => void;
  handleCheckCurrent: (name: string) => void;
}

const SchoolConfig = (props: Props) => {
  const formItem = useFormik({
    initialValues: {
      where_attend_high_school: 'IN_CA',
      state: '',
      city: '',
      country: '',
      is_home_school: false,
      is_independant_study: false,
      my_home_school_associated_with_high_school: false,
      school_name: '',
      school_type: '',
      high_school_name: '',
      high_school_code: '',
      start_date: moment().toISOString(),
      end_date: moment().toISOString(),
      degree: '',
      month: '',
      day: '',
      year: '',
      grade: [],
      academic_year: [],
      is_summer_took: [],
      specialized_curriculum_career: false,
      specialized_curriculum_international: false,
      specialized_curriculum_magnet: false,
      specialized_curriculum_other: false,
      specialized_curriculum_other_free_text: '',
      grading_scale: '',
      grading_scale_is_abcdf: false,
      grading_scale_is_0_100: false,
      grading_scale_is_1_7: false,
      grading_scale_is_other: false,
      is_block_scheduling: false,
      degreeFreeText: '',
      lowest_number_grade: '',
      highest_number_grade: '',
      classes_schedule: '',
      other_grading_system: '',
      graduating_high_school: '',
      school_term_1: false,
      school_term_2: false,
      school_term_3: false,
      school_term_4: false,
      high_school_object: {
        college_board_code: '',
        school_name: '',
        school_type: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        csu_name: '',
        uc_name: '',
        cm_name: '',
        match_all: false,
        label: '',
        code: null,
        is_manual: false,
       },
      is_boarding_school: '',
      is_graduate_from_this_school: '',
      is_live_on_campus: '',
      graduation_status: '',
      academic_performance: '',
      name_of_degree: '',
      allNoneAGCourses: false,
      have_other_grading_system: 'false',
      second_grading_system: '',
    },
    onSubmit: (values) => {},
  });
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  React.useEffect(() => {
    if (props.item) {
      formItem.resetForm({
        values: {
          ...formItem.values,
          ...props.item,
          start_date: props.item.start_date ?? props.item.startDate,
          end_date: props.item.end_date ?? props.item.endDate,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.item?.high_school_name, props.item?.is_graduate_from_this_school]);

  const handleSave = () => {
    try {
      if (!checkDecimalInRange(formItem.values.highest_number_grade)) {
        formItem.setFieldError('highest_number_grade', 'Invalid number');
      } else if (!checkDecimalInRange(formItem.values.lowest_number_grade)) {
        formItem.setFieldError('lowest_number_grade', 'Invalid number');
      } else {
        props.handleSave({
          ...formItem.values,
        });
        setExpanded(false);
      }
    }
    catch(ex){
      console.log('Error:', ex);
    }
  };

  React.useEffect(() => {
    if (formItem.dirty) {
      setTimeout(() => {
        props.handleSave({ ...formItem.values });
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formItem.values]);


  const handleAddTerm = () => {
    if (formItem.values.grade.length > 3) return;
    formItem.setFieldValue('grade', [...formItem.values.grade, '']);
    formItem.setFieldValue('academic_year', [...formItem.values.academic_year, '']);
    formItem.setFieldValue('is_summer_took', [...formItem.values.is_summer_took, false]);
  };

  const handleRemoveTerm = (index: number) => {
    formItem.setFieldValue(
      'grade',
      formItem.values.grade.filter((item: any, i: number) => i !== index)
    );
    formItem.setFieldValue(
      'academic_year',
      formItem.values.academic_year.filter((item: any, i: number) => i !== index)
    );
    formItem.setFieldValue(
      'is_summer_took',
      formItem.values.is_summer_took.filter((item: any, i: number) => i !== index)
    );
  };


  const buildHeader = () => {
    let title = (formItem.values.high_school_name ?? '') +
      (props.indexText.slice(0, 1) === '1'
        ? ' - Current or Most Recent High School'
        : ' - Other High School');
    const details = [];

    if (formItem.values.start_date) {
      details.push(moment(formItem.values.start_date).format('MM/YYYY'));
    } else {
      details.push('/');
    }

    if (formItem.values.end_date) {
      details.push(moment(formItem.values.end_date).format('MM/YYYY'));
    } else {
      details.push('/');
    }

    if (!details.length) {
      return title;
    }

   return `${title} - ${details.join(' to ')}`;

  }
  return (
    <Card className="w-100 my-3 px-0 position-relative overflow" style={{ overflow: 'unset' }}>
      <CardActions
        id={`cardItem${props.index}`}
        disableSpacing
        onClick={handleExpandClick}
        sx={{
          backgroundColor: expanded ? '#AAEBF7' : 'none',
          borderBottom: expanded ? '2px solid #4D88F0' : 'none',
          marginLeft: '-8px',
        }}
      >
        <div className="w-100 d-flex justify-content-between">
          <span className="ms-3" style={{
            color: formItem.values.high_school_object?.is_manual ? 'black' : '#4d88f0'
          }}>
            {buildHeader()}
          </span>
          <strong className="text-field-light">{props.indexText}</strong>
        </div>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Row className="px-2">
            <CheckboxInput
              id={`high_school_name${props.index}`}
              name="isMostRecentHighSchoole"
              label={'Current or Most Recent High School'}
              values={props.indexText.slice(0, 1) === '1'}
              onChange={() => {
                props.handleCheckCurrent(formItem.values.high_school_name);
                setExpanded(false);
              }}
            />
            <HighSchoolInfo formik={formItem} index={props.index} />
            <DatePickerInput
              id={`start_date${props.index}`}
              className="mt-2 justify-content-start"
              label="Start date"
              name="start_date"
              values={formItem.values.start_date}
              format="MM/yyyy"
              onChange={(value) => formItem.setFieldValue('start_date', value)}
            />
            <DatePickerInput
              id={`end_date${props.index}`}
              className="mt-2 justify-content-start"
              name="end_date"
              label="End date"
              values={formItem.values.end_date}
              format="MM/yyyy"
              onChange={(value) => formItem.setFieldValue('end_date', value)}
            />

            <div id="highschool_attend_detail" className="row">
              <div className="text-field-light mt-3">What grades did you attend this high school? *</div>
              {formItem.values.grade.map((gradeOption, i) => (
                <Row className="mb-3" key={i}>
                  <SelectInput
                    id={`grade${props.index}_${i}`}
                    form={formItem}
                    option={gradeOptions}
                    label="Grade"
                    values={formItem.values.grade[i]}
                    name={`grade[${i}]`}
                    onChange={formItem.handleChange}
                  />
                  <SelectInput
                    id={`academic_year${props.index}_${i}`}
                    form={formItem}
                    option={academicOptions}
                    label="Academic Year selection *"
                    values={formItem.values.academic_year[i]}
                    name={`academic_year[${i}]`}
                    onChange={formItem.handleChange}
                  />
                  <Col md={9}>
                    <CheckboxInput
                      label="I took summer courses after this grade"
                      name={`is_summer_took[${i}]`}
                      mdCol={'6'}
                      values={formItem.values.is_summer_took[i]}
                      onChange={formItem.handleChange}
                    />
                  </Col>

                  <Col id={`remove_term${props.index}_${i}`} md={3} style={{ textAlign: 'right' }}>
                    <Chip
                      label="Remove"
                      sx={{ width: 100 }}
                      className="ms-3 pointer"
                      onClick={() => handleRemoveTerm(i)}
                    />
                  </Col>
                </Row>
              ))}
              {formItem.values.grade.length < 4 && (
                <div id={`AddTermYear${props.index}`} className="my-3 w-100 d-flex flex-column align-items-center">
                  <div className="text-field-light sized70">You can add up to 4 term/year</div>
                  <Button variant="primary" className="p-2 px-5" onClick={handleAddTerm}>
                    Add another term/year
                  </Button>
                </div>
              )}
              <GroupCheckbox
                logo="span-uc-logo"
                label="Specialized curriculum (if associated with this school)"
                mdCol={6}
                form={formItem}
                options={[
                  { label: 'Career Pathway/Academy', value: 'specialized_curriculum_career' },
                  {
                    label: 'International Baccalaureate',
                    value: 'specialized_curriculum_international',
                  },
                  { label: 'Magnet', value: 'specialized_curriculum_magnet' },
                  { label: 'Other', value: 'specialized_curriculum_other' },
                ]}
              />
              {formItem.values.specialized_curriculum_other && (
                <TextInput
                  countText={16}
                  logo="span-uc-logo"
                  label="Other specialized curriculum"
                  name="specialized_curriculum_other_free_text"
                  values={formItem.values.specialized_curriculum_other_free_text}
                  onChange={formItem.handleChange}
                />
              )}

              <HtmlTooltip
                title={
                  <React.Fragment>
                    <Typography className="fs-6 text-dark my-2 fw-bold">
                      <InfoOutlinedIcon fontSize="small" className="mx-1" />
                      Class Schedule
                    </Typography>
                    <div className="d-flex flex-wrap text-field-light">
                      This one can be tricky, especially if you're on a quarter or trimester system.
                      Please contact your high school counselor to ask how you should complete this
                      section based on your specific high school's recommendation.
                    </div>
                  </React.Fragment>
                }
                placement="right-start"
              >
                <Row>
                  <GroupCheckbox
                    label="What’s this school grading scale?"
                    options={schoolGradingScaleOptions}
                    form={formItem}
                    mdCol={4}
                    logo="span-uc-logo"
                  />
                  {formItem.values.grading_scale_is_0_100 && (
                    <>
                      <TextInput
                        type="number"
                        label="What is the lowest possible number grade at this school? *"
                        values={formItem.values.lowest_number_grade}
                        name="lowest_number_grade"
                        onChange={formItem.handleChange}
                        feedback={
                          <span className="text-danger">{formItem.errors.lowest_number_grade}</span>
                        }
                      />
                      <TextInput
                        type="number"
                        label="What is the highest possible number grade at this school? *"
                        values={formItem.values.highest_number_grade}
                        name="highest_number_grade"
                        onChange={formItem.handleChange}
                        feedback={
                          <span className="text-danger">
                            {formItem.errors.highest_number_grade}
                          </span>
                        }
                      />
                    </>
                  )}
                  {formItem.values.grading_scale_is_other && (
                    <>
                      <TextInput
                        countText={16}
                        label="Specify the grading system *"
                        values={formItem.values.other_grading_system}
                        onChange={formItem.handleChange}
                        name="other_grading_system"
                      />
                    </>
                  )}
                  <GroupRadioButton
                    options={[
                      'Full year (1 final grade per year)',
                      'Semester (2 final grades per year)',
                      'Trimester (3 final grades per year)',
                      'Quarter (4 final grades per year)',
                    ]}
                    mdCol={12}
                    name="classes_schedule"
                    values={formItem.values.classes_schedule}
                    onChange={formItem.handleChange}
                    label="What's its school system?"
                    logo="span-uc-logo"
                  />
                  <YesNoSwitch
                    className='py-3'
                    label="Does your school have another grading system that has been used while you have attended?"
                    name="have_other_grading_system"
                    values={formItem.values.have_other_grading_system}
                    onChange={formItem.handleChange}
                  />
                  {formItem.values.have_other_grading_system === 'true' && (
                    <>
                      <GroupRadioButton
                        label="What is the second school system?"
                        name="second_grading_system"
                        values={formItem.values.second_grading_system}
                        onChange={formItem.handleChange}
                        mdCol={12}
                        options={[
                          'Full year (1 final grade per year)',
                          'Semester (2 final grades per year)',
                          'Trimester (3 final grades per year)',
                          'Quarter (4 final grades per year)',
                        ]}
                      />
                      <div className="text-field-light mb-3">
                        When entering your courses and grades in each grade year, add your high
                        school twice and select the school system before entering in your grades.
                      </div>
                    </>
                  )}

                  {/* <div>Graduating High School</div>
                  <SelectInput
                    label="Most recent academic year *"
                    values={formItem.values.graduating_high_school}
                    onChange={formItem.handleChange}
                    name="graduating_high_school"
                    option={['9th', '10th', '11th', '12th', 'Already Graduated']}
                  /> */}
                </Row>
              </HtmlTooltip>
            </div>

            <div id="highschool_tree_question">
              <div className="row">
                <Col md={6} className="common-flag">
                  <YesNoSwitch
                    className="py-3"
                    label="Is this a boarding school?*"
                    name="is_boarding_school"
                    values={formItem.values.is_boarding_school}
                    onChange={formItem.handleChange}
                  />
                </Col>
              </div>

              <div id="highschool_graduation" className="row">
                <Col md={6}>
                  <YesNoSwitch
                    className="py-3"
                    label="Do you live on campus?"
                    name="is_live_on_campus"
                    values={formItem.values.is_live_on_campus}
                    onChange={formItem.handleChange}
                  />
                </Col>
                <Col md={6} className="uc-common-flag end-2-flag">
                  <YesNoSwitch
                    className="py-3"
                    label="Did or will you graduate from this school?"
                    name="is_graduate_from_this_school"
                    values={formItem.values.is_graduate_from_this_school}
                    onChange={formItem.handleChange}
                  />
                </Col>
              </div>

              {formItem.values.is_graduate_from_this_school === 'true' && (
                <>
                  <div id="highschool_graduation_detail" className="row">
                    <div className="border my-3 w-100" />
                    <div>Graduation Details</div>
                    <SelectInput
                      form={formItem}
                      option={[
                        { value: 'No degree from this high school' },
                        { value: 'High School/Secondary School Diploma' },
                        { value: 'IB Diploma' },
                        { value: 'GED' },
                        { value: 'Certificate of Proficiency' },
                        { value: 'Other' },
                      ]}
                      label="Degree, diploma, or certification received or to be received. *"
                      values={formItem.values.degree}
                      name="degree"
                      onChange={formItem.handleChange}
                    />
                    {formItem.values.degree === 'Other' && (
                      <TextInput
                        countText={32}
                        label="Other"
                        name="degreeFreeText"
                        values={formItem.values.degreeFreeText}
                        onChange={formItem.handleChange}
                      />
                    )}
                    {formItem.values.degree !== 'No degree from this high school' && (
                      <DateReceiveInfo formik={formItem} />
                    )}
                  </div>                 
                </>
              )}
            </div>

            <div className="row" id="highschool_saveinfo">
            <div className="border w-100 my-3"></div>
              <Col xs={6} md={9} />
              <Col xs={6} md={3} className="p-3">
                <Button className="w-100" onClick={handleSave}>
                  Save Info
                </Button>
              </Col>
            </div>
          </Row>
        </CardContent>
      </Collapse>
      <RemoveCircleOutlineIcon
        className="position-absolute text-field-light pointer"
        style={{ right: '-24px', top: '12px' }}
        onClick={props.onRemove}
      />
    </Card>
  );
};

export default SchoolConfig;
