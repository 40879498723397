/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { Card, Stack, Tab, Tabs, Typography } from '@mui/material';
import CustomTabPanel from './TabPanel';
import LoadingContent from 'components/LoadingContent';
import CheckboxInput from 'components/input/CheckboxInput';
import ItemContent, { CheckboxContent } from 'screen/summary/components/ItemContent';
import { testTakenOption } from 'screen/testGroup/GeneralInfo/GeneralInfo';
import SATTestSum from 'screen/summary/components/SATTestSum';
import ACTTestSum from 'screen/summary/components/ACTTestSum';
import CLEPSum from 'screen/summary/components/CLEPSum';
import {
  checkEnableParseJSON,
  dateFormat,
  formatBoolean,
} from 'components/helper/helper';
import { checkModule } from 'screen/summary/ReviewPersonal';
import testingServices from 'services/testingServices';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { buildClassName } from './RPersonalInformation';
import { authenticateSettings } from 'components/helper/appConfig';
import { logout } from 'app/basicInformationSlice/basicInformationSlice';
import { routers } from 'router/router';
import Cookies from 'js-cookie';
import { getGeneral } from 'app/testingSlice/testingSlice';

type Props = {
  setCurrentModuleId: (id: string) => void;
  isCounselor?: boolean;
};

const RTest = ({ setCurrentModuleId, isCounselor }: Props) => {
  const dispatch = useAppDispatch();
  let history = useHistory();
  const [value, setValue] = React.useState(1);
  const [checkModuleState, setCheckModuleState] = useState(checkModule);
  const[roleTest, setRoleTest] = useState<any>({});
  const[parent, setParent] = useState<any>(null);
  const params: { id?: string | number } = useParams();
  let token = Cookies.get(authenticateSettings.tokenName);

  const handleLogout = () => {
    dispatch(logout());
    Cookies.remove(authenticateSettings.tokenName);
    Cookies.remove('userId');
    history.push(routers.login.path);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setCurrentModuleId(`4.${newValue}`);
  };
  const parentLists = useAppSelector((store) => store.basicInformation.parents);
  const myGeneral = useAppSelector((store) => store.testing.myGeneral);

  const buildRoleTest = (general:any) => {
    const all_tests_wish_report_array:any = checkEnableParseJSON(
      general?.all_tests_wish_report_array ?? {}
    );
    return {
      satTest: all_tests_wish_report_array?.is_SAT,
      actTest: all_tests_wish_report_array?.is_ACT,
      actSubjectTest: all_tests_wish_report_array?.is_AP_Subject,
      ibSubjectTest: all_tests_wish_report_array?.is_IB_Subject,
      ieltsAndPte: all_tests_wish_report_array?.is_PTE || all_tests_wish_report_array?.is_IELTS,
      toeflTest: all_tests_wish_report_array?.is_TOEFL_Paper || all_tests_wish_report_array?.is_TOEFL,
      douLingoTest: all_tests_wish_report_array?.is_DuoLingo,
      clepTest: all_tests_wish_report_array?.is_CLEP,
    };
  }

  const getRoleTest = async (studentId:any) => {
    let role:any = {
      satTest: false,
      actTest: false,
      actSubjectTest: false,
      ibSubjectTest: false,
      ieltsAndPte: false,
      toeflTest: false,
      douLingoTest: false,
      clepTest: false,
    };
    try {
      token = Cookies.get(authenticateSettings.tokenName);
      if (token) {
        const general:any = await testingServices.getGeneral(studentId);
        //const general:any = await dispatch(getGeneral(studentId)).unwrap();
        role = buildRoleTest(general);        
      }
      else {
        handleLogout();
      }

    }
    catch(ex){
      console.log('Error: ', ex);
    }   
    return role;
  }
   
  const syncCheckModule = async() => {
    const obj = parentLists && parentLists.find((item: any) => Number(item.student_id) === Number(params.id));
    if (obj) {
      setParent(obj);
      let loadedCheckModule:any = checkEnableParseJSON(obj?.notes_array, {
        checkModuleState,
        notes: [],
      })?.checkModule ?? checkModule;
      setCheckModuleState(loadedCheckModule);

      const role = await getRoleTest(obj?.student_id);
      setRoleTest(role);
    }
  }

  useEffect(() => {
    const getData = async() => {
      const role = await getRoleTest(params.id);
      setRoleTest(role);
      syncCheckModule();
    }
    params.id !== ':id' && getData();
  }, []);

  useEffect(() => {
    syncCheckModule();
  }, [parentLists]);


  useEffect(() => {
    const syncRoleTest= async (general: any) => {
      if (general && params.id !== ':id') {        
        if (Number(general.student_id) === Number(params.id)) {
          const role = buildRoleTest(general);
          setRoleTest(role);
          return;
        }
      }

      if (parent && parent?.student_id) {
        const role = await getRoleTest(parent?.student_id);
        setRoleTest(role);
      }
      
    }
    syncRoleTest(myGeneral);

  }, [myGeneral]);
  
  return (
    <div>
      <>
      <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto">
        <Tab value={1} label="General" className={buildClassName('4.1', checkModuleState)} />
        <Tab
          value={2}
          label="SAT & ACT"
          hidden={!((roleTest.satTest || roleTest.actTest) && isCounselor)}
          className={buildClassName('4.2', checkModuleState)}
        />
        <Tab
          value={3}
          label="AP Tests"
          hidden={!(roleTest.actSubjectTest && isCounselor)}
          className={buildClassName('4.3', checkModuleState)}
        />
        <Tab
          value={4}
          label="IB Tests"
          hidden={!(roleTest.ibSubjectTest && isCounselor)}
          className={buildClassName('4.4', checkModuleState)}
        />
        <Tab
          value={5}
          label="CLEP Tests"
          hidden={!(roleTest.clepTest && isCounselor)}
          className={buildClassName('4.5', checkModuleState)}
        />
        {/* <Tab value={6} label="Other Tests" /> */}
      </Tabs>
      <CustomTabPanel value={value} index={1}>
        <Card sx={{ backgroundColor: 'var(--ui-grey-soft, #F7F8FA)', margin: 2, padding: 2 }}>
          <Typography sx={{ fontSize: '1.75rem', fontWeight: '500', color: '#4E5A6F' }}>
            General
          </Typography>
          <Stack justifyContent={'center'} alignItems={'center'}>
            <SummaryGeneral />
          </Stack>
        </Card>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Card sx={{ backgroundColor: 'var(--ui-grey-soft, #F7F8FA)', margin: 2, padding: 2 }}>
          <Stack justifyContent={'center'} alignItems={'center'}>
            <SummarySATACT />
          </Stack>
        </Card>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <Card sx={{ backgroundColor: 'var(--ui-grey-soft, #F7F8FA)', margin: 2, padding: 2 }}>
          <Typography sx={{ fontSize: '1.75rem', fontWeight: '500', color: '#4E5A6F' }}>
            AP Tests
          </Typography>
          <Stack justifyContent={'center'} alignItems={'center'}>
            <SummaryAP />
          </Stack>
        </Card>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        <Card sx={{ backgroundColor: 'var(--ui-grey-soft, #F7F8FA)', margin: 2, padding: 2 }}>
          <Typography sx={{ fontSize: '1.75rem', fontWeight: '500', color: '#4E5A6F' }}>
            IB Tests
          </Typography>
          <Stack justifyContent={'center'} alignItems={'center'}>
            <SummaryIB />
          </Stack>
        </Card>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={5}>
        <Card sx={{ backgroundColor: 'var(--ui-grey-soft, #F7F8FA)', margin: 2, padding: 2 }}>
          <Typography sx={{ fontSize: '1.75rem', fontWeight: '500', color: '#4E5A6F' }}>
            CLEP Tests
          </Typography>
          <Stack justifyContent={'center'} alignItems={'center'}>
            <SummaryCLEP />
          </Stack>
        </Card>
      </CustomTabPanel>
      {/* <CustomTabPanel value={value} index={6}>
        <Card sx={{ backgroundColor: 'var(--ui-grey-soft, #F7F8FA)', margin: 2, padding: 2 }}>
          <Typography sx={{ fontSize: '1.75rem', fontWeight: '500', color: '#4E5A6F' }}>
            Other Tests
          </Typography>
          <Stack justifyContent={'center'} alignItems={'center'}>
            <SummaryOtherTests />
          </Stack>
        </Card>
      </CustomTabPanel> */}
      </>     
    </div>
  );
};

export default RTest;

const SummaryGeneral = () => {
  const dispatch = useAppDispatch();  
  const [myGeneral, setMyGeneral] = React.useState<any>(null);
  const [loading, setLoading] = React.useState(false);
  const params: { id?: string | number } = useParams();
  const general = useAppSelector((store) => store.testing.myGeneral);
  const parentLists = useAppSelector((store) => store.basicInformation.parents);

  React.useEffect(() => {
    const getData = async () => {
      setLoading(true);
      //const res: any = await testingServices.getGeneral(params.id);
      const res:any = await dispatch(getGeneral(params.id)).unwrap();
      setMyGeneral({
        ...res,
      });
      setLoading(false);
    };
    params.id !== ':id' && getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  
  useEffect(() => {
    if (general) {
      setMyGeneral(general);
    }
  }, [general]);

  useEffect(() => {
    const syncGeneral = async() => {
      const obj = parentLists && parentLists.find((item: any) => Number(item.student_id) === Number(params.id));
      if (obj) {
        const res: any = await testingServices.getGeneral(obj?.student_id);
        setMyGeneral(res);
      }
    }

    syncGeneral();

  }, [parentLists]);    


  return loading ? (
    <LoadingContent />
  ) : (
    <Row className="w-100">
      {myGeneral && (
        <>
          <ItemContent
            mdCol={12}
            values={myGeneral.is_wish_self_report_scores}
            label="In addition to sending official score reports as required by colleges, do you wish to self-report scores or future test dates for any of the following standardized tests: ACT, SAT/SAT Subject, AP, IB, CLEP, TOEFL, PTE Academic, and IELTS?*"
          />
          {formatBoolean(myGeneral?.is_wish_self_report_scores) === true && (
            <>
              <div className="text-field-light col-md-12">
                Indicate all tests you wish to report.
              </div>
              {testTakenOption.map((option, index) => {
                const key = option.value;
                var formValues: any = checkEnableParseJSON(
                  myGeneral.all_tests_wish_report_array,
                  {}
                );
                return (
                  <Col md={4} lg={3} key={index}>
                    <CheckboxInput
                      label={<span className="text-field-light">{option.label}</span>}
                      name={key}
                      values={formValues[key]}
                      readOnly
                    />
                  </Col>
                );
              })}
              {formatBoolean(myGeneral?.is_wish_self_report_scores) === true && (
                <div className="py-3">
                  <ItemContent
                    mdCol={12}
                    label="International applicants: Is promotion within your educational system based upon standard leaving examinations given at the end of lower and/or senior secondary school by a state or national leaving examinations board? (Students studying in the US typically answer no to this question.)"
                    values={myGeneral?.is_promotion_within_educational_system}
                  />
                </div>
              )}
            </>
          )}
        </>
      )}
    </Row>
  );
};

const SummarySATACT = () => {
  const [testStore, setTestStore] = React.useState<any>({ mySATTest: null, myACTTest: null });
  const [loading, setLoading] = React.useState(false);
  const params: { id?: string | number } = useParams();

  React.useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const [mySATTest, myACTTest] = await Promise.all([
        testingServices.getSATTest(params.id),
        testingServices.getACTTest(params.id),
      ]);
      setTestStore({ mySATTest, myACTTest });
      setLoading(false);
    };
    params.id !== ':id' && getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);
  return loading ? (
    <LoadingContent />
  ) : (
    <Row className="w-100">
      {testStore.myACTTest && <ACTTestSum data={testStore.myACTTest} />}

      {testStore.mySATTest && (
        <SATTestSum
          data={{
            ...testStore.mySATTest,
            csu_info: checkEnableParseJSON(testStore.mySATTest.csu_info),
          }}
        />
      )}
    </Row>
  );
};

const SummaryAP = () => {
  const [myACTSubjectTest, seMyACTSubjectTest] = React.useState<any>({
    parent1: null,
    parent2: null,
  });
  const [loading, setLoading] = React.useState(false);
  const params: { id?: string | number } = useParams();

  React.useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const [res] = await Promise.all([testingServices.getACTSubjectTest(params.id)]);
      seMyACTSubjectTest(res);
      setLoading(false);
    };
    params.id !== ':id' && getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  return loading ? (
    <LoadingContent />
  ) : (
    <Row className="w-100">
      {myACTSubjectTest && (
        <>
          <ItemContent
            mdCol={12}
            label="AP ID"
            values={checkEnableParseJSON(myACTSubjectTest.csu_info)?.apId}
          />

          <ItemContent
            mdCol={12}
            label="AP Subject Tests"
            values={checkEnableParseJSON(myACTSubjectTest.ap_test_array, []).map((item: any) => {

              const details = [];

              if(item.examStatus === 'Completed' && item.dateTaken) {
                details.push(
                  moment(item.dateTaken).format(dateFormat),
                );
              }

              if(item.examStatus !== 'Completed' && item.planToTakeExam) {
                details.push(
                  moment(item.planToTakeExam).format(dateFormat),
                );
              }

              if(item.subjectCM) {
                details.push(item.subjectCM);
              }

              if(item.score) {
                details.push(`Score: ${item.score}`);
              }

              
              return (
                <>
                  <span>
                    {
                      details.join(' - ')
                    }
                  </span>
                  {renderFillAppStatus(item)}
                </>
              );
            })}
          />
        </>
      )}
    </Row>
  );
};

const SummaryIB = () => {
  const [myIBSubjectTest, setMyIBSubjectTest] = React.useState<any>(null);
  const [loading, setLoading] = React.useState(false);
  const params: { id?: string | number } = useParams();

  React.useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const res: any = await testingServices.getIBSubjectTest(params.id);
      setMyIBSubjectTest({
        ...res,
      });
      setLoading(false);
    };
    params.id !== ':id' && getData();
  }, [params.id]);
  return loading ? (
    <LoadingContent />
  ) : (
    <Row className="w-100">
      {myIBSubjectTest && (
        <>
          <CheckboxContent
            label="Check if you have completed or plan to complete the full IB diploma"
            values={myIBSubjectTest.is_completed_full_ib}
          />

          <ItemContent
            mdCol={12}
            label="IB Subject Tests"
            values={checkEnableParseJSON(myIBSubjectTest.ib_test_array, []).map((item: any) => {
              return (
                <>
                  <div>
                    {item.dateTaken && <>{moment(item.dateTaken).format('MM/DD/YYYY')} - </>}
                    {item.subject}
                    {item.score && <>- Score: {item.score}</>}
                    {renderFillAppStatus(item)}
                  </div>
                  <div className="mb-3">
                    {[item.language, item.ibExamSubjectName, item.ibExamName, item.level]
                      .filter((i) => !!i)
                      .join(' - ')}
                  </div>
                </>
              );
            })}
          />
        </>
      )}
    </Row>
  );
};

const SummaryCLEP = () => {
  const [data, setData] = React.useState<any>(null);
  const [loading, setLoading] = React.useState(false);
  const params: { id?: string | number } = useParams();

  React.useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const res = await testingServices.getCLEPTest(params.id);
      setData(res);
      setLoading(false);
    };
    params.id !== ':id' && getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);
  return loading ? (
    <LoadingContent />
  ) : (
    <Row className="w-100">{data && <CLEPSum data={data?.csu_info} />}</Row>
  );
};

// const SummaryOtherTests = () => {
//   const [testStore, setTestStore] = React.useState<any>({
//     myIELTSAndPTE: null,
//     myTOEFL: null,
//     myDouling: null,
//     myOtherTest: null,
//   });
//   const [loading, setLoading] = React.useState(false);
//   const params: { id?: string | number } = useParams();

//   React.useEffect(() => {
//     const getData = async () => {
//       setLoading(true);
//       const [myIELTSAndPTE, myTOEFL, myDouling, myOtherTest] = await Promise.all([
//         testingServices.getIELTSAndPTE(params.id),
//         testingServices.getTOEFL(params.id),
//         testingServices.getDoulingo(params.id),
//         testingServices.getOtherTest(params.id),
//       ]);

//       setTestStore({ myIELTSAndPTE, myTOEFL, myDouling, myOtherTest });
//       setLoading(false);
//     };
//     params.id !== ':id' && getData();
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [params.id]);
//   return loading ? (
//     <LoadingContent />
//   ) : (
//     <Row className="w-100">
//       {testStore.myIELTSAndPTE && <IELTSSum data={testStore.myIELTSAndPTE} />}

//       {testStore.myTOEFL && <TOEFLSum data={testStore.myTOEFL} />}

//       {testStore.myDoulingo && <DoulingoSum data={testStore.myDoulingo} />}

//       {testStore.myOtherTest && (
//         <>
//           <div className="fs-5 text-field-light">Senior Secondary Leaving Examinations</div>
//           <CheckboxContent
//             col={12}
//             values={testStore.myOtherTest.is_not_required_toefl_test}
//             label="I’m a domestic student or am not required to take the TOEFL Tests."
//           />
//           <ItemContent
//             mdCol={12}
//             label="Number of Senior Secondary Leaving Examinations you have already taken"
//             values={testStore.myOtherTest.number_of_time_taken_senior_secondary_exam}
//           />
//           {checkEnableParseJSON(testStore.myOtherTest?.examination_board) &&
//             optionValues2(testStore.myOtherTest.number_of_time_taken_senior_secondary_exam).map(
//               (item, index) => (
//                 <Row key={index}>
//                   <ItemContent
//                     label="Date taken"
//                     values={formatDate(
//                       checkEnableParseJSON(testStore.myOtherTest.senior_secondary_exam_date_taken)[
//                         index
//                       ]
//                     )}
//                   />
//                   <ItemContent
//                     label="Examination board"
//                     values={
//                       checkEnableParseJSON(testStore.myOtherTest?.examination_board)[index] ===
//                       'Other'
//                         ? checkEnableParseJSON(testStore.myOtherTest?.specify_other)[index]
//                         : checkEnableParseJSON(testStore.myOtherTest?.examination_board)[index]
//                     }
//                   />
//                   <ItemContent
//                     label="Academic subject"
//                     values={checkEnableParseJSON(testStore.myOtherTest.academic_subject)[index]}
//                   />
//                   <ItemContent
//                     label="Score"
//                     values={checkEnableParseJSON(testStore.myOtherTest.score)[index]}
//                   />
//                   <ItemContent
//                     label="Score Type"
//                     values={checkEnableParseJSON(testStore.myOtherTest.score_type)[index]}
//                   />
//                 </Row>
//               )
//             )}

//           <div className="border w-100 my-3" />
//           <div className="fs-5 text-field-light">Advanced Level Exams</div>
//           <ItemContent
//             label="Do you have any Advanced Level Exams you wish to report?"
//             values={testStore.myOtherTest.is_have_advanced_level_exam_wish_report}
//             mdCol={12}
//           />
//           <ItemContent
//             label="How many Advanced Level Exams would you like to report"
//             values={testStore.myOtherTest.number_of_advanced_level_exam_report}
//             mdCol={6}
//           />
//           <ItemContent
//             label="Advanced Level Exams (AL Exam - Grade)"
//             values={checkEnableParseJSON(testStore.myOtherTest.advanced_level_exam_array).map(
//               (item: any) => `${item.alExam} - ${item.grade} (${formatDate(item.dateTaken)})`
//             )}
//           />
//           <ItemContent
//             label="Do you have any Predicted Advanced Level Exam Grades you wish to report?"
//             values={testStore.myOtherTest.is_have_predicted_advanced_level_exam_wish_report}
//             mdCol={6}
//           />
//           <ItemContent
//             label="How many Predicted Advanced Level Exams would you like to report?"
//             values={testStore.myOtherTest.number_of_predicted_advanced_level_exam_report}
//             mdCol={6}
//           />
//           <ItemContent
//             label="Predicted Advanced Level Exams (AL Exam - Grade)"
//             values={checkEnableParseJSON(
//               testStore.myOtherTest.predicted_advanced_level_exam_array
//             ).map(
//               (item: any) =>
//                 `${item.alExam} - ${item.predictedGrade} (${formatDate(item.dateTaken)})`
//             )}
//             mdCol={12}
//           />
//         </>
//       )}
//     </Row>
//   );
// };
const renderFillAppStatus = (item: any) => {
  return (
    <span>
      {item.isIncludeIntoCommonApp && (
        <img
          alt="cm"
          src="/assets/icons/icon-cm-5.svg"
          style={{ marginTop: '-8px', marginLeft: '4px' }}
        />
      )}
      {item.isIncludeIntoUCApp && (
        <img
          alt="uc"
          src="/assets/icons/icon-uc-5.svg"
          style={{ marginTop: '-8px', marginLeft: '4px' }}
        />
      )}
      {item.isIncludeIntoCSUApp && (
        <img
          alt="csu"
          src="./assets/icons/icon-csu-5.svg"
          style={{ marginTop: '-8px', marginLeft: '4px' }}
        />
      )}
    </span>
  );
};
