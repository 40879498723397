/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useMemo } from 'react';
import { useFormik } from 'formik';
import SelectInput from 'components/input/SelectInput';
import TextInput from 'components/input/textInput';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import CardConfig from 'components/CardConfig';
import { 
  AToFGrade,
  gradingScaleOptions, 
  scheduleOptions, 
  schoolYearOptions,
  to100Grade,
  to4Grade, 
} from './courseConstant';
import { getCollegeData, updateHighSchoolFromCourse } from 'app/highSchoolSlice/highSchoolSlice';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { routers } from 'router/router';
import { logout } from 'app/basicInformationSlice/basicInformationSlice';
import { authenticateSettings } from 'components/helper/appConfig';
import SubjectConfig from './SubjectConfig';
import { FormCourse, subjectDetailModal } from 'modal/course';
import { 
  checkEnableParseJSON,
  optionValues,
  optionValues2,
  removeDuplicate, 
  reorder 
} from 'components/helper/helper';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Button } from 'react-bootstrap';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import YesNoSwitch from 'components/input/YesNoSwitch';

export function ordinal_suffix_of(n: number) {
  let i = n + 1;
  let j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return i + 'st';
  }
  if (j === 2 && k !== 12) {
    return i + 'nd';
  }
  if (j === 3 && k !== 13) {
    return i + 'rd';
  }
  return i + 'th';
}

const buildYears = (selectedSchool: any) => {
  if (selectedSchool?.other?.academic_year) {
    return selectedSchool?.other?.academic_year;
  } else {
    return [
      selectedSchool?.other?.st_academic_year,
      selectedSchool?.other?.nd_academic_year,
      selectedSchool?.other?.rd_academic_year,
      selectedSchool?.other?.th_academic_year,
    ].filter((i) => !!i && i !== '-- select --' && i !== '- Select -');
  }
};

interface Props {
  is12?: boolean;
  defaultGradeValue?: string;
  handleSave: (value: any) => void;
  item: any;
  index: number;
  total: number;  
}
const CourseConfig = (props: Props) => {
  const MAX_SUBJECTS = 15;
  const highSchoolData = useAppSelector((store) => store.highSchool.highSchoolData);
  const collegeData = useAppSelector((store) => store.highSchool.collegeData);
  const myHighSchool = useAppSelector((store) => store.highSchool.myHighSchool);
  const dispatch = useAppDispatch();
  const history = useHistory();
  let studentId = Cookies.get('userId');
  let token = Cookies.get(authenticateSettings.tokenName);
  const initialValues: FormCourse = {
    schoolName: '',
    schoolCode: '',
    schoolYear: '',
    CMGradingScale: '',
    CNGradingScale: '',
    grade: [],
    finalGrade: '',
    credits: [],
    finalCredits: '',
    checkedNA: false,
    otherGradeScale: '',
    highestGrade: '',
    lowestGrade: '',
    subject: '',
    courseName: '',
    class: '',
    otherClass: '',
    schedule: '',
    otherSchedule: '',
    schedule_transcript: '',
    isFirstSemester: false,
    isSecondSemester: false,
    isThirdSemester: false,
    isFourthSemester: false,
    isTakeIn678Grade: false,
    isUsedBlockScheduling: false,
    classOccurredDuringTime: 'Regular academic year',
    classOccurredDuringGrade: props.defaultGradeValue ?? '9th or earlier',
    typeOfClass: '',
    isTakeClassAtCollege: false,
    collegeTookClass: '',
    subjectDetails: [],
    number_of_summer_courses: 0,
    have12thGradeCourses: '',
    allNoneAGCourses: false,
  };
  const formItem = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      let count = values.subjectDetails?.filter((i: any) => i.isSummerCourse).length;
      values.number_of_summer_courses = count;
    },
  });

  const initGradingOptions = () =>{
    let result: React.SetStateAction<any[]> = [];
    switch (formItem.values.CMGradingScale) {
      case 'A-F':
        result = AToFGrade;
        break;
      case '1-100':
        result = [...to100Grade, ...optionValues2(106)];
        break;
      case '0.0-4.0':
        result = [...to4Grade, ...optionValues(Array.from({ length: 41 }, (_, i) => i / 10))];
        break;
      case '0.0-5.0':
        result = [...to4Grade, ...optionValues(Array.from({ length: 51 }, (_, i) => i / 10))];
        break;
      case '1-7':
        result = [...to4Grade, ...optionValues(Array.from({ length: 7 }, (_, i) => i + 1))];
        break;
      case '1-10':
        result = [...to4Grade, ...optionValues(Array.from({ length: 10 }, (_, i) => i + 1))];
        break;
      case '1-11':
        result = [...to4Grade, ...optionValues(Array.from({ length: 11 }, (_, i) => i + 1))];
        break;
      case '1-20':
        result = [...to4Grade, ...optionValues(Array.from({ length: 20 }, (_, i) => i + 1))];
        break;
      default:
        result = [];
    }
    if (props.is12) {
      let newresult: React.SetStateAction<any[]> = [];
      newresult = [...result, 'In Progress', 'Planned'];
      result = newresult;
    }
    return result;
  };

  const [gradingOptions, setGradingOptions] = useState<any[]>([]);
  useEffect(() => {
    setGradingOptions(initGradingOptions);
  }, [formItem.values.CMGradingScale]);

  // const gradingOptions:any[] = useMemo(() => {
  //   return initGradingOptions()
  // }, [formItem.values.CMGradingScale]);


  const [schoolError, setSchoolError] = useState<null | string>(null);
  // Generate Year Options
  const genYearOptions = (selectedSchool: any) => {
    if (selectedSchool.other) {
      const years = buildYears(selectedSchool);

      if (!!years) {
        const yearOptions = removeDuplicate(years).map((element: any) => {
          return {
            id: element.replaceAll(' ', ''),
            name: element.replaceAll(' ', '').replaceAll('-20', '-'),
          };
        });
        setYearOptions(yearOptions);
        setSchoolError(null);
      } else {
        setYearOptions([]);
        setSchoolError('Year not found, please select valid year in highschool section');
      }
    }
  };

   const handleLogout = () => {
    dispatch(logout());
    Cookies.remove(authenticateSettings.tokenName);
    Cookies.remove('userId');
    history.push(routers.login.path);
  };    

  useEffect(() => {
    studentId = Cookies.get('userId');
    token = Cookies.get(authenticateSettings.tokenName);
    if (token && studentId) {
   
      if (props.item?.schoolName && !!highSchoolData) {
        const loadedValues = {
          ...formItem.values,
          ...props.item,
          schoolName: !highSchoolData.find((i) => i.id === props.item.schoolName)
            ? '-'
            : props.item.schoolName,
          schoolCode: !highSchoolData.find((i) => i.id === props.item.schoolName)
            ? ''
            : props.item.schoolCode,
        };

        //#region Fix final Grade not show
        if (loadedValues.schedule=== 'Yearly' || loadedValues.schedule === 'Other') {
          loadedValues.subjectDetails.forEach((item:any, index:number) =>{
            item.schedule_transcript = '';
          });
        }
        //#endregion
  
        formItem.setValues(loadedValues);
        let selectedSchool = highSchoolData.find((i) => i.id === props.item.schoolName);
        if (!!selectedSchool) {
          genYearOptions(selectedSchool);
        }
      }
  
      //if (props.is12 && !collegeData.length) dispatch(getCollegeData(studentId));
      if (props.is12) {
        dispatch(getCollegeData(studentId));
      }
    }
    else {
      handleLogout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highSchoolData]);

  const handleChangeCourseUpdateToHighschool = (value: any) => {
    const highschoolList = checkEnableParseJSON(myHighSchool?.high_school_array, []);
    const index = highschoolList?.findIndex(
      (i: any) => i.high_school_name === value?.schoolName || i.fullName === value?.schoolName
    );
    if (
      value?.schoolName &&
      index >= 0 &&
      highschoolList[index]?.allNoneAGCourses !== value?.allNoneAGCourses
    ) {
      const newHighschools = [...highschoolList];
      newHighschools[index].allNoneAGCourses = value?.allNoneAGCourses;

      newHighschools.length &&
        dispatch(
          updateHighSchoolFromCourse({
            ...myHighSchool,
            high_school_array: JSON.stringify(newHighschools),
          })
        );
    }
  };

  useEffect(() => {
    if (formItem.dirty) {
      let count = formItem.values.subjectDetails?.filter((i: any) => i.isSummerCourse).length;
      const newValues = {
        ...formItem.values,
        allNoneAGCourses: !(
          formItem.values.subjectDetails?.filter((i: any) => i.AGSubjectArea !== 'Non A-G Course')
            ?.length > 0
        ),        
      };
      newValues.number_of_summer_courses = count;
      handleChangeCourseUpdateToHighschool(newValues);

      props.handleSave(newValues);
    }
  }, [formItem.values]);

  const handleAddSubject = () => {
    if (formItem.values.subjectDetails.length > MAX_SUBJECTS - 1) return;
    formItem.setFieldValue('subjectDetails', [
      ...formItem.values.subjectDetails,
      subjectDetailModal,
    ]);
  };

  const [forceRenderKey, setForceRenderKey] = useState(0);

  const onDragEnd = async (result: any) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }
    const dataList = reorder(
      formItem.values.subjectDetails,
      result.source.index,
      result.destination.index
    );
    formItem.setFieldValue('subjectDetails', [...dataList]);
    setForceRenderKey(forceRenderKey + 1);
  };

  const [yearOptions, setYearOptions] =
    useState<{ id: string; name: string }[]>(schoolYearOptions);

  const onSelectSchool = (e: any) => {
    formItem.handleChange(e);
    formItem.setFieldValue(
      'schoolCode',
      highSchoolData.find((item) => item.id === e.target.value)?.code
    );
    let selectedSchool = highSchoolData.find((item) => item.id === e.target.value);
    if (!!selectedSchool) {
      genYearOptions(selectedSchool);
    }
  };
  return (
    <CardConfig
      headerElement={
        <div className="w-100 d-flex justify-content-between">
          <span className="p-1">
            {formItem.values.schoolName}{' '}
            {formItem.values.schedule ? `(${formItem.values.schedule})` : ''}
          </span>
          <strong className="text-field-light">
          {props.index}/{props.total}
          </strong>
        </div>    
      }
      handleSave={() => {
        props.handleSave({
          ...formItem.values,
        });
      }}
    >
      <SelectInput
        label="Select the school for this coursework"
        option={highSchoolData.map((i) => i.id)}
        className="common-flag"
        mdCol="12"
        placeholder="Search your school"
        name="schoolName"
        values={formItem.values.schoolName}
        onChange={onSelectSchool}
      />
      <YesNoSwitch
        logo="span-cm-logo"
        hidden={!props.is12}
        name="have12thGradeCourses"
        values={formItem.values.have12thGradeCourses}
        onClear={() => formItem.setFieldValue('have12thGradeCourses', '')}
        onChange={formItem.handleChange}
        label="Do you have 12th grade courses on your transcript with official grades? (If your 12th grade courses are still in progress, report them in the “Current or Most Recent Year Courses” section of your Common App)* "
      />
      <SelectInput
        className="common-flag"
        form={formItem}
        option={yearOptions}
        mdCol="4"
        label="School Year*"
        values={formItem.values.schoolYear}
        name="schoolYear"
        onChange={formItem.handleChange}
        feedback={<span style={{ color: 'red' }}>{schoolError}</span>}
      />
      <SelectInput
        mdCol="4"
        form={formItem}
        option={scheduleOptions}
        label="Schedule*"
        values={formItem.values.schedule}
        name="schedule"
        onChange={(e:any)=> {
          if (e.target.value === 'Yearly' || e.target.value === 'Other') {
            formItem.values.subjectDetails.forEach((item:any, index:number) =>{
              formItem.setFieldValue(`subjectDetails[${index}].schedule_transcript`, '');
            });
          }
          formItem.handleChange(e);
        }}
      />
      {formItem.values.schedule === 'Other' && (
        <TextInput
          countText={30}
          mdCol="4"
          label="Please describe schedule*"
          values={formItem.values.otherSchedule}
          name="otherSchedule"
          onChange={formItem.handleChange}
        />
      )}
      <SelectInput
        className="common-flag end-flag"
        mdCol="4"
        form={formItem}
        option={gradingScaleOptions}
        label="Grading Scale"
        values={formItem.values.CMGradingScale}
        name="CMGradingScale"
        onChange={(e: any) => {
          try {
            formItem.setFieldValue(`CMGradingScale`, e.target.value);
            setGradingOptions(initGradingOptions);
            formItem.values.subjectDetails.forEach((item:any, index:number) =>{
              try {
                  // reset grades[] values
                  let grade:any[] = item.grade;
                  if (grade && grade.length>0) {
                    if (!grade.includes(gradingOptions)) {
                      for(let k=0; k<grade.length; k++ ) {
                        formItem.setFieldValue(`subjectDetails[${index}].grade[${k}]`, "");
                      }
                    }
                  }
                  
                  // reset finalGrade
                  if (formItem.values.subjectDetails[index]?.schedule_transcript !=='One grade per term' ) {
                    formItem.setFieldValue(`subjectDetails[${index}].finalGrade`, "");
                  }
              }
              catch(ex2){
                console.log('Error:', ex2);
              }
            });
          }
          catch(ex){
            console.log('Error:', ex);
          }

        }}
        
      />
      <div />

      <DragDropContext onDragEnd={onDragEnd} key={forceRenderKey}>
        <Droppable droppableId="list">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {formItem.values.subjectDetails.map((item, index) => (
                <div key={index + '-' + formItem.values.subjectDetails.length}>
                  <Draggable
                    draggableId={index + '-' + formItem.values.subjectDetails.length}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div
                          key={index + '-' + formItem.values.subjectDetails.length}
                          className="position-relative"
                        >
                          <SubjectConfig
                            form={formItem}
                            item={item}
                            index={index}
                            total={formItem.values.subjectDetails.length}
                            is12={props.is12}
                            gradingOptions={gradingOptions}
                          />
                          <RemoveCircleOutlineIcon
                            className="position-absolute text-field-light pointer"
                            style={{ right: '-24px', top: '12px' }}
                            onClick={() => {
                              const newData = [...formItem.values.subjectDetails];
                              newData.splice(index, 1);
                              formItem.setFieldValue('subjectDetails', newData);
                              
                              let count = formItem.values.subjectDetails?.filter((i: any) => i.isSummerCourse).length;
                              formItem.setFieldValue('number_of_summer_courses', count);
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </Draggable>
                </div>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div className="my-3 w-100 d-flex flex-column align-items-center">        
        <div className="text-field-light sized70">{`You can add up to ${MAX_SUBJECTS} subjects`}</div>
        <Button variant="primary" className="p-2 px-5" onClick={handleAddSubject}>
          Add Subject
        </Button>
      </div>
      <div className="border w-100 my-3" />
    </CardConfig>
  );
};

export default CourseConfig;
