import React from 'react';
import { Badge, Col, Row } from 'react-bootstrap';
// import RadioButton from './RadioButton';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
interface Props {
  hidden?: boolean;
  name?: string;
  values?: any;
  onChange?: any;
  options: any[];
  mdCol?: number;
  disabled?: boolean;
  className?: string;
  label?: string;
  hiddenFormLabel?: boolean;
  onClear?: any;
  logo?: string;
  helperText?: string;
}

const GroupRadioButton = (props: Props) => {
  const handleClear = () => {
    props.onClear && props.onClear();
  };
  return props.hidden ? null : (
    <FormControl className={`p-2 ${props.className ?? ''}`.trim()}>

      {(props.hiddenFormLabel === undefined || props.hiddenFormLabel === false) && (<FormLabel
        id={props.name}
        className={`label-14px pb-1 ${props.logo ?? ''}`.trim()}
      //style={{ display: 'inline-block' }}
      >
        {props.label}
      </FormLabel>)}

      {props.helperText && (
        <span className="sized70 w-100 mb-2 text-field-light">{props.helperText}</span>
      )}
      <RadioGroup onChange={props.onChange} value={props.values} name={props.name}>
        <Row>
          {(props.options || []).map((opt, index) => (
            <Col md={opt.mdCol || props.mdCol || 3} key={index}>
              <FormControlLabel
                disabled={props.disabled}
                value={opt.value || opt}
                control={<Radio size="small" />}
                label={opt.label || opt.value || opt}
              />
              {opt?.extendElement && opt?.value === props.values && <>{opt?.extendElement}</>}
            </Col>
          ))}
          {props.onClear && (
            <Col md={props.mdCol || 3}>
              <Badge bg="light" text="dark" className="pointer" onClick={handleClear}>
                Clear
              </Badge>
            </Col>
          )}
        </Row>
      </RadioGroup>
      <style scoped>{`
        span .MuiFormControlLabel-label {
          font-size: 14px !important
        }
      `}</style>
    </FormControl>
  );
};

export default GroupRadioButton;
