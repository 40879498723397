/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, FormControlLabel, Stack } from '@mui/material';
import React from 'react';
import { Form } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';

interface Props {
  formStyleLabel?: string;
  tooltip?: string;
  mdCol?: string;
  label?: any;
  name: string;
  values: any;
  onChange?: (e: any) => void;
  isValid?: boolean;
  feedback?: any;
  placeholder?: string;
  disabled?: boolean;
  readOnly?: boolean;
  className?: string;
  id?: string;
  logo?: string;
  setValue?: (val: boolean) => void;
}

const CheckboxInput = (props: Props) => {
  const [checked, setChecked] = React.useState<boolean>(false);
  React.useEffect(() => {
    if (Array.isArray(props.values) && props.setValue) {
      props.values.includes('on') ? props.setValue(true) : props.setValue(false);
    } else if (Array.isArray(props.values)) {
      props.values.includes('on') ? setChecked(true) : setChecked(false);
    } else if (props.values === true) {
      setChecked(true);
    } else {
      setChecked(false);
    }
    if (Array.isArray(props.values) && props.setValue) {
      props.values.includes('on') ? props.setValue(true) : props.setValue(false);
    }
  }, [props.values]);
  return (
    <div className={props.className}>
      <Stack direction="row" alignItems="center" sx={{ paddingLeft: '4px', marginTop: '1px' }}>
        {!!props.formStyleLabel && (
          <Form.Label
            style={{
              fontSize: '1rem',
              color: '#4E5A6F',
            }}
            className={`text-field-mid mt-3`}
          >
            {props.formStyleLabel}
          </Form.Label>
        )}
        <FormControlLabel
          sx={{ alignItems: 'flex-start' }}
          className="text-field-mid"
          control={
            <Checkbox
              id={`${props?.id ?? (props.name + '_' + uuidv4())}`}
              disabled={props.disabled}
              checked={checked ?? false}
              data-val="true"
              placeholder={props.placeholder}
              name={props.name}
              onChange={props.onChange}
              readOnly={props.readOnly}
              sx={{ paddingY: '0px', paddingTop: '.2em', opacity: (props.readOnly ? '0.8' : '') }}
            />
          }
          label={<span className={`${props.logo}`}>{props.label}</span>}
        />
      </Stack>
      {props.feedback}
    </div>
  );
};

export default CheckboxInput;
