import React from 'react';
import { Avatar } from '@mui/material';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import SettingsIcon from '@mui/icons-material/Settings';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Button, Dropdown, ButtonGroup } from 'react-bootstrap';
import { routers } from 'router/router';
import { useHistory } from 'react-router';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import basicInformationServices from 'services/basicInformationApi';
import { FileToBase64 } from 'components/helper/helper';
import { logout, getStripeSubscription } from 'app/basicInformationSlice/basicInformationSlice';
import toast from 'react-hot-toast';
import { authenticateSettings } from 'components/helper/appConfig';
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';
import { renderTrialMessage } from './header';

interface Props {}
const MobileSettingHeader = (props: Props) => {
  let location = useLocation();
  const dispatch = useAppDispatch();
  let history = useHistory();
  const catName = function (firstName: string, midName: string, lastName: string) {
    var s2 = midName ? ' ' + midName + ' ' : ' ';
    return firstName ?? '' + s2 ?? '' + lastName ?? '';
  };
  const [stripeSubscription, setStripeSubscription] = React.useState<any>(null);
  const [profilePicture, setProfilePicture] = React.useState<any>();
  const personalStore = useAppSelector((store) => store.basicInformation.myBasicInformation);
  const displayName = catName(
    personalStore?.first_name,
    personalStore?.middle_name,
    personalStore?.last_name
  );
  
  React.useEffect(() => {
    const getData = async() => {
      let id = Cookies.get('userId');
      let token = Cookies.get(authenticateSettings.tokenName);
      if (token && id) {
        let ss:any=  await dispatch(getStripeSubscription(id)).unwrap();
        setStripeSubscription(ss);
      }
    }

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  React.useEffect(() => {
    const profilePictureName = personalStore?.profile_picture_file_name ?? null;
    if (profilePictureName) {
      basicInformationServices.getProfilePicture(profilePictureName).then(async (res) => {
        const blob = new Blob([res], { type: 'image/png;charset=utf-8' });
        const url = await FileToBase64(blob);
        setProfilePicture(url);
      });
    }
  }, [personalStore?.profile_picture_file_name]);

  const handleLogout = (showMsg:boolean) => {
    dispatch(logout());
    if (showMsg) {
      toast.success("You have been successfully logged out!");
    }
  };
  return (
    <>
    <div id="mobile-setting-header" className="gm-topbar">
      <div className="d-flex gm-topbar-height">
        <img
          src={'/assets/images/logo-color.svg'}
          alt="sb-logo"
          style={{ alignSelf: 'center' }}
          height={48}
          className="d-inline mx-3 pointer"
          onClick={() =>
            history.push({
              pathname: routers.dashBoard.path,
              search: `?role=${localStorage.getItem('userRole')}`,
            })
          }
        />
      </div>
      <div className="mx-2">
        <Button variant="outline-dart" className="border rounded" size="sm">
          <NotificationsOutlinedIcon />
        </Button>
      </div>
      <div className="d-flex justify-content-end align-items-center">
        <span>
          <Avatar
            className="me-md-1"
            alt="sb-avatar"
            src={profilePicture}
            sx={{ width: 40, height: 40 }}
          />
        </span>
        <span>
          <Dropdown as={ButtonGroup} size="sm">
            <Dropdown.Toggle variant="white" id="dropdown-split-basic">
              <div className="d-flex">
                <div className="text-start text-left">
                  <span>{displayName}</span>
                  <div className="text-field-light">
                    {personalStore?.year_finish_high_school &&
                      `Class of ${personalStore?.year_finish_high_school}`}
                  </div>
                </div>
                <div className="icon-arrowdown">
                  <ArrowDropDownIcon sx={{ color: '#231F20' }}></ArrowDropDownIcon>
                </div>
                <div className="icon-arrowup">
                  <ArrowDropUpIcon sx={{ color: '#231F20' }}></ArrowDropUpIcon>
                </div>
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => history.push(routers.settings.path)}>
                <SettingsIcon fontSize="small" className="mx-1" />
                Settings
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleLogout(true)}>
                <PowerSettingsNewIcon fontSize="small" className="mx-1" />
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </span>
      </div>
    </div>

    <div className="d-flex w-100 justify-content-center text-danger">
    { stripeSubscription && stripeSubscription?.status === 'trialing' && (
      <div className="d-flex text-danger text-center pl-3 align-self-center " style={{margin:'8px'}} >
        {`${renderTrialMessage(stripeSubscription)}`}
        {!location.pathname.includes('/payment') && (
          <>
          &nbsp;<a href="/#/payment">Click here to subscribe!</a>
          </>
        )}
      </div>
    )}
    </div>
    </>
  );
};

export default MobileSettingHeader;
