import { Card, Stack, Tab, Tabs, Typography } from '@mui/material';
import React from 'react';
import CustomTabPanel from './TabPanel';
import LoadingContent from 'components/LoadingContent';
import ItemContent, { CheckboxContent } from 'screen/summary/components/ItemContent';
import { Row } from 'react-bootstrap';
import { checkEnableParseJSON, paserSubjectFirst, checkEmptyValue, checkSubjectMap, checkNoCreditAwarded, checkNoCreditEligible, getPointYearFromSchedule, checkNoCreditGradeCollege, getKeyTypeOfCourse, checkWeightedCourse } from 'components/helper/helper';
import { useParams } from 'react-router-dom';

import courseServices from 'services/courseServices';
import CourseGrade9Summary from 'screen/summary/components/CourseGrade9Summary';
import CourseCollegeSum from 'screen/summary/components/CourseCollegeSum';
import SimpleTable from 'components/table/SimpleTable';
import { useAppSelector } from 'app/hooks';
import { buildClassName, agSubjectTotals, wCourseSummary } from './RPersonalInformation';


const RCoursesAndGrades = ({
  setCurrentModuleId,
}: {
  setCurrentModuleId: (id: string) => void;
}) => {
  const checkModule = useAppSelector((store) => store.basicInformation.checkModule);
  const [value, setValue] = React.useState(1);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setCurrentModuleId(`5.${newValue}`);
  };
  return (
    <div>
      <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto">
        <Tab value={1} label="General" className={buildClassName('5.1', checkModule)} />
        <Tab value={2} label="9th" className={buildClassName('5.2', checkModule)} />
        <Tab value={3} label="10th" className={buildClassName('5.3', checkModule)} />
        <Tab value={4} label="11th" className={buildClassName('5.4', checkModule)} />
        <Tab value={5} label="12th" className={buildClassName('5.5', checkModule)} />
        <Tab value={6} label="College Courses" className={buildClassName('5.6', checkModule)} />
        <Tab value={7} label="A-G Summary" className={buildClassName('5.7', checkModule)} />
        <Tab value={8} label="(W) Course Summary" className={buildClassName('5.8', checkModule)} />
      </Tabs>
      <CustomTabPanel value={value} index={1}>
        <Card sx={{ backgroundColor: 'var(--ui-grey-soft, #F7F8FA)', margin: 2, padding: 2 }}>
          <Typography sx={{ fontSize: '1.75rem', fontWeight: '500', color: '#4E5A6F' }}>
            General
          </Typography>
          <Stack justifyContent={'center'} alignItems={'center'}>
            <SummaryGeneral />
          </Stack>
        </Card>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Card sx={{ backgroundColor: 'var(--ui-grey-soft, #F7F8FA)', margin: 2, padding: 2 }}>
          <Typography sx={{ fontSize: '1.75rem', fontWeight: '500', color: '#4E5A6F' }}>
            9th
          </Typography>
          <Stack justifyContent={'center'} alignItems={'center'}>
            <Summary9th />
          </Stack>
        </Card>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <Card sx={{ backgroundColor: 'var(--ui-grey-soft, #F7F8FA)', margin: 2, padding: 2 }}>
          <Typography sx={{ fontSize: '1.75rem', fontWeight: '500', color: '#4E5A6F' }}>
            10th
          </Typography>
          <Stack justifyContent={'center'} alignItems={'center'}>
            <Summary10th />
          </Stack>
        </Card>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        <Card sx={{ backgroundColor: 'var(--ui-grey-soft, #F7F8FA)', margin: 2, padding: 2 }}>
          <Typography sx={{ fontSize: '1.75rem', fontWeight: '500', color: '#4E5A6F' }}>
            11th
          </Typography>
          <Stack justifyContent={'center'} alignItems={'center'}>
            <Summary11th />
          </Stack>
        </Card>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={5}>
        <Card sx={{ backgroundColor: 'var(--ui-grey-soft, #F7F8FA)', margin: 2, padding: 2 }}>
          <Typography sx={{ fontSize: '1.75rem', fontWeight: '500', color: '#4E5A6F' }}>
            12th
          </Typography>
          <Stack justifyContent={'center'} alignItems={'center'}>
            <Summary12th />
          </Stack>
        </Card>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={6}>
        <Card sx={{ backgroundColor: 'var(--ui-grey-soft, #F7F8FA)', margin: 2, padding: 2 }}>
          <Typography sx={{ fontSize: '1.75rem', fontWeight: '500', color: '#4E5A6F' }}>
            College Courses
          </Typography>
          <Stack justifyContent={'center'} alignItems={'center'}>
            <SummaryCollegeCourses />
          </Stack>
        </Card>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={7}>
        <Card sx={{ backgroundColor: 'var(--ui-grey-soft, #F7F8FA)', margin: 2, padding: 2 }}>
          <Typography sx={{ fontSize: '1.75rem', fontWeight: '500', color: '#4E5A6F' }}>
            A-G Summary
          </Typography>
          <Stack justifyContent={'center'} alignItems={'center'}>
            <AGSummary />
          </Stack>
        </Card>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={8}>
        <Card sx={{ backgroundColor: 'var(--ui-grey-soft, #F7F8FA)', margin: 2, padding: 2 }}>
          <Typography sx={{ fontSize: '1.75rem', fontWeight: '500', color: '#4E5A6F' }}>
            (W) Course Summary
          </Typography>
          <Stack justifyContent={'center'} alignItems={'center'}>
            <WCourseSummary />
          </Stack>
        </Card>
      </CustomTabPanel>
    </div>
  );
};

export default RCoursesAndGrades;

const SummaryGeneral = () => {
  const [myGeneralInfo, setMyGeneralInfo] = React.useState<any>(null);
  const [loading, setLoading] = React.useState(false);
  const params: { id?: string | number } = useParams();

  React.useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const res: any = await courseServices.getGeneral(params.id);
      setMyGeneralInfo({ ...res, additional_info: checkEnableParseJSON(res.additional_info, {}) });
      setLoading(false);
    };
    params.id !== ':id' && getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);
  return loading ? (
    <LoadingContent />
  ) : (
    <Row className="w-100">
      {myGeneralInfo && (
        <>
          <ItemContent
            mdCol={6}
            label="I am able to obtain a copy of my transcript(s)"
            values={myGeneralInfo.is_able_obtain_copy_transcript}
          />
          {!myGeneralInfo.is_able_obtain_copy_transcript && (
            <ItemContent
              mdCol={6}
              label="Based on your resspanonses, you are not required to complete this section. Colleges who are listed above as requiring this information will not penalize you. Please have your counselor submit your transcripts as per their usual methods"
              values={myGeneralInfo.is_counselor_submit_transcript}
            />
          )}
          <ItemContent
            mdCol={6}
            label="My transcript shows grades for courses I've completed"
            values={myGeneralInfo.is_transcript_show_grade_completed}
          />
          {!myGeneralInfo.is_transcript_show_grade_completed && (
            <ItemContent
              mdCol={6}
              label="Based on your resspanonses, you are not required to complete this section. Colleges who are listed above as requiring this information will not penalize you. Please have your counselor submit your transcripts as per their usual methods"
              values={myGeneralInfo.is_counselor_submit_transcript_2}
            />
          )}
          <div className="border w-100 my-3" />
          <div className="text-field-light fs-5">Middle School - Academic History</div>
          <div className="text-field-light">
            Mathematics or foreign language courses are common middle courses that count toward your
            high school requirements.
          </div>
          <div className="text-field-light">
            If any of your middle school courses are listed on your transcript, be sure to list them
            here, too.
          </div>
          <div className="text-field-light">
            Note: On the college applications, you do not need to list the courses under your middle
            school name, they will be listed under your high school
          </div>
          <ItemContent
            mdCol={12}
            label="Did you take any course(s) in middle school that fulfilled your high school subject requirements?"
            values={myGeneralInfo?.additional_info?.is_take_course_in_middle_school}
          />
          <ItemContent
            mdCol={12}
            label="Do you have middle school courses listed on your transcript that have been issued official transcript grades?*"
            values={
              myGeneralInfo?.additional_info?.is_have_course_issued_official_transcript_grades
            }
          />
          <div className="text-field-light fs-5">Middle School Coursework</div>
          {(myGeneralInfo?.additional_info?.course_work ?? []).map((course: any, index: number) => {
            return (
              <Row key={index}>
                <div className="p-2 fs-5 fw-bold text-field-light">{course?.grade_level}</div>
                <ItemContent
                  mdCol={12}
                  label="Select the school for this coursework"
                  values={course.school}
                />
                <ItemContent label="School Year*" values={course?.school_year} />
                <ItemContent label="Schedule" values={course?.schedule} />
                <ItemContent label="Grading Scale" values={course?.grade_scale} />
                <Row className="bg-white rounded p-3">
                  <ItemContent label="Course Name" values={course?.course_name} />
                  <ItemContent label="A-G Subject Area" values={course?.ag_subject_requirement} />
                  <ItemContent label="Subject/Discipline" values={course?.subject_discipline} />
                  <ItemContent label="Type of Class" values={course?.type_of_class} />
                  <ItemContent
                    hidden={course.schedule === 'Yearly'}
                    label="During which term(s) did you take this class?"
                    values={buildSemesterData(course)}
                  />
                  <ItemContent
                    hidden={course.schedule === 'Yearly'}
                    label="How are the grades for this class reported on your transcript?"
                    values={course?.schedule_transcript}
                  />
                  <ItemContent
                    label="Is this a weighted course?"
                    values={course?.is_weighted_course}
                  />
                  <div />
                  {(course?.grades ?? []).map((grade: any, gradeIndex: any) => (
                    <Row key={gradeIndex}>
                      <ItemContent
                        label={[course?.schedule, gradeIndex + 1, 'Grade'].join(' ')}
                        values={course?.grades[gradeIndex]}
                      />
                      <ItemContent
                        label={[course?.schedule, gradeIndex + 1, 'Credit'].join(' ')}
                        values={course?.credits[gradeIndex]}
                      />
                    </Row>
                  ))}
                  <CheckboxContent
                    label="I took this class at a college and/or I received college credit for this class"
                    values={course?.is_take_class_at_college}
                  />
                  <ItemContent label="Select College *" mdCol={12} values={course?.college} />
                </Row>
              </Row>
            );
          })}

          <div className="border w-100 my-3" />
          <div className="text-field-light fs-5">International school experience</div>
          <ItemContent
            mdCol={12}
            label="Did you attend school outside the United States for any part of 6th through 8th grade?"
            values={myGeneralInfo.is_attend_school_outside_us_6_through_8}
          />
        </>
      )
      }
    </Row >
  );
};

const Summary9th = () => {
  const [myGradeAndCourse, setMyGradeAndCourse] = React.useState<any>(null);
  const [loading, setLoading] = React.useState(false);
  const params: { id?: string | number } = useParams();

  React.useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const res: any = await courseServices.getGradeAndCourse(params.id);
      setMyGradeAndCourse(res);
      setLoading(false);
    };
    params.id !== ':id' && getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);
  return loading ? (
    <LoadingContent />
  ) : (
    <Row className="w-100">
      {checkEnableParseJSON(myGradeAndCourse?.grade_9_course_array, []).length !== 0 && (
        <Row>
          <CourseGrade9Summary
            data={checkEnableParseJSON(myGradeAndCourse?.grade_9_course_array, [])}
          />
          <div className="text-field-light p-2">
            Please indicate that you have reported all courses and grades for 9th grade.*
          </div>
          <CheckboxContent
            values={myGradeAndCourse.is_reported_all_grade_9}
            label="I have reported all of my courses for this grade."
          />
        </Row>
      )}
    </Row>
  );
};

const Summary12th = () => {
  const [myGradeAndCourse, setMyGradeAndCourse] = React.useState<any>(null);
  const [loading, setLoading] = React.useState(false);
  const params: { id?: string | number } = useParams();
  React.useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const res: any = await courseServices.getGradeAndCourse(params.id);
      setMyGradeAndCourse(res);
      setLoading(false);
    };
    params.id !== ':id' && getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  let course_array = checkEnableParseJSON(myGradeAndCourse?.course_array, [])?.map((i: any) => {
    return {
      ...i,
      schedule: i.schedule.join(', '),
    };
  });
  return loading ? (
    <LoadingContent />
  ) : (
    <Row className="w-100">
      {myGradeAndCourse?.grade_12_course_array && (
        <Row>
          <CourseGrade9Summary
            data={checkEnableParseJSON(myGradeAndCourse?.grade_12_course_array, [])}
            is12={true}
          />
          <div className="text-field-light p-2">
            Please indicate that you have reported all courses and grades for 12th grade.*
          </div>
          <CheckboxContent
            values={myGradeAndCourse.is_reported_all_grade_12}
            label="I have reported all of my courses for this grade."
          />
          {checkEnableParseJSON(myGradeAndCourse?.course_title, {})?.is_indicated_all_course &&
            <>
              <div className="text-field-light p-2">
                Have you indicated all your middle school, post-12th grade, or summer courses?
              </div>
              <CheckboxContent
                values={
                  checkEnableParseJSON(myGradeAndCourse?.course_title, {})?.is_indicated_all_course
                }
                label="I have reported all of my courses for this grade."
              />
            </>
          }
          <div className="my-3 w-100 border" />
          <div className="fs-5 p-2 text-field-light">Current or Most Recent Year Courses</div>
          <div className="text-field-light fs-5 p-2">Coursework</div>
          <ItemContent
            mdCol={12}
            label="Please select the main course scheduling system your institution is using.**"
            values={myGradeAndCourse?.course_scheduling_system_is_using}
          />

          <CheckboxContent
            label="My 12th grade courses are my current courses"
            values={myGradeAndCourse.is_fields_enter_your_current_year_course}
          />
          <div className="text-field-light p-2">
            Please list all courses you are taking this academic year. If you are not currently
            enrolled, please list courses from your most recent academic year.
          </div>
          <SimpleTable
            data={course_array}
            column={[
              {
                label: 'Please select the course scheduling system your school is using',
                value: 'course_scheduling_system_is_using',
              },
              { label: 'Course title', value: 'title' },
              { label: 'Subject', value: 'subject' },
              { label: 'Course Level', value: 'level' },
              { label: 'Course Schedule', value: 'schedule' },
            ]}
          />
        </Row>
      )}
    </Row>
  );
};
const Summary10th = () => {
  const [myGradeAndCourse, setMyGradeAndCourse] = React.useState<any>(null);
  const [loading, setLoading] = React.useState(false);
  const params: { id?: string | number } = useParams();

  React.useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const res: any = await courseServices.getGradeAndCourse(params.id);
      setMyGradeAndCourse(res);
      setLoading(false);
    };
    params.id !== ':id' && getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);
  return loading ? (
    <LoadingContent />
  ) : (
    <Row className="w-100">
      {checkEnableParseJSON(myGradeAndCourse?.grade_10_course_array, []).length !== 0 && (
        <Row>
          <CourseGrade9Summary
            data={checkEnableParseJSON(myGradeAndCourse?.grade_10_course_array, [])}
          />
          <div className="text-field-light p-2">
            Please indicate that you have reported all courses and grades for 10th grade.*
          </div>
          <CheckboxContent
            values={myGradeAndCourse.is_reported_all_grade_10}
            label="I have reported all of my courses for this grade."
          />
        </Row>
      )}
    </Row>
  );
};
const Summary11th = () => {
  const [myGradeAndCourse, setMyGradeAndCourse] = React.useState<any>(null);
  const [loading, setLoading] = React.useState(false);
  const params: { id?: string | number } = useParams();

  React.useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const res: any = await courseServices.getGradeAndCourse(params.id);
      setMyGradeAndCourse(res);
      setLoading(false);
    };
    params.id !== ':id' && getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);
  return loading ? (
    <LoadingContent />
  ) : (
    <Row className="w-100">
      {checkEnableParseJSON(myGradeAndCourse?.grade_11_course_array, []).length !== 0 && (
        <Row>
          <CourseGrade9Summary
            data={checkEnableParseJSON(myGradeAndCourse?.grade_11_course_array, [])}
          />
          <div className="text-field-light p-2">
            Please indicate that you have reported all courses and grades for 11th grade.*
          </div>
          <CheckboxContent
            values={myGradeAndCourse.is_reported_all_grade_11}
            label="I have reported all of my courses for this grade."
          />
        </Row>
      )}
    </Row>
  );
};

const SummaryCollegeCourses = () => {
  const [myGradeAndCourse, setMyGradeAndCourse] = React.useState<any>(null);
  const [loading, setLoading] = React.useState(false);
  const params: { id?: string | number } = useParams();

  React.useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const res: any = await courseServices.getGradeAndCourse(params.id);
      setMyGradeAndCourse(res);
      setLoading(false);
    };
    params.id !== ':id' && getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);
  return loading ? (
    <LoadingContent />
  ) : (
    <Row className="w-100">
      {checkEnableParseJSON(myGradeAndCourse?.grade_9_course_array, []).length !== 0 && (
        <Row>
          {checkEnableParseJSON(myGradeAndCourse?.college_course_array, []).length !== 0 && (
            <>
              <CourseCollegeSum
                data={checkEnableParseJSON(myGradeAndCourse?.college_course_array, [])}
              />
            </>
          )}
          <div className="text-field-light mt-4">
            Please indicate that you added all of the courses you have taken or plan to take for
            every college
          </div>
          <CheckboxContent
            values={myGradeAndCourse?.is_finish_adding_all_college_grade}
            label={`I'm finished adding all the college grade classes I've taken or plan to take for every college I've attended. (I can still edit my classes if I need to.)`}
          />
        </Row>
      )}
    </Row>
  );
};

const AGSummary = () => {
  const [myAGSummary, setMyAGSummary] = React.useState<any>(null);
  const [loading, setLoading] = React.useState(false);
  const params: { id?: string | number } = useParams();

  const conditionGradeTH = (item: any, schedule: any, gradeScale: any) => {
    if (!item) return 0;

    const finalGrade = item.finalGrade;
    const finalCredit = item.finalCredits;

    if (schedule === 'Yearly' || schedule === 'Other') {
      if (gradeScale === 'A-F') {
        if (checkEmptyValue(finalGrade) || checkEmptyValue(finalCredit) || checkNoCreditAwarded(finalGrade)) {
          return 0;
        }
        return getPointYearFromSchedule(schedule);
      } else if (gradeScale === '1-100') {
        if (checkEmptyValue(finalGrade) || checkEmptyValue(finalCredit) || checkNoCreditEligible(finalGrade)) {
          return 0;
        }
        return getPointYearFromSchedule(schedule);
      }
    } else {
      //semester, quarter, trimester
      const scheduleTranscript = item.schedule_transcript;
      const grades = item.grade || []; //Gán mặc định là mảng rỗng để tránh lỗi nếu grades không tồn tại
      const credits = item.credits || [];

      if (gradeScale === 'A-F') {
        if (item.isFirstSemester === true && item.isSecondSemester === true) {
          return selectTwoSemester(scheduleTranscript, grades, credits, schedule, finalGrade, finalCredit);
        } else if (item.isFirstSemester === true && item.isSecondSemester === false) {
          return selectOneSemester(0, scheduleTranscript, grades, credits, schedule, finalGrade, finalCredit);
        } else if (item.isFirstSemester === false && item.isSecondSemester === true) {
          return selectOneSemester(1, scheduleTranscript, grades, credits, schedule, finalGrade, finalCredit);
        }
      } else if (gradeScale === '1-100') {
        if (item.isFirstSemester === true && item.isSecondSemester === true) {
          return selectTwoSemesterEligible(scheduleTranscript, grades, credits, schedule, finalGrade, finalCredit);
        } else if (item.isFirstSemester === true && item.isSecondSemester === false) {
          return selectOneSemesterEligible(0, scheduleTranscript, grades, credits, schedule, finalGrade, finalCredit);
        } else if (item.isFirstSemester === false && item.isSecondSemester === true) {
          return selectOneSemesterEligible(1, scheduleTranscript, grades, credits, schedule, finalGrade, finalCredit);
        }
      }
    }
    return 0;
  }

  const selectOneSemester = (gradeIndex: number, scheduleTranscript: any, grades: any, credits: any, schedule: any, finalGrade: any, finalCredit: any) => {
    if (scheduleTranscript === 'One grade per term' || scheduleTranscript === 'One grade per term and one final grade') {
      let point = 0;
      if (!checkEmptyValue(grades[gradeIndex]) && !checkEmptyValue(credits[gradeIndex]) && !checkNoCreditAwarded(grades[gradeIndex])) {
        point += getPointYearFromSchedule(schedule);
      }
      return point;
    } else if (scheduleTranscript === 'One final Grade') {
      if (checkEmptyValue(finalGrade) || checkEmptyValue(finalCredit) || checkNoCreditAwarded(finalGrade)) {
        return 0;
      }
      return getPointYearFromSchedule(schedule);
    }
  }

  const selectOneSemesterEligible = (gradeIndex: number, scheduleTranscript: any, grades: any, credits: any, schedule: any, finalGrade: any, finalCredit: any) => {
    if (scheduleTranscript === 'One grade per term' || scheduleTranscript === 'One grade per term and one final grade') {
      let point = 0;
      if (!checkEmptyValue(grades[gradeIndex]) && !checkEmptyValue(credits[gradeIndex]) && !checkNoCreditEligible(grades[gradeIndex])) {
        point += getPointYearFromSchedule(schedule);
      }
      return point;
    } else if (scheduleTranscript === 'One final Grade') {
      if (checkEmptyValue(finalGrade) || checkEmptyValue(finalCredit) || checkNoCreditEligible(finalGrade)) {
        return 0;
      }
      return getPointYearFromSchedule(schedule);
    }
  }

  const conditionCollegeCourses = (item: any, scheduleSystem: string) => {
    if (!item || item?.honorStatus !== 'CL' || item?.transferable !== true || item?.completionStatus === 'Select' || checkEmptyValue(item?.grade) || checkNoCreditGradeCollege(item?.grade)) {
      return 0;
    }

    const pointYear = getPointYearFromSchedule(scheduleSystem);
    return pointYear;
  }

  //For Grade Scale A-F
  const selectTwoSemester = (scheduleTranscript: any, grades: any, credits: any, schedule: any, finalGrade: any, finalCredit: any) => {
    if (scheduleTranscript === 'One grade per term' || scheduleTranscript === 'One grade per term and one final grade') {
      let point = 0;
      for (let i = 0; i < 2; i++) { //Dùng vòng lặp for cho ngắn gọn
        if (!checkEmptyValue(grades[i]) && !checkEmptyValue(credits[i]) && !checkNoCreditAwarded(grades[i])) {
          point += getPointYearFromSchedule(schedule);
        }
      }
      return point;
    } else if (scheduleTranscript === 'One final Grade') {
      if (checkEmptyValue(finalGrade) || checkEmptyValue(finalCredit) || checkNoCreditAwarded(finalGrade)) {
        return 0;
      }
      return getPointYearFromSchedule(schedule);
    }
  }

  //For Grade Scale 1-100
  const selectTwoSemesterEligible = (scheduleTranscript: any, grades: any, credits: any, schedule: any, finalGrade: any, finalCredit: any) => {
    if (scheduleTranscript === 'One grade per term' || scheduleTranscript === 'One grade per term and one final grade') {
      let point = 0;
      for (let i = 0; i < 2; i++) { //Dùng vòng lặp for cho ngắn gọn
        if (!checkEmptyValue(grades[i]) && !checkEmptyValue(credits[i]) && !checkNoCreditEligible(grades[i])) {
          point += getPointYearFromSchedule(schedule);
        }
      }
      return point;
    } else if (scheduleTranscript === 'One final Grade') {
      if (checkEmptyValue(finalGrade) || checkEmptyValue(finalCredit) || checkNoCreditEligible(finalGrade)) {
        return 0;
      }
      return getPointYearFromSchedule(schedule);
    }
  }

  const conditionGeneralInfo = (item: any) => {
    if (!item || checkEmptyValue(item?.type_of_class) || checkEmptyValue(item?.is_weighted_course)) {
      return 0;
    }

    const schedule = item.schedule; // Lưu giá trị schedule để dùng lại
    const finalGrade = item.final_grade;
    const finalCredit = item.final_credit;
    const gradeScale = item.grade_scale;

    if (schedule === 'Yearly' || schedule === 'Other') {
      if (gradeScale === 'A-F') {
        if (checkEmptyValue(finalGrade) || checkEmptyValue(finalCredit) || checkNoCreditAwarded(finalGrade)) {
          return 0;
        }
        return getPointYearFromSchedule(schedule);
      } else if (gradeScale === '1-100') {
        if (checkEmptyValue(finalGrade) || checkEmptyValue(finalCredit) || checkNoCreditEligible(finalGrade)) {
          return 0;
        }
        return getPointYearFromSchedule(schedule);
      }
    } else {
      //semester, quarter, trimester
      const scheduleTranscript = item.schedule_transcript;
      const grades = item.grades || []; //Gán mặc định là mảng rỗng để tránh lỗi nếu grades không tồn tại
      const credits = item.credits || [];

      if (gradeScale === 'A-F') {
        if (item.isFirstSemester === true && item.isSecondSemester === true) {
          return selectTwoSemester(scheduleTranscript, grades, credits, schedule, finalGrade, finalCredit);
        } else if (item.isFirstSemester === true && item.isSecondSemester === false) {
          return selectOneSemester(0, scheduleTranscript, grades, credits, schedule, finalGrade, finalCredit);
        } else if (item.isFirstSemester === false && item.isSecondSemester === true) {
          return selectOneSemester(1, scheduleTranscript, grades, credits, schedule, finalGrade, finalCredit);
        }
      } else if (gradeScale === '1-100') {
        if (item.isFirstSemester === true && item.isSecondSemester === true) {
          return selectTwoSemesterEligible(scheduleTranscript, grades, credits, schedule, finalGrade, finalCredit);
        } else if (item.isFirstSemester === true && item.isSecondSemester === false) {
          return selectOneSemesterEligible(0, scheduleTranscript, grades, credits, schedule, finalGrade, finalCredit);
        } else if (item.isFirstSemester === false && item.isSecondSemester === true) {
          return selectOneSemesterEligible(1, scheduleTranscript, grades, credits, schedule, finalGrade, finalCredit);
        }
      }
    }
    return 0;
  }

  React.useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const res: any = await caculatorSubjectYears(params.id);
      setMyAGSummary(res);
      setLoading(false);
    };
    const caculatorSubjectYears = async (studentId: any) => {
      let countKeys = {
        'A': 0,
        'B': 0,
        'C': 0,
        'D1': 0,
        'D2': 0,
        'E': 0,
        'F': 0,
        'G': 0,
      };
      const resGradeAndCourse: any = await courseServices.getGradeAndCourse(studentId);
      const resGeneral: any = await courseServices.getGeneral(studentId);

      const summary9th = checkEnableParseJSON(resGradeAndCourse?.grade_9_course_array, []);
      const summary10th = checkEnableParseJSON(resGradeAndCourse?.grade_10_course_array, []);
      const summary11th = checkEnableParseJSON(resGradeAndCourse?.grade_11_course_array, []);
      const summary12th = checkEnableParseJSON(resGradeAndCourse?.grade_12_course_array, []);

      const summaryTH = [...summary9th, ...summary10th, ...summary11th, ...summary12th];
      const summaryCollegeCourses = checkEnableParseJSON(resGradeAndCourse?.college_course_array, []);
      const additional_info = checkEnableParseJSON(resGeneral?.additional_info, {});

      summaryTH.map((item: any) => {
        item?.subjectDetails?.map((subject: any) => {
          const pointYear = conditionGradeTH(subject, item?.schedule, item?.CMGradingScale);
          if (!checkEmptyValue(pointYear)) {
            const agSubjectArea = subject?.AGSubjectArea;
            const firstLetter = paserSubjectFirst(agSubjectArea).startLetter;
            let currentValue = (countKeys as any)[firstLetter];
            (countKeys as any)[firstLetter] = currentValue + pointYear;
          }
        })
      })

      summaryCollegeCourses.map((item: any) => {
        item?.collegeCourseArray?.map((subject: any) => {
          const scheduleSystem = item?.scheduleSystem;
          const pointYear = conditionCollegeCourses(subject, scheduleSystem);
          if (!checkEmptyValue(pointYear)) {
            const subjectAreaValue = subject?.subject;
            const keyMap = checkSubjectMap(subjectAreaValue);
            if (keyMap != null) {
              let currentValue = (countKeys as any)[keyMap];
              (countKeys as any)[keyMap] = currentValue + pointYear;
            }
          }
        })
      })

      additional_info?.course_work.map((item: any) => {
        const pointYear = conditionGeneralInfo(item);
        if (!checkEmptyValue(pointYear)) {
          const agSubjectArea = item?.ag_subject_requirement;
          const firstLetter = paserSubjectFirst(agSubjectArea).startLetter;
          let currentValue = (countKeys as any)[firstLetter];
          (countKeys as any)[firstLetter] = currentValue + pointYear;
        }
      })
      return countKeys;
    }
    params.id !== ':id' && getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);
  return loading ? (
    <LoadingContent />
  ) : (
    <Row className="w-100">
      <table className='table-subject-totals'>
        <thead>
          <tr>
            <th colSpan={4}>Subject</th>
            <th>Years</th>
          </tr>
        </thead>
        <tbody>
          {
            Object.entries(agSubjectTotals).map(([key, value]) => {
              return (
                <tr key={key}>
                  <td colSpan={4}>
                    <span className={"subject " + key}>{key}</span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span className='subject-text'> {value}</span></td>
                  <td>{myAGSummary?.[key]}</td>
                </tr>
              );
            })
          }
        </tbody>
      </table>
    </Row>
  );
};

const WCourseSummary = () => {
  const [myAGSummary, setMyAGSummary] = React.useState<any>(null);
  const [loading, setLoading] = React.useState(false);
  const params: { id?: string | number } = useParams();
  const [totalCredits, setTotalCredits] = React.useState(0);

  const conditionGradeTH = (item: any, schedule: any, gradeScale: any) => {
    if (!item) return 0;

    const finalGrade = item.finalGrade;
    const finalCredit = item.finalCredits;

    if (schedule === 'Yearly' || schedule === 'Other') {
      if (gradeScale === 'A-F') {
        if (checkEmptyValue(finalGrade) || checkEmptyValue(finalCredit)
          || checkNoCreditAwarded(finalGrade)) {
          return 0;
        }
        return getPointYearFromSchedule(schedule);
      } else if (gradeScale === '1-100') {
        if (checkEmptyValue(finalGrade) || checkEmptyValue(finalCredit)
          || checkNoCreditEligible(finalGrade)) {
          return 0;
        }
        return getPointYearFromSchedule(schedule);
      }
    } else {
      //semester, quarter, trimester
      const scheduleTranscript = item.schedule_transcript;
      const grades = item.grade || []; //Gán mặc định là mảng rỗng để tránh lỗi nếu grades không tồn tại
      const credits = item.credits || [];

      if (gradeScale === 'A-F') {
        if (item.isFirstSemester === true && item.isSecondSemester === true) {
          return selectTwoSemester(scheduleTranscript, grades, credits, schedule, finalGrade, finalCredit);
        } else if (item.isFirstSemester === true && item.isSecondSemester === false) {
          return selectOneSemester(0, scheduleTranscript, grades, credits, schedule, finalGrade, finalCredit);
        } else if (item.isFirstSemester === false && item.isSecondSemester === true) {
          return selectOneSemester(1, scheduleTranscript, grades, credits, schedule, finalGrade, finalCredit);
        }
      } else if (gradeScale === '1-100') {
        if (item.isFirstSemester === true && item.isSecondSemester === true) {
          return selectTwoSemesterEligible(scheduleTranscript, grades, credits, schedule, finalGrade, finalCredit);
        } else if (item.isFirstSemester === true && item.isSecondSemester === false) {
          return selectOneSemesterEligible(0, scheduleTranscript, grades, credits, schedule, finalGrade, finalCredit);
        } else if (item.isFirstSemester === false && item.isSecondSemester === true) {
          return selectOneSemesterEligible(1, scheduleTranscript, grades, credits, schedule, finalGrade, finalCredit);
        }
      }
    }
    return 0;
  }

  const selectOneSemester = (gradeIndex: number, scheduleTranscript: any, grades: any, credits: any, schedule: any, finalGrade: any, finalCredit: any) => {
    if (scheduleTranscript === 'One grade per term' || scheduleTranscript === 'One grade per term and one final grade') {
      let point = 0;
      if (!checkEmptyValue(grades[gradeIndex]) && !checkEmptyValue(credits[gradeIndex])
        && !checkNoCreditAwarded(grades[gradeIndex])) {
        point += getPointYearFromSchedule(schedule);
      }
      return point;
    } else if (scheduleTranscript === 'One final Grade') {
      if (checkEmptyValue(finalGrade) || checkEmptyValue(finalCredit)
        || checkNoCreditAwarded(finalGrade)) {
        return 0;
      }
      return getPointYearFromSchedule(schedule);
    }
  }

  const selectOneSemesterEligible = (gradeIndex: number, scheduleTranscript: any, grades: any, credits: any, schedule: any, finalGrade: any, finalCredit: any) => {
    if (scheduleTranscript === 'One grade per term' || scheduleTranscript === 'One grade per term and one final grade') {
      let point = 0;
      if (!checkEmptyValue(grades[gradeIndex]) && !checkEmptyValue(credits[gradeIndex])
        && !checkNoCreditEligible(grades[gradeIndex])) {
        point += getPointYearFromSchedule(schedule);
      }
      return point;
    } else if (scheduleTranscript === 'One final Grade') {
      if (checkEmptyValue(finalGrade) || checkEmptyValue(finalCredit)
        || checkNoCreditEligible(finalGrade)) {
        return 0;
      }
      return getPointYearFromSchedule(schedule);
    }
  }

  const conditionCollegeCourses = (item: any, scheduleSystem: string) => {
    if (!item || item?.honorStatus !== 'CL' || item?.transferable !== true || item?.completionStatus === 'Select' || checkEmptyValue(item?.grade) || checkNoCreditGradeCollege(item?.grade)) {
      return 0;
    }

    //Change logic make it so that every transferable college course is equal to 1.0 credits
    const pointYear = getPointYearFromSchedule('Yearly');
    return pointYear;
  }

  //For Grade Scale A-F
  const selectTwoSemester = (scheduleTranscript: any, grades: any, credits: any, schedule: any, finalGrade: any, finalCredit: any) => {
    if (scheduleTranscript === 'One grade per term' || scheduleTranscript === 'One grade per term and one final grade') {
      let point = 0;
      for (let i = 0; i < 2; i++) { //Dùng vòng lặp for cho ngắn gọn
        if (!checkEmptyValue(grades[i]) && !checkEmptyValue(credits[i])
          && !checkNoCreditAwarded(grades[i])) {
          point += getPointYearFromSchedule(schedule);
        }
      }
      return point;
    } else if (scheduleTranscript === 'One final Grade') {
      if (checkEmptyValue(finalGrade) || checkEmptyValue(finalCredit)
        || checkNoCreditAwarded(finalGrade)) {
        return 0;
      }
      return getPointYearFromSchedule(schedule);
    }
  }

  //For Grade Scale 1-100
  const selectTwoSemesterEligible = (scheduleTranscript: any, grades: any, credits: any, schedule: any, finalGrade: any, finalCredit: any) => {
    if (scheduleTranscript === 'One grade per term' || scheduleTranscript === 'One grade per term and one final grade') {
      let point = 0;
      for (let i = 0; i < 2; i++) { //Dùng vòng lặp for cho ngắn gọn
        if (!checkEmptyValue(grades[i]) && !checkEmptyValue(credits[i])
          && !checkNoCreditEligible(grades[i])) {
          point += getPointYearFromSchedule(schedule);
        }
      }
      return point;
    } else if (scheduleTranscript === 'One final Grade') {
      if (checkEmptyValue(finalGrade) || checkEmptyValue(finalCredit)
        || checkNoCreditEligible(finalGrade)) {
        return 0;
      }
      return getPointYearFromSchedule(schedule);
    }
  }

  const conditionGeneralInfo = (item: any) => {
    if (!item || checkEmptyValue(item?.type_of_class) || checkEmptyValue(item?.is_weighted_course)) {
      return 0;
    }

    const schedule = item.schedule; // Lưu giá trị schedule để dùng lại
    const finalGrade = item.final_grade;
    const finalCredit = item.final_credit;
    const gradeScale = item.grade_scale;

    if (schedule === 'Yearly' || schedule === 'Other') {
      if (gradeScale === 'A-F') {
        if (checkEmptyValue(finalGrade) || checkEmptyValue(finalCredit)
          || checkNoCreditAwarded(finalGrade)) {
          return 0;
        }
        return getPointYearFromSchedule(schedule);
      } else if (gradeScale === '1-100') {
        if (checkEmptyValue(finalGrade) || checkEmptyValue(finalCredit)
          || checkNoCreditEligible(finalGrade)) {
          return 0;
        }
        return getPointYearFromSchedule(schedule);
      }
    } else {
      //semester, quarter, trimester
      const scheduleTranscript = item.schedule_transcript;
      const grades = item.grades || []; //Gán mặc định là mảng rỗng để tránh lỗi nếu grades không tồn tại
      const credits = item.credits || [];

      if (gradeScale === 'A-F') {
        if (item.isFirstSemester === true && item.isSecondSemester === true) {
          return selectTwoSemester(scheduleTranscript, grades, credits, schedule, finalGrade, finalCredit);
        } else if (item.isFirstSemester === true && item.isSecondSemester === false) {
          return selectOneSemester(0, scheduleTranscript, grades, credits, schedule, finalGrade, finalCredit);
        } else if (item.isFirstSemester === false && item.isSecondSemester === true) {
          return selectOneSemester(1, scheduleTranscript, grades, credits, schedule, finalGrade, finalCredit);
        }
      } else if (gradeScale === '1-100') {
        if (item.isFirstSemester === true && item.isSecondSemester === true) {
          return selectTwoSemesterEligible(scheduleTranscript, grades, credits, schedule, finalGrade, finalCredit);
        } else if (item.isFirstSemester === true && item.isSecondSemester === false) {
          return selectOneSemesterEligible(0, scheduleTranscript, grades, credits, schedule, finalGrade, finalCredit);
        } else if (item.isFirstSemester === false && item.isSecondSemester === true) {
          return selectOneSemesterEligible(1, scheduleTranscript, grades, credits, schedule, finalGrade, finalCredit);
        }
      }
    }
    return 0;
  }

  React.useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const res: any = await caculatorSubjectYears(params.id);
      setMyAGSummary(res);
      setLoading(false);
    };
    const caculatorSubjectYears = async (studentId: any) => {
      let countKeys = {
        'WH': 0,
        'AP': 0,
        'IB': 0,
        'TC': 0,
      };
      const resGradeAndCourse: any = await courseServices.getGradeAndCourse(studentId);
      const resGeneral: any = await courseServices.getGeneral(studentId);

      const summary9th = checkEnableParseJSON(resGradeAndCourse?.grade_9_course_array, []);
      const summary10th = checkEnableParseJSON(resGradeAndCourse?.grade_10_course_array, []);
      const summary11th = checkEnableParseJSON(resGradeAndCourse?.grade_11_course_array, []);
      const summary12th = checkEnableParseJSON(resGradeAndCourse?.grade_12_course_array, []);

      const summaryTH = [...summary9th, ...summary10th, ...summary11th, ...summary12th];
      const summaryCollegeCourses = checkEnableParseJSON(resGradeAndCourse?.college_course_array, []);
      const additional_info = checkEnableParseJSON(resGeneral?.additional_info, {});

      summaryTH.map((item: any) => {
        item?.subjectDetails?.map((subject: any) => {
          const pointYear = conditionGradeTH(subject, item?.schedule, item?.CMGradingScale);
          if (!checkEmptyValue(pointYear)) {
            const typeOfClass = subject?.typeOfClass;
            //Type of Class
            const firstLetter = getKeyTypeOfCourse(typeOfClass, wCourseSummary);
            if (firstLetter != null) {
              let currentValue = (countKeys as any)[firstLetter];
              (countKeys as any)[firstLetter] = currentValue + pointYear;
            }

            //Weighted Course
            const isWeightedCourse = subject?.isWeightedCourse;
            if (checkWeightedCourse(isWeightedCourse)) {
              const keyWeighted = getKeyTypeOfCourse('Weighted Honors Courses', wCourseSummary);
              if (keyWeighted != null) {
                const keyWeightedValue = (countKeys as any)[keyWeighted];
                (countKeys as any)[keyWeighted] = keyWeightedValue + pointYear;
              }
            }
          }
        })
      })

      summaryCollegeCourses.map((item: any) => {
        item?.collegeCourseArray?.map((subject: any) => {
          const scheduleSystem = item?.scheduleSystem;
          const pointYear = conditionCollegeCourses(subject, scheduleSystem);
          if (!checkEmptyValue(pointYear)) {
            const transferable = subject?.transferable;
            if (transferable === true) {
              const keyWeighted = getKeyTypeOfCourse('Transferable College Courses', wCourseSummary);
              if (keyWeighted != null) {
                const keyWeightedValue = (countKeys as any)[keyWeighted];
                (countKeys as any)[keyWeighted] = keyWeightedValue + pointYear;
              }
            }
          }
        })
      })

      additional_info?.course_work.map((item: any) => {
        const pointYear = conditionGeneralInfo(item);
        if (!checkEmptyValue(pointYear)) {
          const typeOfClass = item.type_of_class;
          //Type of Class
          const firstLetter = getKeyTypeOfCourse(typeOfClass, wCourseSummary);
          if (firstLetter != null) {
            let currentValue = (countKeys as any)[firstLetter];
            (countKeys as any)[firstLetter] = currentValue + pointYear;
          }
          //Weighted Course
          const isWeightedCourse = item.is_weighted_course;
          if (checkWeightedCourse(isWeightedCourse)) {
            const keyWeighted = getKeyTypeOfCourse('Weighted Honors Courses', wCourseSummary);
            if (keyWeighted != null) {
              const keyWeightedValue = (countKeys as any)[keyWeighted];
              (countKeys as any)[keyWeighted] = keyWeightedValue + pointYear;
            }
          }
        }
      })

      //Total Points
      const total = Object.values(countKeys).reduce((a, b) => a + b, 0);
      setTotalCredits(total);
      return countKeys;
    }
    params.id !== ':id' && getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);
  return loading ? (
    <LoadingContent />
  ) : (
    <Row className="w-100">
      <table className='table-subject-totals'>
        <thead>
          <tr>
            <th colSpan={4}>Subject</th>
            <th>Number of Courses</th>
          </tr>
        </thead>
        <tbody>
          {
            Object.entries(wCourseSummary).map(([key, value]) => {
              return (
                <tr key={key}>
                  <td colSpan={4}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span className='subject-text'> {value}</span>
                  </td>
                  <td>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {myAGSummary?.[key]}
                  </td>
                </tr>
              );
            })
          }
          <tr key='4'>
            <td colSpan={4} className="total-credits bold-font-td">
              <span className='subject-text'> Total: </span>
            </td>
            <td className="bold-font-td total-inline-value">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {totalCredits}
            </td>
          </tr>
        </tbody>
      </table>
    </Row>
  );
};

const buildSemesterData = (item: any) => {
  let options: any[] = [];
  switch (item.schedule) {
    case 'Quarters':
      options = [
        { value: 'isFirstSemester', label: 'First Quarter', fieldIndex: 0 },
        { value: 'isSecondSemester', label: 'Second Quarter', fieldIndex: 1 },
        { value: 'isThirdSemester', label: 'Third Quarter', fieldIndex: 2 },
        { value: 'isFourthSemester', label: 'Fourth Quarter', fieldIndex: 3 },
      ];
      break;
    case 'Trimester':
      options = [
        { value: 'isFirstSemester', label: 'First Trimester', fieldIndex: 0 },
        { value: 'isSecondSemester', label: 'Second Trimester', fieldIndex: 1 },
        { value: 'isThirdSemester', label: 'Third Trimester', fieldIndex: 2 },
      ];
      break;
    case 'Semester':
      options = [
        { value: 'isFirstSemester', label: 'First Semester', fieldIndex: 0 },
        { value: 'isSecondSemester', label: 'Second Semester', fieldIndex: 1 },
      ];
      break;
    default:
      break;
  }
  let str = options
    .map((opt) => (item[opt.value] ? opt.label : ''))
    .filter((i) => !!i)
    .join(', ');
  return str;
};
