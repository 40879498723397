/* eslint-disable react-hooks/exhaustive-deps */
import LoadingContent from 'components/LoadingContent';
import { useFormik } from 'formik';
import React from 'react';
import TopProcessBar from 'screen/selectCollege/TopProcessBar';
import { Container, Form, Row, Spinner, Button } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { routers } from 'router/router';
import { logout } from 'app/basicInformationSlice/basicInformationSlice';
import { authenticateSettings } from 'components/helper/appConfig';
import YesNoSwitch from 'components/input/YesNoSwitch';
import GroupCheckbox from 'components/input/GroupCheckbox';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import { BoolToString, formatBoolean } from 'components/helper/helper';
import { getGeneral, updateGeneral } from 'app/testingSlice/testingSlice';
import { debounce } from 'lodash';
import Dialog from '@mui/material/Dialog';
import { DialogActions, DialogContent, DialogContentText } from '@mui/material';

interface Props {}

const GeneralInfoTest = (props: Props) => {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const [loading, setLoading] = React.useState(false);
  const test = useAppSelector((store) => store.testing);
  const dispatch = useAppDispatch();
  const history = useHistory();
  let studentId = Cookies.get('userId');
  let token = Cookies.get(authenticateSettings.tokenName);

  const formik = useFormik({
    initialValues: {
      is_wish_self_report_scores: undefined,
      all_tests_wish_report_array: '',
      is_promotion_within_educational_system: undefined,
    },
    onSubmit: async (values) => {
      let frmObj:any = {};
      frmObj.is_SAT = formAllTest.values.is_SAT;
      frmObj.is_ACT = formAllTest.values.is_ACT;
      frmObj.is_AP_Subject = formAllTest.values.is_AP_Subject;
      frmObj.is_IB_Subject = formAllTest.values.is_IB_Subject;
      frmObj.is_CLEP = formAllTest.values.is_CLEP;
      frmObj.is_TOEFL = formAllTest.values.is_TOEFL;
      frmObj.is_PTE = formAllTest.values.is_PTE;
      frmObj.is_IELTS = formAllTest.values.is_IELTS;
      frmObj.is_DuoLingo = formAllTest.values.is_DuoLingo;

      const newValues = {
        student_id: studentId,
        is_wish_self_report_scores: formatBoolean(values.is_wish_self_report_scores),
        all_tests_wish_report_array: JSON.stringify(frmObj),
        is_promotion_within_educational_system: formatBoolean(
          values.is_promotion_within_educational_system
        ),
      };

      await dispatch(updateGeneral(newValues));
    },
  });

  const handleLogout = () => {
    dispatch(logout());
    Cookies.remove(authenticateSettings.tokenName);
    Cookies.remove('userId');
    history.push(routers.login.path);
  };

  const handleContinue = () => {
    formik.handleSubmit();
    const nextRoute = Object.keys(test.roleTest).find((key) => test.roleTest[key] === true);
    history.push(nextRoute ? routers?.[nextRoute]?.path : routers.otherTest.path);
  };

  const formAllTest = useFormik({
    initialValues: {
      is_SAT: false,
      is_ACT: false,
      is_AP_Subject: false,
      is_IB_Subject: false,
      is_CLEP: false,      
      is_TOEFL: false,
      is_PTE: false,
      is_IELTS: false,
      is_DuoLingo: false,

      //is_other: false,
      //other_values: '',
      //is_SAT_before_2016: false,
      //is_SAT_Subject: false,
      //is_TOEFL_Paper: false,
    },
    onSubmit: () => {},
  });

  const getDetail = async () => {
    studentId = Cookies.get('userId');
    token = Cookies.get(authenticateSettings.tokenName);
    if (token && studentId) {
      setLoading(true);
      let res = await dispatch(getGeneral(studentId));
      const dataLoaded: any = res.payload;
      if (dataLoaded) {
        const values: any = {
          ...dataLoaded,
          is_wish_self_report_scores: BoolToString(dataLoaded.is_wish_self_report_scores),
          is_promotion_within_educational_system: BoolToString(
            dataLoaded.is_promotion_within_educational_system
          ),
        };
        formik.setValues(values);
        formAllTest.setValues(JSON.parse(dataLoaded.all_tests_wish_report_array));
      }
      setLoading(false);
      formik.setStatus('idle');
    } else {
      handleLogout();
    }
  };

  React.useEffect(() => {
    getDetail();
  }, []);

  // Auto save function ==========
  React.useEffect(() => {
    if (formik.dirty) debounceSaving();
  }, [formik.values, formAllTest.values]);
  const debounceSaving = React.useCallback(
    debounce(() => {
        if (!loading) formik.handleSubmit();
    },  800),
    []
  );
  // Auto save function ==========

  const testTakenOptionLocal = [
    { value: 'is_SAT', label: 'SAT Tests' },
    { value: 'is_ACT', label: 'ACT Tests' },
    { value: 'is_AP_Subject', label: 'AP Subject Tests' },
    { value: 'is_IB_Subject', label: 'IB Subject Tests' },
    { value: 'is_CLEP', label: 'CLEP Tests', mdCol: 12 },
    {
      value: 'is_TOEFL',
      label: 'TOEFL iBT',
      activeContent: 'English Language Tests',
      onChecked: (checked: boolean) => checked && setOpen(true),
    },
    {
      value: 'is_PTE',
      label: 'PTE Academic',
      activeContent: 'English Language Tests',
      onChecked: (checked: boolean) => checked && setOpen(true),
    },
    {
      value: 'is_IELTS',
      label: 'IELTS',
      activeContent: 'English Language Tests',
      onChecked: (checked: boolean) => checked && setOpen(true),
    },
    {
      value: 'is_DuoLingo',
      label: 'DuoLingo',
      activeContent: 'English Language Tests',
      onChecked: (checked: boolean) => checked && setOpen(true),
    },
  ];
  return loading ? (
    <LoadingContent />
  ) : (
    <>
      <TopProcessBar
        stepProcess={[]}
        title="Standardized Testing"
        process={1}
        icon={<CalculateOutlinedIcon />}
      />

      <Container className="main-container">
        <div className="px-2">
          <Form style={{ maxWidth: '900px' }} onSubmit={formik.handleSubmit}>
            <Row className="m-0">
              <div className="text-field-mid fs-3 mb-4">General Info</div>
              <div className="my-2">Let's put all your standardized testing in one place!</div>
              <YesNoSwitch
                className="mb-3"
                logo="span-cm-logo"
                label="In addition to sending official score reports as required by colleges, do you wish to self-report scores or future test dates for any of the following standardized tests: ACT, SAT/SAT Subject, AP, IB, CLEP, TOEFL, PTE Academic, and IELTS?*"
                name="is_wish_self_report_scores"
                onChange={formik.handleChange}
                values={formik.values.is_wish_self_report_scores}
              />
              {formik.values.is_wish_self_report_scores === 'true' && (
                <>
                  <GroupCheckbox
                    label="Indicate all tests you wish to report. Be sure to include tests you expect to take in addition to tests you have already taken.*"
                    className="common-flag"
                    options={testTakenOptionLocal}
                    form={formAllTest}
                    mdCol={6}
                  />
                  {/* {formAllTest.values.is_other && (
                      <TextInput
                        label="Other test"
                        values={formAllTest.values.other_values}
                        name="other_values"
                        onChange={formAllTest.handleChange}
                      />
                    )}
                    <GroupCheckbox
                      className="common-flag"
                      options={testTakenOption2}
                      form={formAllTest}
                      mdCol={3}
                    /> */}

                  <div className="text-field-light my-3"></div>
                  <YesNoSwitch
                    className="mb-3"
                    label="International applicants: Is promotion within your educational system based upon standard leaving examinations given at the end of lower and/or senior secondary school by a state or national leaving examinations board? (Students studying in the US typically answer no to this question.)"
                    logo="span-cm-logo"
                    name="is_promotion_within_educational_system"
                    onChange={formik.handleChange}
                    values={formik.values.is_promotion_within_educational_system}
                  />
                </>
              )}
            </Row>
            <div className="my-3 d-flex justify-content-end border-top pt-3">
              <div className="col col-md-6 text-center text-md-end">
                <Button variant="primary" type="submit" className="p-2 px-5 mx-2">
                  {test.status === 'loading' ? (
                    <Spinner size="sm" animation="border" variant="light" />
                  ) : (
                    'Save'
                  )}
                </Button>
                <Button variant="primary" className="p-2 px-5" onClick={() => handleContinue()}>
                  Continue
                </Button>
              </div>
            </div>
          </Form>
        </div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You will need to enter these test results manually into the applications.
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
};

export const testTakenOption = [
  { value: 'is_SAT', label: 'SAT Tests' },
  { value: 'is_ACT', label: 'ACT Tests' },
  { value: 'is_AP_Subject', label: 'AP Subject Tests' },
  { value: 'is_IB_Subject', label: 'IB Subject Tests' },
  { value: 'is_CLEP', label: 'CLEP Tests' },
  {
    value: 'is_TOEFL',
    label: 'TOEFL iBT',
    activeContent: 'English Language Tests',
  },
  {
    value: 'is_PTE',
    label: 'PTE Academic',
    activeContent: 'English Language Tests',
  },
  {
    value: 'is_IELTS',
    label: 'IELTS',
    activeContent: 'English Language Tests',
  },
  {
    value: 'is_DuoLingo',
    label: 'DuoLingo',
    activeContent: 'English Language Tests',
  },
];
export default GeneralInfoTest;
