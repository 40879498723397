/* eslint-disable react-hooks/exhaustive-deps */
import { csuCountry, jobCategory, occupationOptions, optionValues } from 'components/helper/helper';
import { canadaProvince, usaState } from 'components/helper/stateAndCounty';
import AutocompleteInput from 'components/input/AutoCompleteInput';
import GroupRadioButton from 'components/input/GroupRadioButton';
import SelectInput from 'components/input/SelectInput';
import TextInput from 'components/input/textInput';
import React from 'react';
import { Row } from 'react-bootstrap';
import collegeServices from 'services/collegeServices';
import TextIconInput from 'components/input/TextIconInput';
import SearchIcon from '@mui/icons-material/Search';
import LoadingContent from 'components/LoadingContent';
import { debounce, defer } from 'lodash';
import Card from '@mui/material/Card';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

interface Props {
  formik: any;
  parentNumber?: number;
}

const Employment = (props: Props) => {
  const [searchInput, setSearchInput] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [openMenu, setOpenMenu] = React.useState(false);
  const [selectedEducation, setSelectedEducation] = React.useState<any>();

  const { formik } = props;
  const [collegeData, setCollegeData] = React.useState<any>();

  const checkCountry = () =>{
    if (formik.values.college_or_university.country !== 'Canada' && formik.values.college_or_university.country !== 'United States of America') {      
      formik.setFieldValue('college_or_university.state', null); 
      formik.setFieldValue('college_or_university.province', null);
      if (!formik.values.college_or_university.state_or_province){
        formik.setFieldValue('college_or_university.state_or_province', '');
      }
    }
  }
  
  const handleGetCollege = async (keyword: string) => {
    setIsLoading(true);
    const res: any = await collegeServices.getCollege({ keyword: keyword.trim(), limit_item: 20 });
    const data = res.map((item: any) => ({
      ...item,
      id: item.id,
      label: item.name,
      value: item.name,
    }));
    setCollegeData(data);
    setIsLoading(false);
    setOpenMenu(true);
  };

  const handleSearchCollege = debounce((keyword: string) => {
    defer(() => {
      handleGetCollege(keyword);
    });
  }, 300);

  React.useEffect(() => {
    const college = props.formik.values.college_or_university;
    if (college?.name ==="null") {
      college.name = '';
    }
    if (college?.name) {
      setSelectedEducation(college);
    }
  }, []);


  const handleFormChange = (e:any) =>{
    checkCountry();
    formik.handleChange(e);
  }

  return (
    <>
      <div className="my-2 fs-5 p-1">Employment</div>
      <GroupRadioButton
        label="What is this parent's current job status?"
        logo="span-uc-logo"
        mdCol={6}
        options={[
          { value: 'Employed' },
          { value: 'Stay-at-home parent/homemaker' },
          { value: 'Not-employed' },
          { value: 'Retired' },
        ]}
        name="parent_current_job_status"
        values={props.formik.values.parent_current_job_status}
        onChange={props.formik.handleChange}
      />
      <SelectInput
        logo="span-cm-logo"
        form={props.formik}
        name="employment_status"
        values={props.formik.values.employment_status}
        option={[
          { value: 'Employed' },
          { value: 'Unemployed' },
          { value: 'Retired' },
          { value: 'Self-Employed' },
        ]}
        label="Employment status*"
        onChange={props.formik.handleChange}
      />
      <TextInput
        hidden={props.formik.values.parent_current_job_status !== 'Employed'}
        label="Current Employer"
        name="current_employer"
        countText={40}
        values={props.formik.values.current_employer}
        onChange={props.formik.handleChange}
      />

      {props.formik.values.parent_current_job_status === 'Stay-at-home parent/homemaker' && (
        <SelectInput
          option={optionValues(Array.from({ length: 70 }, (_, i) => i + 1))}
          label="How many years has this parent been a stay-at-home parent?"
          logo="span-uc-logo"
          name="number_of_year_at_home"
          values={props.formik.values.number_of_year_at_home}
          onChange={props.formik.handleChange}
        />
      )}
      {props.formik.values.parent_current_job_status === 'Not-employed' && (
        <SelectInput
          option={optionValues(Array.from({ length: 70 }, (_, i) => i + 1))}
          label="How many years has this parent not been employed?"
          logo="span-uc-logo"
          name="number_of_year_not_employed"
          values={props.formik.values.number_of_year_not_employed}
          onChange={props.formik.handleChange}
        />
      )}
      {props.formik.values.parent_current_job_status === 'Retired' && (
        <SelectInput
          option={optionValues(Array.from({ length: 70 }, (_, i) => i + 1))}
          label="How many years has this parent been retired?"
          logo="span-uc-logo"
          name="number_of_year_retired"
          values={props.formik.values.number_of_year_retired}
          onChange={props.formik.handleChange}
        />
      )}

      <div className="p-1">Most recent/Former job held by this parent info:</div>
      <SelectInput
        className={`common-flag ${
          props.formik.values.parent_current_job_status === 'Employed' && 'end-flag'
        }`}
        form={props.formik}
        option={occupationOptions}
        label="Occupation (former occupation, if retired or deceased)"
        name="occupation"
        values={props.formik.values.occupation}
        onChange={props.formik.handleChange}
      />
      <SelectInput
        logo="span-uc-logo"
        label="Job Category"
        name="job_category"
        values={props.formik.values.job_category}
        onChange={props.formik.handleChange}
        option={jobCategory}
      />
      <TextInput
        label="Job title"
        countText={20}
        name="job_title"
        values={props.formik.values.job_title}
        onChange={props.formik.handleChange}
      />
      <SelectInput
        option={optionValues(Array.from({ length: 70 }, (_, i) => i + 1))}
        logo="span-uc-logo"
        label="Number of years at job"
        name="number_of_year_at_job"
        values={props.formik.values.number_of_year_at_job}
        onChange={props.formik.handleChange}
      />

      {props.formik.values.occupation === 'Other' && (
        <TextInput
          mdCol="12"
          label="Other Occupation Details"
          name="other_occupation_detail"
          values={props.formik.values.other_occupation_detail}
          onChange={props.formik.handleChange}
          countText={50}
        />
      )}

      {['Employed', 'Retired'].includes(props.formik.values.employment_status) && (
        <>
          <GroupRadioButton
            className="py-4"
            label="Is parent currently employed by or retired from a college or university?"
            logo="span-cm-logo"
            name="parent_currently_employed_or_retire_from_college"
            values={props.formik.values.parent_currently_employed_or_retire_from_college}
            onChange={props.formik.handleChange}
            mdCol={12}
            options={[
              { value: 'Never employed at a college/university' },
              { value: 'Currently employed at a college/university' },
              { value: 'Retired from a college/university' },
            ]}
          />
          {[
            'Currently employed at a college/university',
            'Retired from a college/university',
          ].includes(props.formik.values.parent_currently_employed_or_retire_from_college) && (
            <>
              <TextIconInput
                label="College/University employer lookup"
                logo="span-cm-logo"
                icon={<SearchIcon />}
                mdCol="6"
                onChange={(e: any) => {
                  setSearchInput(e.target.value);
                  handleSearchCollege(e.target.value);
                  formik.setFieldValue('college_or_university.name', e.target.value);
                }}
                values={searchInput}
              />

              {isLoading && <LoadingContent />}
              {collegeData !== undefined &&
                !collegeData?.length &&
                !isLoading &&
                !formik.values.college_or_university?.name && (
                  <div className="text-center">Not found college</div>
                )}

              {openMenu && (collegeData || []).map(
                  (
                    college: {
                      label:
                        | boolean
                        | React.ReactChild
                        | React.ReactFragment
                        | React.ReactPortal
                        | null
                        | undefined;
                      address_line_1:
                        | boolean
                        | React.ReactChild
                        | React.ReactFragment
                        | React.ReactPortal
                        | null
                        | undefined;
                    },
                    index: React.Key | null | undefined
                  ) =>
                    college?.label ? (
                      <Card className="border rounded my-1 pt-2 pb-1" key={index}>
                        <div className="d-flex align-items-end position-relative">
                          <div className="mx-3 pb-2 ">{college.label}</div>
                          <div className="text-field-light sized70 pb-2">{college.address_line_1}</div>
                          <span className="position-absolute h-100 d-flex align-items-center end-0">
                            <AddIcon
                              fontSize="small"
                              className="bg-app text-primary rounded-circle mx-3 pointer"
                              onClick={() => {
                                setSelectedEducation(college);
                                formik.setFieldValue('college_or_university', college);
                                setOpenMenu(false);
                                setSearchInput('');
                              }}
                            />
                          </span>
                        </div>
                      </Card>
                    ) : null
              )}
            
            {selectedEducation?.id ? (
              <>
                  <div className="my-2 text-field-light">Employer Graduation College</div>
                  <div className="d-flex align-items-end position-relative">
                    <div className="mx-3 pb-2 fw-bold"
                    style={{
                      color: '#4d88f0'
                    }}
                    >{selectedEducation?.name}</div>
                    <div className="text-field-light sized70  pb-2">
                      {selectedEducation?.address_line_1}
                    </div>
                    <span className="position-absolute h-100 d-flex align-items-center end-0">
                      <RemoveIcon
                        fontSize="small"
                        className="bg-light text-dart rounded-circle mx-4 pointer"
                        onClick={() => {
                          setSelectedEducation(undefined);
                          formik.setFieldValue('college_or_university', null);
                        }}
                      />
                    </span>
                  </div>
              </>
            ) : (collegeData !== undefined && !collegeData?.length) || selectedEducation?.name ? (
              <>
                  <div className="my-2 text-field-light">Employer Graduation College</div>
                  <Row className="bg-light rounded p-3">
                    <TextInput
                      name={'college_or_university.name'}
                      values={formik.values?.college_or_university?.name}
                      onChange={handleFormChange}
                      label="Name of college/university"
                      countText={60}
                    />
                    <AutocompleteInput
                      name={'college_or_university.country'}
                      form={formik}
                      values={formik.values?.college_or_university?.country}
                      label="Country/Region/Territory*"
                      option={csuCountry}
                      onChange={(e:any)=>{
                        if (formik.values?.college_or_university?.country !== 'Canada' && formik.values?.college_or_university?.country !== 'United States of America') {      
                          formik.setFieldValue('college_or_university.state', null); 
                          formik.setFieldValue('college_or_university.province', null);
                          if (!formik.values?.college_or_university?.state_or_province){
                            formik.setFieldValue('college_or_university.state_or_province', '');
                          }
                        }
                      }}
                    />
                    <TextInput
                      mdCol={12}
                      name={'college_or_university.address_line_1'}
                      values={formik.values?.college_or_university?.address_line_1}
                      onChange={handleFormChange}
                      label="Address Line 1"
                      countText={60}
                    />
                    <TextInput
                      mdCol={12}
                      name={'college_or_university.address_line_2'}
                      values={formik.values?.college_or_university?.address_line_2}
                      onChange={handleFormChange}
                      label="Address Line 2"
                      countText={60}
                    />
                    <TextInput
                      mdCol={12}
                      name={'college_or_university.address_line_3'}
                      values={formik.values?.college_or_university?.address_line_3}
                      onChange={handleFormChange}
                      label="Address Line 3"
                      countText={60}
                    />
                    <TextInput
                      name={'college_or_university.city'}
                      values={formik.values?.college_or_university?.city}
                      onChange={handleFormChange}
                      label="City"
                      countText={30}
                    />
                    <AutocompleteInput
                      option={usaState}
                      form={formik}
                      hidden={formik.values?.college_or_university?.country !== 'United States of America'}
                      label="State"
                      name={'college_or_university.state'}
                      values={formik.values?.college_or_university?.state}
                    />
                    <AutocompleteInput
                      form={formik}
                      option={canadaProvince}
                      hidden={formik.values?.college_or_university?.country !== 'Canada'}
                      label="Province"
                      name={'college_or_university.province'}
                      values={formik.values?.college_or_university?.province}
                    />
                    <TextInput
                      hidden={
                        formik.values?.college_or_university?.country === 'Canada' ||
                        formik.values?.college_or_university?.country === 'United States of America'
                      }
                      name={'college_or_university.state_or_province'}
                      values={formik.values?.college_or_university?.state_or_province}
                      onChange={(e:any)=>{
                        formik.setFieldValue('college_or_university.state', null); 
                        formik.setFieldValue('college_or_university.province', null); 
                        formik.setFieldValue('college_or_university.state_or_province', e.target.value);
                        handleFormChange(e);
                      }}
                      label="State or Province"
                      countText={30}
                    />
                    <TextInput
                      name={'college_or_university.zip_code'}
                      values={formik.values?.college_or_university?.zip_code}
                      onChange={handleFormChange}
                      label="Zip Code"
                      countText={10}
                    />
                  </Row>
              </>
            ) : null}

          </>
          )}
        </>
      )}
    </>
  );
};

export default Employment;
