/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Col } from 'react-bootstrap';
import { capitalizedString } from '../PersonalSum';
import CheckboxInput from 'components/input/CheckboxInput';
import { v4 as uuidv4 } from 'uuid';

interface Props {
  mdCol?: number;
  label: string;
  values: any;
  secondValues?: string;
  hidden?: boolean;
  options?: any[];
  usePreTag?:boolean;
  textColor?: string;
  isOverCount?: boolean;
  countText?: number;
  countWord?: number;
}


const ItemContent = (props: Props) => {
  const [counter, setCounter] = React.useState(0);
  const [counterWord, setCounterWord] = React.useState(0); 
  const [classOverText, setClassOverText] = React.useState('summary');

  const changeColorOverCount = () => {
    setClassOverText('summary');
    if (props.isOverCount && props.countText && (props.values.length ?? 0) > props.countText) {
      setClassOverText('text-danger');
    }
    if (props.isOverCount && props.countWord && wordCount() > props.countWord) {
      setClassOverText('text-danger');
    }
  }
  
  const wordCount = () => {
    if (typeof props.values === 'string' || props.values instanceof String) {
      return (props.values || "").split(/\s+/).filter(Boolean).length;
    }
    else {
      let values = `${props.values}`; // force convert to string
      return (values || "").split(/\s+/).filter(Boolean).length;
    }
  }

  const getInfo = () =>{
      if (props.values?.length && props.countText) {
        setCounter(props.values.length ?? 0);
      } else if (props.countWord) {
        setCounterWord(wordCount ?? 0);
      }
      changeColorOverCount();
  }
  React.useEffect(() => {
    getInfo();
  }, [props.values]);

  React.useEffect(() => {
    changeColorOverCount();
  }, []);

  const renderText = (props.options ?? []).find((i) => i.value === props.values)?.label;
  return props.hidden ? null : (
    <Col xs={12} md={props.mdCol || 3} className="p-2 d-flex flex-column justify-content-between">
      <div className="text-field-light">{props.label}</div>
      {props.usePreTag ? (
        <>
          {props.options?.length ? (
            renderText
          ) : Array.isArray(props.values) ? (
            props.values.map((e, index) => <pre className="summary"  key={index}>{e}</pre>)
          ) : (
            <pre className={`${classOverText}`} style={{ wordWrap: 'break-word', color: props.textColor, marginBottom: 0}}>
              {typeof props.values === 'string' ? capitalizedString(props.values) : props.values ?? '-'}
              {props.values === true && 'Yes'}
              {props.values === false && 'No'}
              {props.secondValues !== undefined && (
                <div style={{ minHeight: '2rem' }}>{props.secondValues}</div>
              )}
            </pre>
          )}
        </>
      ):
      (
        <>
        {props.options?.length ? (
          renderText
        ) : Array.isArray(props.values) ? (
          props.values.map((e, index) => <div key={index}>{e}</div>)
        ) : (
          <div className={`${classOverText}`} style={{ wordWrap: 'break-word', color: props.textColor}}>
            {typeof props.values === 'string' ? capitalizedString(props.values) : props.values ?? '-'}
            {props.values === true && 'Yes'}
            {props.values === false && 'No'}
            {props.secondValues !== undefined && (
              <div style={{ minHeight: '2rem' }}>{props.secondValues}</div>
            )}
          </div>
        )}
        </>
      )}
      <div className="sized70 text-field-light w-100 d-flex justify-content-between mh-1rem">
        <span></span>
        {props.countText && (
          <span title={`${counter}/${props.countText} characters`}>
            {counter}/{props.countText} characters
          </span>
        )}
        {props.countWord && (
          <span title={`${counterWord}/${props.countWord} words`}>
            {counterWord}/{props.countWord} words 
          </span>
        )}
      </div>
    </Col>
  );
};

export const ItemParagraph = (props: Props) => {
  return typeof props.values !== 'string' ? (<></>) : (
    <Col xs={12} md={props.mdCol || 3} className="p-2">
      <div className="text-field-light">{props.label}</div>
      <div>
        {props.values.split('\n').map((e: any, index: any) => (
          <div key={index}>{e}</div>
        ))}
      </div>
    </Col>
  );
};

export const CheckboxContent = ({
  col,
  label,
  values,
}: {
  col?: number;
  label: string;
  values: boolean;
}) => {
  return (
    <Col xs={12} md={col || 12} className="px-1">
      <CheckboxInput
        name={label + uuidv4()}
        readOnly
        label={<span className="mt-1 text-dark">{label}</span>}
        values={values}
      />
    </Col>
  );
};
export const CheckboxContentGroup = ({
  col,
  label,
  options,
  form,
}: {
  col?: number;
  label: string;
  options: any[];
  form: any;
}) => {
  return (
    <>
      <div>{label}</div>
      {(options ?? []).map((option, index) => (
        <Col xs={12} md={col || 12} className="px-1" key={index}>
          <CheckboxInput
            name={option.label + uuidv4()}
            readOnly
            label={<span className="mt-1 text-dark">{option.label}</span>}
            values={form[option.value]}
          />
        </Col>
      ))}
    </>
  );
};

export default ItemContent;
