import React from 'react';
import { Row, Col } from 'react-bootstrap';
import ItemContent, { CheckboxContent } from './ItemContent';
import CardConfig from 'components/CardConfig';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import { ExpandMore } from 'screen/activitiesAndHonors/Activities';

interface Props {
  data: any;
}

const HonorSum = ({ data }: Props) => {
  const [expandAll, setExpandAll] = React.useState(false);
  const [expandActive, setExpandActive] = React.useState<number>(0);

  const handleExpandAll = () => {
    setExpandAll(!expandAll);
    if (!expandAll) {
      setExpandActive(data.length);
    } else {
      setExpandActive(0);
    }
  };

  const handleExpandActive = (value: any) => {
    value
      ? setExpandActive(Math.abs(expandActive - 1))
      : setExpandActive(Math.abs(expandActive + 1));
  };

  React.useEffect(() => {
    if (expandActive === data.length) {
      setExpandAll(true);
    }

    if (expandActive === 0) {
      setExpandAll(false);
    }
  }, [expandActive, data.length, expandAll]);

  return (
    <>
      <div className="p-3">
        If you have received any honors or awards related beginning with the ninth grade or
        international equivalent, please indicate them here.
      </div>
      <div className="bg-light p-3 rounded">
        <div>App Notes:</div>
        <div>
          Common App - Allows you to report any honors related to your academic achievements
          beginning with the ninth grade or international equivalent. If you received more than
          five, please add the five most important to you.*
        </div>
        <div>UC App - Allow you to report up to 20 activities and honors combined.</div>
      </div>

      {data?.length > 0 && (
        <div className="d-flex justify-content-end my-2">
          <ExpandMore aria-expanded={expandAll} aria-label="show all" onClick={handleExpandAll}>
            {!expandAll ? <KeyboardDoubleArrowDownIcon /> : <KeyboardDoubleArrowUpIcon />}
          </ExpandMore>
        </div>
      )}

      {(data || []).map((item: any, index: number) => (
        <HonorItem
          honor={item}
          key={index}
          expandAll={expandAll}
          handleExpandActive={handleExpandActive}
        />
      ))}
    </>
  );
};

const renderFillAppStatus = (item: any) => {
  return (
    <span>
      {item.isIncludeIntoCommonApp && (
        <img
          alt="cm"
          src="/assets/icons/icon-cm-5.svg"
          style={{ marginTop: '-8px', marginLeft: '4px' }}
        />
      )}
      {item.isIncludeIntoUCApp && (
        <img
          alt="uc"
          src="/assets/icons/icon-uc-5.svg"
          style={{ marginTop: '-8px', marginLeft: '4px' }}
        />
      )}
      {item.isIncludeIntoCSUApp && (
        <img
          alt="csu"
          src="./assets/icons/icon-csu-5.svg"
          style={{ marginTop: '-8px', marginLeft: '4px' }}
        />
      )}
    </span>
  );
};

const HonorItem = ({
  honor,
  key,
  expandAll,
  handleExpandActive,
}: {
  honor: any;
  key: number;
  expandAll?: boolean;
  handleExpandActive?: (value: any) => void;
}) => {
  return (
    <CardConfig
      expandAll={expandAll}
      handleExpandActive={handleExpandActive}
      headerElement={
        <div>
          {honor?.honorTitle} {renderFillAppStatus(honor)}
        </div>
      }
    >
      <Row className="p-3">
        <ItemContent
          mdCol={6}
          label="What's the name of the award or honor? *"
          values={honor?.honorTitle}
        />
        <Col md={6}>
          <div className="text-dark px-2">Grade Level</div>
          <Row>
            <CheckboxContent col={3} label="9" values={honor?.isGradeNinthLevel} />
            <CheckboxContent col={3} label="10" values={honor?.isGradeTenthLevel} />
            <CheckboxContent col={3} label="11" values={honor?.isGradeEleventhLevel} />
            <CheckboxContent col={3} label="12" values={honor?.isGradeTwelvethLevel} />
          </Row>
        </Col>
        <Col md={6} className="my-3">
          <div className="text-dark px-2">Level(s) of recognition</div>
          <CheckboxContent col={6} label="School" values={honor?.isSchoolLevelRecognition} />
          <CheckboxContent col={6} label="State/Regional" values={honor?.isStateLevelRecognition} />
          <CheckboxContent col={6} label="National" values={honor?.isNational} />
          <CheckboxContent
            col={6}
            label="International"
            values={honor?.isInternationalLevelRecognition}
          />
        </Col>

        {/* <CheckboxContent
          col={6}
          label="Post-graduate (can select mulitple)"
          values={honor?.isPostGraduateLevel}
        /> */}

        <ItemContent mdCol={12} label="Type of award or honor *" values={honor?.typeOfHonor} />

        <ItemContent
          mdCol={12}
          label="What are the eligibility requirements for this award or honor? *"
          values={honor?.eligibilityRequirementsHonor}
          countText={250}
          isOverCount={true}
        />
        <ItemContent
          mdCol={12}
          label="What did you do to achieve this award or honor? *"
          values={honor?.actionToAchieveHonor}
          isOverCount={true}
          countText={350}
        />

        <div className="text-dark">Include honor or award into these applications</div>
        <CheckboxContent col={4} label="UC App" values={honor?.isIncludeIntoUCApp} />
        <CheckboxContent col={4} label="Common App" values={honor?.isIncludeIntoCommonApp} />
      </Row>
    </CardConfig>
  );
};

export default HonorSum;
