/* eslint-disable react-hooks/exhaustive-deps */
import 'antd/dist/antd.css';
import 'antd.css';
import { Card, Stack, Tab, Tabs, Typography } from '@mui/material';
import React from 'react';
import SimpleTable from 'components/table/SimpleTable';
import SumarryTable from 'components/table/SumarryTable';
import CustomTabPanel from './TabPanel';
import LoadingContent from 'components/LoadingContent';
import { useHistory, useParams } from 'react-router-dom';
import gpaForecastServices from 'services/gpaforecast.services';
import gpaForecastDetailServices from 'services/gpaforecastDetail.services';
import { logout } from 'app/basicInformationSlice/basicInformationSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { buildClassName } from './RPersonalInformation';
import { authenticateSettings } from 'components/helper/appConfig';
import Cookies from 'js-cookie';
import { routers } from 'router/router';

const RGPAForecaster = ({ setCurrentModuleId }: { setCurrentModuleId: (id: string) => void }) => {
  const [gpaForecast9Data, setGpaForecast9Data] = React.useState<any[]>([]);
  const [gpaForecast9Custom, setGpaForecast9Custom] = React.useState<any[]>([]);
  const [gpaForecast9Average, setGpaForecast9Average] = React.useState<any[]>([]);
  const [gpaForecast10Data, setGpaForecast10Data] = React.useState<any[]>([]);
  const [gpaForecast10Custom, setGpaForecast10Custom] = React.useState<any[]>([]);
  const [gpaForecast10Average, setGpaForecast10Average] = React.useState<any[]>([]);
  const [gpaForecast11Data, setGpaForecast11Data] = React.useState<any[]>([]);
  const [gpaForecast11Custom, setGpaForecast11Custom] = React.useState<any[]>([]);
  const [gpaForecast11Average, setGpaForecast11Average] = React.useState<any[]>([]);
  const [gpaForecast12Data, setGpaForecast12Data] = React.useState<any[]>([]); 
  const [gpaForecast12Custom, setGpaForecast12Custom] = React.useState<any[]>([]);
  const [gpaForecast12Average, setGpaForecast12Average] = React.useState<any[]>([]);

  const [loading, setLoading] = React.useState(false);
  const params: { id?: string | number } = useParams();
  const dispatch = useAppDispatch();
  const history = useHistory();

 let token = Cookies.get(authenticateSettings.tokenName);
 
  const handleLogout = () => {
    dispatch(logout());
    Cookies.remove(authenticateSettings.tokenName);
    Cookies.remove('userId');
    history.push(routers.login.path);
  };

  const getGpaDetails = async(studentId: string, gradeNo: number) => {
    let arr:any[] = [];
    const ret:any[] = await gpaForecastServices.getGpaForeCastByGradeNo(studentId, gradeNo);
    if (ret && ret.length>0) {
      for (let i=0; i<ret.length; i++) {
          let item = ret[i];
          let ret2:any[] = await gpaForecastDetailServices.getGpaForeCastDetails(item.gpa_forecast_id);
          if (ret2 && ret2.length>0) {
            for(let j=0; j<ret2.length; j++) {
              arr.push(ret2[j]);
            }
          }
      }        
    }
    return arr;
  }

  const formatDecial =(num:number) =>{
    return (Math.round(num * 100) / 100).toFixed(2);
  } 
  
  const calcAverageGpa = (data:any[]) => {
    if (data && data.length>0) {
      let sum1 = 0;
      let sum2 = 0;
      for(let i=0; i< data.length; i++) {
        sum1 += data[i].unweighted_point;
        sum2 += data[i].weighted_point;
      }
      return { unweighted_point: sum1/data.length, weighted_point: sum2/data.length }
    }
    return { unweighted_point: 0, weighted_point: 0 }
  }

  const CalcAverageGpaPoint = (data:any[], gradeNo: number) => {
    let gradeth = `${gradeNo}th Grade`;
    if (data && data.length>0) {
      let sum1 = 0;
      let sum2 = 0;
      for(let i=0; i< data.length; i++) {
        sum1 += data[i].unweighted_point;
        sum2 += data[i].weighted_point;
      }     
      return [{ grade: gradeth, unweighted_point: formatDecial(sum1/data.length), weighted_point: formatDecial(sum2/data.length)}]
    }
    return [{ grade: gradeth, unweighted_point: 0, weighted_point: 0}]
  }

  const CalcAverageGpa_10Grade = (data9:any[], data10:any[]) => {
    let gradeth = '9th-10th Grade';
    let arr:any[] = [];
    let avg9:any = calcAverageGpa(data9);
    let avg10:any = calcAverageGpa(data10);
    let unweighted_point = (avg9.unweighted_point + avg10.unweighted_point) /2;
    let weighted_point= (avg9.weighted_point + avg10.weighted_point) /2;
    arr.push(CalcAverageGpaPoint(data10, 10)[0]);
    arr.push({grade: gradeth, unweighted_point: formatDecial(unweighted_point), weighted_point: formatDecial(weighted_point)});
    return arr;
  }

  const CalcAverageGpa_11Grade = (data9:any[], data10:any[], data11:any[]) => {
    let grade_9_11th = '9th-11th Grade';
    let grade_10_11th = '10th-11th Grade';  
    let arr:any[] = []; 
    let avg9:any = calcAverageGpa(data9);
    let avg10:any = calcAverageGpa(data10);
    let avg11:any = calcAverageGpa(data11);
    let unweighted_point_9_11 = (avg9.unweighted_point + avg10.unweighted_point + avg11.unweighted_point) /3;
    let weighted_point_9_11= (avg9.weighted_point + avg10.weighted_point + avg11.weighted_point) /3;
    arr.push(CalcAverageGpaPoint(data11, 11)[0]);
    arr.push({grade: grade_9_11th, unweighted_point: formatDecial(unweighted_point_9_11), weighted_point: formatDecial(weighted_point_9_11)});
    let unweighted_point_10_11 = (avg10.unweighted_point + avg11.unweighted_point) /2;
    let weighted_point_10_11= (avg10.weighted_point + avg11.weighted_point) /2;
    arr.push({grade: grade_10_11th, unweighted_point: formatDecial(unweighted_point_10_11), weighted_point: formatDecial(weighted_point_10_11)});   
    return arr;
  }

  const CalcAverageGpa_12Grade = (data9:any[], data10:any[], data11:any[], data12:any[]) => {
    let grade_9_12th = '9th-12th Grade';
    let grade_10_12th = '10th-12th Grade';  
    let arr:any[] = []; 
    let avg9:any = calcAverageGpa(data9);
    let avg10:any = calcAverageGpa(data10);
    let avg11:any = calcAverageGpa(data11);
    let avg12:any = calcAverageGpa(data12);
    let unweighted_point_9_12 = (avg9.unweighted_point + avg10.unweighted_point + avg11.unweighted_point + avg12.unweighted_point) /4;
    let weighted_point_9_12= (avg9.weighted_point + avg10.weighted_point + avg11.weighted_point + avg12.weighted_point) /4;
    arr.push(CalcAverageGpaPoint(data11, 12)[0]);
    arr.push({grade: grade_9_12th, unweighted_point: formatDecial(unweighted_point_9_12), weighted_point: formatDecial(weighted_point_9_12)});
    let unweighted_point_10_12 = (avg10.unweighted_point + avg11.unweighted_point + avg12.unweighted_point) /3;
    let weighted_point_10_12= (avg10.weighted_point + avg11.weighted_point + avg12.weighted_point) /3;
    arr.push({grade: grade_10_12th, unweighted_point: formatDecial(unweighted_point_10_12), weighted_point: formatDecial(weighted_point_10_12)});   
    return arr;
  }

  const getData = async () => {
    token = Cookies.get(authenticateSettings.tokenName);
    if (token && params.id) {
      let studentId = `${params.id}`;
      setLoading(true);
      try { 
        let arr9 = await getGpaDetails(studentId, 9);
        let arr9_origin:any[] = arr9.filter( (item:any) => (item.is_custom === false));
        setGpaForecast9Data(arr9_origin);
        let arr9_custom:any[] = arr9.filter( (item:any) => (item.is_custom === true));
        setGpaForecast9Custom(arr9_custom);
        setGpaForecast9Average(CalcAverageGpaPoint(arr9, 9));
  
        let arr10 = await getGpaDetails(studentId, 10);
        let arr10_origin:any[] = arr10.filter( (item:any) => (item.is_custom === false));
        setGpaForecast10Data(arr10_origin);
        let arr10_custom:any[] = arr10.filter( (item:any) => (item.is_custom === true));
        setGpaForecast10Custom(arr10_custom);
        setGpaForecast10Average(CalcAverageGpa_10Grade(arr9, arr10));
  
        let arr11 = await getGpaDetails(studentId, 11);
        let arr11_origin:any[] = arr11.filter( (item:any) => (item.is_custom === false));
        setGpaForecast11Data(arr11_origin);
        let arr11_custom:any[] = arr11.filter( (item:any) => (item.is_custom === true));
        setGpaForecast11Custom(arr11_custom);
        setGpaForecast11Average(CalcAverageGpa_11Grade(arr9, arr10, arr11));
        
        let arr12 = await getGpaDetails(studentId, 12);
        let arr12_origin:any[] = arr12.filter( (item:any) => (item.is_custom === false));
        setGpaForecast12Data(arr12_origin);
        let arr12_custom:any[] = arr12.filter( (item:any) => (item.is_custom === true));
        setGpaForecast12Custom(arr12_custom);
        setGpaForecast12Average(CalcAverageGpa_12Grade(arr9, arr10, arr11, arr12));
      }
      catch(ex) {
        console.log('Error: ', ex);
      }    
      setLoading(false);
    } else {
      handleLogout();
    }
  };

  React.useEffect(() => {
    if (!loading) {
      params.id !== ':id' && getData();
    }
  }, [params.id]);

  React.useEffect(() => {
    const syncData = async () => {
      if (!loading) {
        let studentId = `${params.id}`;
        const ret:any = await gpaForecastServices.syncGpaForecast(studentId);
        if (ret && ret.statusCode === 200) {
          await getData();
        }
      }
    };
    syncData();
  }, []);

  const [value, setValue] = React.useState(1);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setCurrentModuleId(`9.${newValue}`);
  };
  const checkModule = useAppSelector((store) => store.basicInformation.checkModule);

  return (
    <div>
      <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto">
        <Tab value={1} label="General" className={buildClassName('9.1', checkModule)} />

      </Tabs>
      <CustomTabPanel value={value} index={1}>
        <Card sx={{ backgroundColor: 'var(--ui-grey-soft, #F7F8FA)', margin: 2, padding: 2 }}>
          <Typography sx={{ fontSize: '1.75rem', fontWeight: '500', color: '#4E5A6F' }}>
            GPA Forecaster
          </Typography>
          <div className="text-field-light p-2">
          </div>
          <Stack justifyContent={'center'} alignItems={'center'}>
            {loading ? (
              <LoadingContent />
            ) : (
              <div className="row w-100">
                  <div className="col-md-12 py-4">
                    <h2>Grade 9th</h2>
                  </div>
                  <div className="col-md-12">
                    <SimpleTable
                      column={[
                        { label: 'School Name', value: 'school_name'},
                        //{ label: 'Summer Course', value: 'summer_course', isYesNo: true},
                        { label: 'Course', value: 'course'},
                        { label: 'Term', value: 'term'},
                        { label: 'Grade', value: 'grade'},
                        { label: 'Unweighted Point', value: 'unweighted_point'},
                        { label: 'Weighted point', value: 'weighted_point'},
                      ]}
                      data = {gpaForecast9Data}
                    />
                  </div>
                  <div className="col-md-12 py-3">
                    <h3 className="fs-6">Grade 9th - Custom Data</h3>
                    <SimpleTable
                      column={[
                        { label: 'School Name', value: 'school_name'},
                        //{ label: 'Summer Course', value: 'summer_course', isYesNo: true},
                        { label: 'Course', value: 'course'},
                        { label: 'Term', value: 'term'},
                        { label: 'Grade', value: 'grade'},
                        { label: 'Unweighted Point', value: 'unweighted_point'},
                        { label: 'Weighted point', value: 'weighted_point'},
                      ]}
                      data = {gpaForecast9Custom}
                    />
                  </div>
                  <div className="d-flex w-100 justify-content-end pb-4">
                    <div className="d-flex">
                    <SumarryTable
                      column={[
                        { label: '', value: 'grade', fontsize5: true},
                        { label: 'Unweighted Point', value: 'unweighted_point', align: 'center'},
                        { label: 'Weighted point', value: 'weighted_point', align: 'center'},
                      ]}
                      data = {gpaForecast9Average}
                      minWidth={120}
                     />
                    </div>
                  </div>
                  <hr/>

                  <div className="col-md-12 py-4">
                    <h2>Grade 10th</h2>
                  </div>
                  <div className="col-md-12">
                    <SimpleTable
                      column={[
                        { label: 'School Name', value: 'school_name'},
                        //{ label: 'Summer Course', value: 'summer_course', isYesNo: true},
                        { label: 'Course', value: 'course'},
                        { label: 'Term', value: 'term'},
                        { label: 'Grade', value: 'grade'},
                        { label: 'Unweighted Point', value: 'unweighted_point'},
                        { label: 'Weighted point', value: 'weighted_point'},
                      ]}
                      data = {gpaForecast10Data}
                    />
                  </div>
                  <div className="col-md-12 py-3">
                    <h3 className="fs-6">Grade 10th - Custom Data</h3>
                    <SimpleTable
                      column={[
                        { label: 'School Name', value: 'school_name'},
                        //{ label: 'Summer Course', value: 'summer_course', isYesNo: true},
                        { label: 'Course', value: 'course'},
                        { label: 'Term', value: 'term'},
                        { label: 'Grade', value: 'grade'},
                        { label: 'Unweighted Point', value: 'unweighted_point'},
                        { label: 'Weighted point', value: 'weighted_point'},
                      ]}
                      data = {gpaForecast10Custom}
                    />
                  </div>
                  <div className="d-flex w-100 justify-content-end pb-4">
                    <div className="d-flex">
                    <SumarryTable
                      column={[
                        { label: '', value: 'grade', fontsize5: true},
                        { label: 'Unweighted Point', value: 'unweighted_point', align: 'center'},
                        { label: 'Weighted point', value: 'weighted_point', align: 'center'},
                      ]}
                      data = {gpaForecast10Average}
                      minWidth={120}
                     />
                    </div>
                  </div>
                  <hr/>

                  <div className="col-md-12 py-4">
                    <h2>Grade 11th</h2>
                  </div>
                  <div className="col-md-12">
                    <SimpleTable
                      column={[
                        { label: 'School Name', value: 'school_name'},
                        //{ label: 'Summer Course', value: 'summer_course', isYesNo: true},
                        { label: 'Course', value: 'course'},
                        { label: 'Term', value: 'term'},
                        { label: 'Grade', value: 'grade'},
                        { label: 'Unweighted Point', value: 'unweighted_point'},
                        { label: 'Weighted point', value: 'weighted_point'},
                      ]}
                      data = {gpaForecast11Data}
                    />
                  </div>
                  <div className="col-md-12 py-3">
                    <h3 className="fs-6">Grade 11th - Custom Data</h3>
                    <SimpleTable
                      column={[
                        { label: 'School Name', value: 'school_name'},
                        //{ label: 'Summer Course', value: 'summer_course', isYesNo: true},
                        { label: 'Course', value: 'course'},
                        { label: 'Term', value: 'term'},
                        { label: 'Grade', value: 'grade'},
                        { label: 'Unweighted Point', value: 'unweighted_point'},
                        { label: 'Weighted point', value: 'weighted_point'},
                      ]}
                      data = {gpaForecast11Custom}
                    />
                  </div>
                  <div className="d-flex w-100 justify-content-end pb-4">
                    <div className="d-flex">
                    <SumarryTable
                      column={[
                        { label: '', value: 'grade', fontsize5: true},
                        { label: 'Unweighted Point', value: 'unweighted_point', align: 'center'},
                        { label: 'Weighted point', value: 'weighted_point', align: 'center'},
                      ]}
                      data = {gpaForecast11Average}
                      minWidth={120}
                     />
                    </div>
                  </div>
                  <hr />

                  <div className="col-md-12 py-4">
                    <h2>Grade 12th</h2>
                  </div>
                  <div className="col-md-12">
                    <SimpleTable
                      column={[
                        { label: 'School Name', value: 'school_name'},
                        //{ label: 'Summer Course', value: 'summer_course', isYesNo: true},
                        { label: 'Course', value: 'course'},
                        { label: 'Term', value: 'term'},
                        { label: 'Grade', value: 'grade'},
                        { label: 'Unweighted Point', value: 'unweighted_point'},
                        { label: 'Weighted point', value: 'weighted_point'},
                      ]}
                      data = {gpaForecast12Data}
                    />
                  </div>
                  <div className="col-md-12 py-3">
                    <h3 className="fs-6">Grade 12th - Custom Data</h3>
                    <SimpleTable
                      column={[
                        { label: 'School Name', value: 'school_name'},
                        //{ label: 'Summer Course', value: 'summer_course', isYesNo: true},
                        { label: 'Course', value: 'course'},
                        { label: 'Term', value: 'term'},
                        { label: 'Grade', value: 'grade'},
                        { label: 'Unweighted Point', value: 'unweighted_point'},
                        { label: 'Weighted point', value: 'weighted_point'},
                      ]}
                      data = {gpaForecast12Custom}
                    />
                  </div>
                  <div className="d-flex w-100 justify-content-end pb-4">
                    <div className="d-flex">
                    <SumarryTable
                      column={[
                        { label: '', value: 'grade', fontsize5: true},
                        { label: 'Unweighted Point', value: 'unweighted_point', align: 'center'},
                        { label: 'Weighted point', value: 'weighted_point', align: 'center'},
                      ]}
                      data = {gpaForecast12Average}
                      minWidth={120}
                     />
                    </div>
                  </div>
              </div>
            )}
          </Stack>
        </Card>
      </CustomTabPanel>

    </div>
  );
};

export default RGPAForecaster;
