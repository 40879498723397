import TextIconInput from 'components/input/TextIconInput';
import React from 'react';
import { Badge, Button, Form, Row, Col } from 'react-bootstrap';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import CheckboxInput from 'components/input/CheckboxInput';
import { useFormik } from 'formik';
import VerifyCodeInput from 'components/input/VerifyCodeInput';
import SelectPhoneCodeInput from 'components/input/SelectPhoneCodeInput';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { checkVerificationCode, sendVerificationCode } from 'app/authorization/authorizationSlice';
import * as Yup from 'yup';
import { phoneRegExp } from 'components/helper/appConfig';
import { Typography } from '@mui/material';
import { USER_ROLE } from 'constants/common';
import { useHistory } from 'react-router';

interface Props {
  setStep: (value: number) => void;
  currentStep: number;
  formik: any;
}

const FormAuthPhoneNumber = (props: Props) => {
  const dispatch = useAppDispatch();
  const authorizationStore = useAppSelector((store) => store.authorization);
  let history = useHistory();
  const params = new URLSearchParams(history.location.search);
  const stepURL = params.get('step');
  const [stepAuth, setStepAuth] = React.useState<'phone' | 'code'>('phone');
  const [verifyCode, setVerifyCode] = React.useState<string | undefined>(undefined);
  const formik = useFormik({
    initialValues: {
      phone_number: undefined,
      country_code: '+1',
      receive_sms: true,
    },
    validationSchema: Yup.object({
      advisor_phone_number: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
    }),
    onSubmit: async (values) => {
      const res = await dispatch(
        sendVerificationCode({
          student_id: authorizationStore?.studentId,
          phone_number: `${values?.country_code}${values?.phone_number}`,
        })
      );
      if (res?.payload) setStepAuth('code');
    },
  });

  const sendVerifyCode = async () => {
      try{
        const res = await dispatch(
          checkVerificationCode({
            verification_code: verifyCode,
            student_id: authorizationStore.studentId,
            country_code: formik.values.country_code,
            phone_number: formik.values.phone_number,
          })
        );
        if (res.payload) {  
          if (props.formik.values.user_role === USER_ROLE.counselor) {
            props.setStep(5);
          }
          else
          {
            props.setStep(4);
          }
      }
      } catch (error) {
        console.log(error);
      }      
  };

  const [timer, setTimer] = React.useState(60);
  const interValRef: any = React.useRef();

  React.useEffect(() => {
    if (stepAuth === 'code')
      interValRef.current = setInterval(() => {
        setTimer((timer) => timer - 1);
      }, 1000);
    return () => {
      clearInterval(interValRef.current);
    };
  }, [stepAuth]);
  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        {stepAuth === 'phone' && (
          <>
            <div className="m-3">
            {!stepURL && <Button className="text-field-light" variant="light" onClick={() => props.setStep(2)}>
                Back
              </Button>}
            </div>
            <h2 className="m-3">Confirm your phone number</h2>
            <div className="m-3 d-flex justify-content-between align-items-center">
              <h5 className="">
                We will send you a SMS containing 4 digits for implementing the verification.
              </h5>
              <Badge className="px-2 py-1" bg="secondary">
                {/* <span className="h6">{props.currentStep}/2</span> */}
              </Badge>
            </div>
            <Row className="m-3 p-0">
              <SelectPhoneCodeInput
                mdCol={'6'}
                values={formik.values.country_code}
                onChange={formik.handleChange}
                name={'country_code'}
                label={'Country Code'}
              />

              <TextIconInput
                className="phone-number-input"
                mdCol={'6'}
                icon={<PhoneAndroidIcon />}
                label={'Phone Number'}
                name={'phone_number'}
                values={formik.values.phone_number}
                onChange={formik.handleChange}
                feedback={
                  formik.touched.phone_number &&
                  formik.errors.phone_number && (
                    <span className="text-danger">{formik.errors.phone_number}</span>
                  )
                }
              />
            </Row>
            <div className="mx-3">
              <CheckboxInput
                mdCol={'6'}
                label={'I would like to receive SMS updates'}
                name={'receive_sms'}
                values={formik.values.receive_sms}
                onChange={formik.handleChange}
              />
            </div>
            <div className="mx-3">
              <Button
                className="w-100 p-2 my-3"
                variant="primary"
                onClick={() => formik.handleSubmit()}
              >
                <span className="h5 fw-bold">Send Code</span>
              </Button>
            </div>
          </>
        )}
        {stepAuth === 'code' && (
          <>
            <div className="m-3">
              <Button className="text-field-light" variant="light" onClick={() => props.setStep(2)}>
                Back
              </Button>
            </div>
            <h2 className="m-3">Confirmation</h2>
            <div className="m-3 d-flex justify-content-between align-items-center">
              <h5>Please type the verification code we sent to your phone number</h5>
              <Badge className="px-2 py-1" bg="secondary">
                <span className="h6">{props.currentStep}/2</span>
              </Badge>
            </div>
            <Col md={6} className="p-0">
              <div className="my-3 d-flex flex-column align-items-right">
                <Row className="p-2">
                  <TextIconInput
                    disabled
                    mdCol={'12'}
                    icon={<PhoneAndroidIcon />}
                    defaultValue={`${formik.values.country_code}${formik.values.phone_number}`}
                  />
                </Row>

                <div style={{ padding: '1rem' }}>
                  <VerifyCodeInput onChange={(value) => setVerifyCode(value.join(''))} />
                </div>
                {timer <= 0 ? (
                  <Button
                    variant="outline-primary"
                    className="p-2 m-3"
                    onClick={() => {
                      setTimer(60);
                      formik.handleSubmit();
                    }}
                  >
                    Resend Code
                  </Button>
                ) : (
                  <Typography className="text-field-light w-100 text-center my-3">
                    {timer}s
                  </Typography>
                )}
                <Button className="p-2 m-3" variant="primary" onClick={() => sendVerifyCode()}>
                  <span className="h5 fw-bold">Verify Code</span>
                </Button>
              </div>
            </Col>
          </>
        )}
      </Form>
    </>
  );
};

export default FormAuthPhoneNumber;
