import http from './http';

const gpaForecastDetailServices = {
  async getGpaForeCastDetails(gpa_forecast_id: any) {
   const body = {
    gpa_forecast_id: gpa_forecast_id
   };
    const res:any[] = await http.post(`api/gpa-forecast-detail/search/by_gpa_forecast_id`, body);  
    return res;
  },



};

export default gpaForecastDetailServices;
