import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Col, Row, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import TextInput from 'components/input/textInput';
import DatePickerInput from 'components/input/DatePicker';
import SelectInput from 'components/input/SelectInput';
import { dateFormat } from 'components/helper/helper';
import moment from 'moment';
import GroupRadioButtonRounded from 'components/input/GroupRadioButtonRounded';
import YesNoSwitch from 'components/input/YesNoSwitch';
import GroupCheckbox from 'components/input/GroupCheckbox';
interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface Props {
  handleSave: (value: any) => void;
  item: any;
  indexText: string;
  key: number;
}

const cmSubjectTestOption = [
  'African American Studies',
  'Art History',
  'Art: Art-2-D Design',     // #1656 - rename 2024-09-17
  'Art: Art-3-D Design',     // #1656 - rename 2024-09-17
  'Art: Drawing',
  'Biology',
  'Calculus AB',
  'Calculus BC',
  'Chemistry',
  'Chinese Language & Culture',
  'Government & Politics: Comparative',
  'Government & Politics: United States',
  'Computer Science A',
  'Computer Science Principles',
  'Economics: Macroeconomics',
  'Economics: Microeconomics',
  'English Language & Composition',
  'English Literature & Composition',
  'Environmental Science',
  'European History',
  'French Language',
  'German Language',
  'Human Geography',
  'Italian Language & Culture',
  'Japanese Language & Culture',
  'Latin',
  'Music Theory',
  'Physics 1',
  'Physics 2',
  'Physics C - Electricity & Magnetism',
  'Physics C Mechanics',
  'Precalculus',
  'Psychology',
  'Research',
  'Seminar',
  'Spanish Language',
  'Spanish Literature',
  'Statistics',
  'United States History',
  'World History',
];
const ACTSubjectConfig = (props: Props) => {
  const formItem = useFormik({
    initialValues: {
      isReceivedScore: '',
      examStatus: 'Planned',
      subject: '',
      subjectCM: '',
      dateTaken: null,
      dateTakenCSUApp: '',
      dateTakenMonth: '',
      dateTakenYear: '',
      score: '',
      planToTakeExamMonth: '',
      planToTakeExamYear: '',
      planToTakeExam: '',
      isIncludeIntoCommonApp: false,
      isIncludeIntoUCApp: false,
      isIncludeIntoCSUApp: false,
    },
    onSubmit: (values) => {},
  });
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  React.useEffect(() => {
    if (props.item) formItem.resetForm({ values: { ...formItem.values, ...props.item } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (formItem.dirty) {
      props.handleSave({ ...formItem.values });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formItem.values]);

  const renderFillAppStatus = () => {
    return (
      <span>
        {formItem.values.isIncludeIntoCommonApp && (
          <img
            alt="cm"
            src="/assets/icons/icon-cm-5.svg"
            style={{ marginTop: '-8px', marginLeft: '4px' }}
          />
        )}
        {formItem.values.isIncludeIntoUCApp && (
          <img
            alt="uc"
            src="/assets/icons/icon-uc-5.svg"
            style={{ marginTop: '-8px', marginLeft: '4px' }}
          />
        )}
        {formItem.values.isIncludeIntoCSUApp && (
          <img
            alt="csu"
            src="./assets/icons/icon-csu-5.svg"
            style={{ marginTop: '-8px', marginLeft: '4px' }}
          />
        )}
      </span>
    );
  };

  return (
    <Card className="w-100 my-3 px-0" key={props.indexText}>
      <CardActions
        disableSpacing
        onClick={handleExpandClick}
        sx={{
          backgroundColor: expanded ? '#AAEBF7' : 'none',
          borderBottom: expanded ? '2px solid #4D88F0' : 'none',
          marginLeft: '-8px',
        }}
      >
        <div className="w-100 d-flex justify-content-between">
          <span className={`p-1 ps-3`}>
            {formItem.values.subjectCM}
            {formItem.values.examStatus === 'Completed' ? (
              <>
                {formItem.values.dateTaken && (
                  <> - ({moment(formItem.values.dateTaken).format(dateFormat)})</>
                )}
                {formItem.values.score && <> - Score: {formItem.values.score}</>}
              </>
            ) : formItem.values.planToTakeExam ? (
              <> - ({moment(formItem.values.planToTakeExam).format(dateFormat)})</>
            ) : null}

            {renderFillAppStatus()}
          </span>
          <strong className="text-field-light mt-1">{props.indexText}</strong>
        </div>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Row className="m-1">
            <SelectInput
              className="common-flag end-flag"
              form={formItem}
              option={cmSubjectTestOption}
              label="AP exam *"
              name="subjectCM"
              values={formItem.values.subjectCM}
              onChange={formItem.handleChange}
            />
            <Col md={6}>
              <GroupRadioButtonRounded
                name="examStatus"
                label="Is this exam completed or planned?"
                values={formItem.values.examStatus}
                onChange={formItem.handleChange}
                options={[{ value: 'Completed' }, { value: 'Planned' }]}
              />
            </Col>
            {formItem.values.examStatus === 'Completed' ? (
              <Row>
                <DatePickerInput
                  label={'When did you take this test? *'}
                  name="dateTaken"
                  values={formItem.values.dateTaken}
                  onChange={(value) => formItem.setFieldValue('dateTaken', value)}
                />
                <Col md={6}>
                  <YesNoSwitch
                    className="mb-3"
                    label="Have you received your score yet? *"
                    name="isReceivedScore"
                    values={formItem.values.isReceivedScore}
                    onChange={(e: any) => {
                      formItem.handleChange(e);
                      if (e.target.value === 'false') {
                        formItem.setFieldValue('score', '');
                      }
                    }}
                  />
                </Col>
                {formItem.values.isReceivedScore === 'true' && (
                  <TextInput
                    label="Score *"
                    name="score"
                    type="number"
                    max={5}
                    min={1}
                    values={formItem.values.score}
                    onChange={formItem.handleChange}
                  />
                )}
              </Row>
            ) : (
              <>
                <DatePickerInput
                  label="When do you plan to take the exam? *"
                  name="planToTakeExam"
                  values={formItem.values.planToTakeExam}
                  onChange={(value) => formItem.setFieldValue('planToTakeExam', value)}
                />
                {/* <SelectInput
                  form={formItem}
                  option={monthOptions}
                  label="Month"
                  name="planToTakeExamMonth"
                  values={formItem.values.planToTakeExamMonth}
                  onChange={formItem.handleChange}
                />
                <SelectInput
                  form={formItem}
                  option={['2024', '2025', '2026', '2027']}
                  label="Year"
                  name="planToTakeExamYear"
                  values={formItem.values.planToTakeExamYear}
                  onChange={formItem.handleChange}
                /> */}
              </>
            )}
            <GroupCheckbox
              label="Include test info into these applications"
              form={formItem}
              mdCol={3}
              options={[
                {
                  value: 'isIncludeIntoCommonApp',
                  label: 'Common App',
                },
                {
                  value: 'isIncludeIntoUCApp',
                  label: 'UC App',
                },
                {
                  value: 'isIncludeIntoCSUApp',
                  label: 'CSU App',
                },
              ]}
            />
            <Col xs={6} md={9} />
            <Col xs={6} md={3} className="p-3">
              <Button
                className="w-100"
                onClick={() => {
                  props.handleSave({ ...formItem.values });
                  setExpanded(false);
                }}
              >
                Save Info
              </Button>
            </Col>
          </Row>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default ACTSubjectConfig;
