import http from './http';

const gpaForecastServices = {
  async getGpaForeCast(student_id: any) {
   const body = {
      student_id: student_id
   };
    const res = await http.post(`api/gpa-forecast/search/by_student_id`, body);  
    return res;
  },

  async getGpaForeCastByGradeNo(studentId:any, gradeNo: number) {
    const body = {
      student_id: Number(studentId ?? 0),
      grade_no: gradeNo
    };
     const res:any[] = await http.post(`api/gpa-forecast/search/by_grade_no`, body);  
     return res;
   },

  async syncGpaForecast(studentId: any) {
    const res = await http.get(`api/grade-and-college-course/sync_gpa_forecast/${studentId}`);
    return res;
  },
 
};

export default gpaForecastServices;
