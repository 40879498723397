export const subjectAreaOptions = [
  'A History / Social Science',
  'B English',
  'C Mathematics',
  'D1 - Biological Science',
  'D2 - Physical Science',
  'E Language Other Than English',
  'F Visual & Performing Arts',
  'G College-Preparatory Elective',
  'Non A-G Course',
];

export const subjectOptions = [
  { value: '--', type: '' },
  { value: 'Civics/American Government', type: 'A History / Social Science' },
  { value: 'U.S. History', type: 'A History / Social Science' },
  { value: 'World History/Cultures/Hist. Geography', type: 'A History / Social Science' },
  { value: 'English', type: 'B English' },
  { value: 'English-ESL/ELD', type: 'B English' },
  { value: 'Algebra I', type: 'C Mathematics' },
  { value: 'Geometry', type: 'C Mathematics' },
  { value: 'Algebra II', type: 'C Mathematics' },
  { value: 'Mathematics I (Integrated)', type: 'C Mathematics' },
  { value: 'Mathematics II (Integrated)', type: 'C Mathematics' },
  { value: 'Mathematics III (Integrated)', type: 'C Mathematics' },
  { value: 'Algebra II/Trigonometry', type: 'C Mathematics' },
  { value: 'Statistics', type: 'C Mathematics' },
  { value: 'Pre-Calculus', type: 'C Mathematics' },
  { value: 'Calculus', type: 'C Mathematics' },
  { value: 'Other Advanced Mathematics', type: 'C Mathematics' },
  { value: 'Computer Science', type: 'C Mathematics' },
  { value: 'Biology/Life Sciences', type: 'D1 - Biological Science' },
  { value: 'Biology/Earth & Space Sciences', type: 'D1 - Biological Science' },
  { value: 'Chemistry', type: 'D2 - Physical Science' },
  { value: 'Chemistry/Earth & Space Sciences', type: 'D2 - Physical Science' },
  { value: 'Physics', type: 'D2 - Physical Science' },
  { value: 'Physics/Earth & Space Sciences', type: 'D2 - Physical Science' },
  { value: 'Integrated Science 1', type: 'D2 - Physical Science' },
  { value: 'Integrated Science 2', type: 'D2 - Physical Science' },
  { value: 'Integrated Science 3', type: 'D2 - Physical Science' },
  { value: 'Applied Science', type: 'D2 - Physical Science' },
  { value: 'Computer Science', type: 'D2 - Physical Science' },
  { value: 'Earth & Space Sciences', type: 'D2 - Physical Science' },
  { value: 'Engineering', type: 'D2 - Physical Science' },
  { value: 'Interdisciplinary Sciences', type: 'D2 - Physical Science' },
  { value: 'Yr 1 Lang Other Than English', type: 'E Language Other Than English' },
  { value: 'Yr 2 Lang Other Than English', type: 'E Language Other Than English' },
  { value: 'Yr 3 Lang Other Than English', type: 'E Language Other Than English' },
  { value: 'Yr 4+ Lang Other Than English', type: 'E Language Other Than English' },
  { value: 'Dance ', type: 'F Visual & Performing Arts' },
  { value: 'Music', type: 'F Visual & Performing Arts' },
  { value: 'Theater', type: 'F Visual & Performing Arts' },
  { value: 'Visual Arts', type: 'F Visual & Performing Arts' },
  { value: 'Interdisciplinary Arts', type: 'F Visual & Performing Arts' },
  { value: 'Elective - English', type: 'G College-Preparatory Elective' },
  { value: 'Elective - ESL/ELD', type: 'G College-Preparatory Elective' },
  { value: 'Elective - History/Social Science', type: 'G College-Preparatory Elective' },
  { value: 'Elective - Interdisciplinary', type: 'G College-Preparatory Elective' },
  { value: 'Elective - Language Other than English', type: 'G College-Preparatory Elective' },
  { value: 'Elective - Mathematics', type: 'G College-Preparatory Elective' },
  { value: 'Elective - Math-Computer Science', type: 'G College-Preparatory Elective' },
  { value: 'Elective - Science - Biology/Life Sciences', type: 'G College-Preparatory Elective' },
  { value: 'Elective - Science - Integrated Science', type: 'G College-Preparatory Elective' },
  { value: 'Elective - Science - Physical Science', type: 'G College-Preparatory Elective' },
  { value: 'Elective - Visual & Performing Arts', type: 'G College-Preparatory Elective' },
  { value: 'Physical Education/Health', type: 'Non A-G Course' },
  { value: 'Religion', type: 'Non A-G Course' },
];

export const getSubjectsByType = (type: any) => {
  try {
    let list = subjectOptions.filter((o) => {
      return o.type === type;
    });

    if (list.length > 0) {
      list.push({ value: 'Other', type: '' });
      return list;
    }
  } catch (ex) {
    // skip
  }
  return subjectOptions;
};

// exact list - no Other adding
export const getSubjectsByTypeEx = (type: any) => {
  try {
    let list = subjectOptions.filter((o) => {
      return o.type === type;
    });

    if (list.length > 0) {
      return list;
    }
  } catch (ex) {
    // skip
  }
  return subjectOptions;
};

export const scheduleOptions = ['Semester', 'Trimester', 'Quarters', 'Yearly', 'Other'];
export const schoolYearOptions = [
  { id: '2016-2017', name: '2016-17' },
  { id: '2017-2018', name: '2017-18' },
  { id: '2018-2019', name: '2018-19' },
  { id: '2019-2020', name: '2019-20' },
  { id: '2020-2021', name: '2020-21' },
  { id: '2021-2022', name: '2021-22' },
  { id: '2022-2023', name: '2022-23' },
  { id: '2023-2024', name: '2023-24' },
  { id: '2024-2025', name: '2024-25' },
  { id: '2025-2026', name: '2025-26' },
  { id: '2026-2027', name: '2026-27' },
];

export const gradingScaleOptions = [
  { value: 'A-F' },
  { value: '1-100' },
  { value: '0.0-4.0' },
  { value: '0.0-5.0' },
  { value: '1-7' },
  { value: '1-10' },
  { value: '1-11' },
  { value: '1-20' },
  { value: 'Other' },
];
export const gradingScale2Options = [
  { value: 'Letter' },
  { value: 'Numbers' },
  { value: 'Other' },
  { value: 'This school does not provide grades' },
];

export const AToFGrade = [
  null,
  'A+',
  'A',
  'A-',
  'B+',
  'B',
  'B-',
  'C+',
  'C',
  'C-',
  'D+',
  'D',
  'D-',
  //'E',  // remove 2025-01-09
  'F',
  'Incomplete',
  'No Grade',
  'Fail - (Pass/Fail)',
  'Pass - (Pass/Fail)',
  'S - Satisfactory',
  'U- Unsatisfactory',
  'Withdrawn',
  'Withdrawn - Failed',
  'Medical Excuse',
];
export const to100Grade = [
  null,
  'Incomplete',
  'No Grade',
  'Fail - (Pass/Fail)',
  'Pass - (Pass/Fail)',
  'S - Satisfactory',
  'U- Unsatisfactory',
  'Withdrawn',
  'Withdrawn - Failed',
  'Medical Excuse',
];
export const to4Grade = [
  null,
  'Incomplete',
  'No Grade',
  'Fail - (Pass/Fail)',
  'Pass - (Pass/Fail)',
  'S - Satisfactory',
  'U- Unsatisfactory',
  'Withdrawn',
  'Withdrawn - Failed',
  'Medical Excuse',
];
export const classOptionEnglish = [
  { value: 'Communications' },
  { value: 'English/Language Arts I' },
  { value: 'English/Language Arts II' },
  { value: 'English/Language Arts III' },
  { value: 'English/Language Arts IV' },
  { value: 'Public Speaking' },
  { value: 'English Other' },
];
export const classOptionHistory = [
  { value: 'Ancient Civilizations' },
  { value: 'Ancient and Medieval History' },
  { value: 'Anthropology' },
  { value: 'Business Law' },
  { value: 'Civics' },
  { value: 'Comparative Government' },
  { value: 'Consumer Law' },
  { value: 'International Relations' },
  { value: 'Law Studies' },
  { value: 'Legal System' },
  { value: 'Macroeconomics' },
  { value: 'Medieval European History' },
  { value: 'Microeconomics' },
  { value: 'Modern European History' },
  { value: 'Philosophy' },
  { value: 'Political Science' },
  { value: 'Psychology' },
  { value: 'Sociolgy' },
  { value: 'State-Specific Studies' },
  { value: 'U.S. Ethnic Studies' },
  { value: 'U.S. Gender Studies' },
  { value: 'U.S. Geography' },
  { value: 'U.S. Government' },
  { value: 'U.S. History' },
  { value: 'United States and World Affairs' },
  { value: 'Western Civilization' },
  { value: 'World Geography' },
  { value: 'World History' },
  { value: 'History/Social Science-Other' },
];
export const classOptionLaboratory = [
  'Aerospace',
  'Anatomy',
  'Astronomy',
  'Biology',
  'Botany',
  'Chemistry',
  'Earth Science',
  'Environmental Science',
  'Genetics',
  'Geology',
  'Integrated Science',
  'Life Science',
  'Marine Science',
  'Meteorology',
  'Nutrition Science',
  'Organic Chemistry',
  'Physical Chemistry',
  'Physcial Geography',
  'Physical Science',
  'Physics',
  'Physiology',
  'Zoology',
  'Laboratory Scicence-Other',
];
export const classOptionForeign = [
  'American Indian Language I - V',
  'American Indian Literature',
  'American Sign Language I-V',
  'Arabic I-V',
  'Arabic Literature',
  'Braille',
  'Chinese I-V',
  'Chinese Literature',
  'Classical Greek I-V',
  'Classical Hebrew I-V',
  'Filipino I-V',
  'Filipino Literature',
  'French I-V',
  'French Literature',
  'German I-V',
  'German Literature',
  'Greek I-V',
  'Greek Literature',
  'Hebrew I-V',
  'Hebrew Literature',
  'Italian I-V',
  'Italian Literature',
  'Japanese I-V',
  'Japanese Literature',
  'Korean I-V',
  'Korean Literature',
  'Latin I-V',
  'Portuguese I-V',
  'Portuguese Literature',
  'Russian I-V',
  'Russian Literature',
  'Spanish I-V',
  'Spanish Literature',
  'Swahili I-V',
  'Swahili Literature',
  'Vietnamese I-V',
  'Vietnamese Literature',
  'Language Other Than English-Other',
];
export const classOptionMath = [
  'Algebra I-III',
  'Calculus',
  'Calculus AB',
  'Calculus BC',
  'Differential Calculus',
  'Discrete Mathematics',
  'Finite Mathematics',
  'Geometry',
  'Integraded Mathematics I-IV',
  'Linear Algebra',
  'Multivariate Calculus',
  'Pre-Algebra',
  'Pre-Calculus',
  'Statistics',
  'Trigonometry',
  'Math-Other',
];

export const classTypeOption = [
  'Regular/Standard',
  'Accelerated',
  'Advanced',
  'Advanced Placement (AP)',
  'AS/A-level/International A-level, Cambridge AICE',
  'College Prep',
  'Dual Enrollment',
  'Enriched',
  'GCSE, IGCSE',
  'Gifted',
  'High Honors',
  'Honors',
  'Intensive',
  'International Baccalaureate (IB)',
  'Pre-IB',
  'Regents',
  'N/A',
  // 'Advanced / Honors',
  // 'Advanced Placement (AP)',
  // 'Cambridge AICE',
  // 'International Baccalaureate Higher Level(IB HL)',
  // 'International Baccalaureate Standard Level(IB SL)',
  // 'Regents',
  // 'Running Start',
  // 'Other',
];
