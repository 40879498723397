/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Col, Row } from 'react-bootstrap';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

type Props = {
  onExpandClick?: (expanded: boolean) => void;
  headerElement: any;
  handleSave?: () => void;
  handleRemove?: () => void;
  expandAll?: boolean;
  handleExpandActive?: (value:any) => void;
};

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));
const CardConfig = (props: React.PropsWithChildren<Props>) => {
  const handleExpandClick = () => {
    setExpanded(!expanded);
    props.onExpandClick && props.onExpandClick(true);
    props.handleExpandActive && props.handleExpandActive(expanded);
  };
  const [expanded, setExpanded] = React.useState(props?.expandAll || false);

  React.useEffect(() => {
    setExpanded(props?.expandAll || false);
  }, [props?.expandAll]);

  return (
    <div className="m-0 position-relative">
      <Card className="w-100 my-2 px-0">
        <CardActions
          disableSpacing
          onClick={handleExpandClick}
          sx={{
            backgroundColor: expanded ? '#AAEBF7' : 'none',
            borderBottom: expanded ? '2px solid #4D88F0' : 'none',
          }}
        >
          <div className="ms-3 w-100 d-flex justify-content-between align-items-center">
            {props.headerElement}
          </div>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Row className="m-0 px-2">
              {props.children}
              <Col xs={6} md={9} />
              <Col xs={6} md={3} className="p-3">
                {props.handleSave && (
                  <Button
                    className="w-100"
                    onClick={() => {
                      props.handleSave && props.handleSave();
                      setExpanded(false);
                    }}
                  >
                    Save
                  </Button>
                )}
              </Col>
            </Row>
          </CardContent>
        </Collapse>
      </Card>
      {props.handleRemove && (
        <RemoveCircleOutlineIcon
          className="position-absolute text-field-light pointer"
          style={{ right: '-24px', top: '12px' }}
          onClick={props.handleRemove}
        />
      )}
    </div>
  );
};

export default CardConfig;
