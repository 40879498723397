import { Card, Stack, Tab, Tabs, Typography } from '@mui/material';
import React from 'react';
import CustomTabPanel from './TabPanel';
import LoadingContent from 'components/LoadingContent';
import { Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { checkEnableParseJSON } from 'components/helper/helper';
import activitiesAndHonorsServices from 'services/activitiesAndHonorsServices';
import ActivitySum from 'screen/summary/components/ActivitySum';
import HonorSum from 'screen/summary/components/HonorSum';
import EducationalProgramrSum from 'screen/summary/components/EducationalProgramrSum';
import ItemContent from 'screen/summary/components/ItemContent';
import { useAppSelector } from 'app/hooks';
import { buildClassName } from './RPersonalInformation';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import { ExpandMore } from 'screen/activitiesAndHonors/Activities';

const RActivitiesAndHonors = ({
  setCurrentModuleId,
}: {
  setCurrentModuleId: (id: string) => void;
}) => {
  const [value, setValue] = React.useState(1);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setCurrentModuleId(`6.${newValue}`);
  };
  const checkModule = useAppSelector((store) => store.basicInformation.checkModule);
  return (
    <div>
      <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto">
        <Tab value={1} label="Activities 1-10" className={buildClassName('6.1', checkModule)} />
        <Tab value={5} label="Activities 11-20" className={buildClassName('6.5', checkModule)} />
        <Tab value={6} label="Activities 20+" className={buildClassName('6.6', checkModule)} />
        <Tab value={2} label="Honors & Awards" className={buildClassName('6.2', checkModule)} />
        <Tab value={3} label="EOP and Other Info" className={buildClassName('6.3', checkModule)} />
      </Tabs>
      <CustomTabPanel value={value} index={1}>
        <Card sx={{ backgroundColor: 'var(--ui-grey-soft, #F7F8FA)', margin: 2, padding: 2 }}>
          <Typography sx={{ fontSize: '1.75rem', fontWeight: '500', color: '#4E5A6F' }}>
            Activities
          </Typography>
          <Stack justifyContent={'center'} alignItems={'center'}>
            <SummaryActivities startIndex={0} />
          </Stack>
        </Card>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={5}>
        <Card sx={{ backgroundColor: 'var(--ui-grey-soft, #F7F8FA)', margin: 2, padding: 2 }}>
          <Typography sx={{ fontSize: '1.75rem', fontWeight: '500', color: '#4E5A6F' }}>
            Activities
          </Typography>
          <Stack justifyContent={'center'} alignItems={'center'}>
            <SummaryActivities startIndex={10} />
          </Stack>
        </Card>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={6}>
        <Card sx={{ backgroundColor: 'var(--ui-grey-soft, #F7F8FA)', margin: 2, padding: 2 }}>
          <Typography sx={{ fontSize: '1.75rem', fontWeight: '500', color: '#4E5A6F' }}>
            Activities
          </Typography>
          <Stack justifyContent={'center'} alignItems={'center'}>
            <SummaryActivities startIndex={20} />
          </Stack>
        </Card>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Card sx={{ backgroundColor: 'var(--ui-grey-soft, #F7F8FA)', margin: 2, padding: 2 }}>
          <Typography sx={{ fontSize: '1.75rem', fontWeight: '500', color: '#4E5A6F' }}>
            Honors & Awards
          </Typography>
          <Stack justifyContent={'center'} alignItems={'center'}>
            <SummaryHonors />
          </Stack>
        </Card>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <Card sx={{ backgroundColor: 'var(--ui-grey-soft, #F7F8FA)', margin: 2, padding: 2 }}>
          <Typography sx={{ fontSize: '1.75rem', fontWeight: '500', color: '#4E5A6F' }}>
            EOP and Other Info
          </Typography>
          <Stack justifyContent={'center'} alignItems={'center'}>
            <SummaryOther />
          </Stack>
        </Card>
      </CustomTabPanel>
    </div>
  );
};

export default RActivitiesAndHonors;

const SummaryActivities = ({ startIndex }: { startIndex: number }) => {
  const [data, setData] = React.useState<any>({
    myActivities: null,
    is_have_any_activity_to_report: null,
  });
  const [loading, setLoading] = React.useState(false);
  const params: { id?: string | number } = useParams();
  const [expandAll, setExpandAll] = React.useState(false);
  const [expandActive, setExpandActive] = React.useState<number>(0);
  const dataActivity = (data?.myActivities ?? []).filter(
    (item: any, index: number) => index >= startIndex && index < startIndex + 10
  );
  const handleExpandAll = () => {
    setExpandAll(!expandAll);
    if (!expandAll) {
      setExpandActive(dataActivity.length);
    } else {
      setExpandActive(0);
    }
  };

  const handleExpandActive = (value: any) => {
    value
      ? setExpandActive(Math.abs(expandActive - 1))
      : setExpandActive(Math.abs(expandActive + 1));
  };

  React.useEffect(() => {
    if (expandActive === dataActivity.length) {
      setExpandAll(true);
    }

    if (expandActive === 0) {
      setExpandAll(false);
    }
  }, [expandActive, dataActivity.length, expandAll]);

  React.useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const res: any = await activitiesAndHonorsServices.getActivitiesAndHonors(params.id);
      setData({
        myActivities: checkEnableParseJSON(res?.activity_array, []),
        is_have_any_activity_to_report: res?.is_have_any_activity_to_report,
      });
      setLoading(false);
    };
    params.id !== ':id' && getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  return loading ? (
    <LoadingContent />
  ) : (
    <Row className="w-100">
      <div className="p-1">
        Reporting activities can help colleges better understand your life outside of the classroom.
        Examples of activities might include: Arts or music, Clubs, Community engagement, Family
        responsibilities, Hobbies Sports, Work or volunteering, Other experiences that have been
        meaningful to you.
      </div>
      <div className="bg-light rounded p-2">
        <div>App Notes:</div>
        <div> Common Application allows for up to 10 Activities + 5 Honors or Awards</div>
        <div> UC Application allows for a total of 20 Activites, Honors or Awards</div>
      </div>
      {dataActivity && (
        <>
          <ItemContent
            mdCol={12}
            label="Do you have any activities that you wish to report?"
            values={data?.is_have_any_activity_to_report}
          />
          {dataActivity?.length > 0 && (
            <div className="d-flex justify-content-end my-2">
              <ExpandMore aria-expanded={expandAll} aria-label="show all" onClick={handleExpandAll}>
                {!expandAll ? <KeyboardDoubleArrowDownIcon /> : <KeyboardDoubleArrowUpIcon />}
              </ExpandMore>
            </div>
          )}
          <ActivitySum
            data={dataActivity}
            startIndex={startIndex}
            total={data?.myActivities?.length}
            expandAll={expandAll}
            handleExpandActive={handleExpandActive}
          />
        </>
      )}
    </Row>
  );
};

const SummaryHonors = () => {
  const [data, setData] = React.useState<any>({
    myActivities: null,
    is_have_any_activity_to_report: null,
  });
  const [loading, setLoading] = React.useState(false);
  const params: { id?: string | number } = useParams();

  React.useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const res: any = await activitiesAndHonorsServices.getActivitiesAndHonors(params.id);
      setData({
        myHonors: checkEnableParseJSON(res?.honor_array, []),
        is_have_any_activity_to_report: res?.myActivityAndHonor?.is_have_any_activity_to_report,
      });
      setLoading(false);
    };
    params.id !== ':id' && getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);
  return loading ? (
    <LoadingContent />
  ) : (
    <Row className="w-100">{data.myHonors && <HonorSum data={data.myHonors} />}</Row>
  );
};

const SummaryOther = () => {
  const [myEducationalProgram, setMyEducationalProgram] = React.useState<any>(null);
  const [loading, setLoading] = React.useState(false);
  const params: { id?: string | number } = useParams();

  React.useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const res: any = await activitiesAndHonorsServices.getEducationalProgram(params.id);
      setMyEducationalProgram(res.csu_info);
      setLoading(false);
    };
    params.id !== ':id' && getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);
  return loading ? (
    <LoadingContent />
  ) : (
    <Row className="w-100">
      {myEducationalProgram && (
        <EducationalProgramrSum data={checkEnableParseJSON(myEducationalProgram, [])} />
      )}
    </Row>
  );
};
