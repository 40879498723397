/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-undef */
import { Card, Tab, Tabs, Typography, Grid } from '@mui/material';
import React, { useState } from 'react';
import RPersonalInformation, { moduleName } from './components/RPersonalInformation';
import CustomTabPanel from './components/TabPanel';
import { Button, Col, Row } from 'react-bootstrap';
import TextAreaInput from 'components/input/TextAreaInput';
import { useFormik } from 'formik';
import counselorServices from 'services/counselorServices';
import Cookies from 'js-cookie';
import { useHistory, useParams } from 'react-router-dom';
import { routers } from 'router/router';
import { logout, setParent } from 'app/basicInformationSlice/basicInformationSlice';
import { authenticateSettings } from 'components/helper/appConfig';
import { setStudentList } from 'app/counselorSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import RFamily from './components/RFamily';
import REducation from './components/REducation';
import RTests from './components/RTests';
import RCoursesAndGrades from './components/RCoursesAndGrades';
import RActivitiesAndHonors from './components/RActivitiesAndHonors';
import RAdditionalInfo from './components/RAdditionalInfo';
import { NoteModal, NoteStatus } from './counselor.types';
import moment from 'moment';
import { checkEnableParseJSON } from 'components/helper/helper';
import './ReviewStudent.css';
import Modal from 'react-bootstrap/Modal';
import RCollege from './components/RCollege';
import collegeServices from 'services/collegeServices';
import CheckboxInput from 'components/input/CheckboxInput';
import { checkModule } from 'screen/summary/ReviewPersonal';
import { setCheckModule } from 'app/basicInformationSlice/basicInformationSlice';
import { getHousehold } from 'app/family/familySlice';
import { getGeneral } from 'app/testingSlice/testingSlice';
import RGPAForecaster from './components/RGPAForecaster';

const initValues: {
  content: string;
  status: NoteStatus;
  isStudentReviewed: boolean;
  isCounselorApproved: boolean;
} = {
  content: '',
  status: '',
  isStudentReviewed: false,
  isCounselorApproved: false,
};
const tabContent: any = {
  1: 'Personal Information',
  2: 'Family',
  3: 'Education',
  4: 'Tests',
  5: 'Courses & Grades',
  6: 'Activities & Honors',
  7: 'Additional Info',
  9: 'GPA Forecaster',
};

export const BuildStatusColor = ({ status }: { status: string }) => {
  const classbuild =
    status === 'Review Feedback'
      ? 'text-danger'
      : status === 'Approved'
      ? 'text-success'
      : 'text-primary';
  return <Typography className={classbuild}>{status}</Typography>;
};

const ReviewStudent = () => {
  const params: { id: string } = useParams();
  const dispatch = useAppDispatch();
  const history = useHistory();  
  let studentId = Cookies.get('userId');
  let token = Cookies.get(authenticateSettings.tokenName);  
  const [showModalNoStudent, setShowModalNoStudent] = React.useState(false);
  const [value, setValue] = React.useState(1);
  const [checkedData, setCheckedData] = React.useState(checkModule);
  const [currentModuleId, setCurrentModuleId] = React.useState('1.1');

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setCurrentModuleId(`${newValue}.1`);
  };
  const store = useAppSelector((store) => store.counselor);
  
  const handleLogout = () => {
    dispatch(logout());
    Cookies.remove(authenticateSettings.tokenName);
    Cookies.remove('userId');
    history.push(routers.login.path);
  };     

  const getData = async () => {
    studentId = Cookies.get('userId');
    token = Cookies.get(authenticateSettings.tokenName);
    try {
      if (token && studentId) {
        const res: any = await counselorServices.getPartner(studentId);
        dispatch(setStudentList(res));
        if (res.length && params?.id === ':id') {
          history.push(routers.viewStudent.path.replace(':id', res[0].student_id));
        } else if (!res.length) {
          setShowModalNoStudent(true);
        }        
      }    
      else {
        handleLogout();
      }
    }
    catch(ex){
      console.log('Error: ', ex);
    }

  };

  React.useEffect(() => {
    getData();
  }, []);


  const getPartnerDataById = async () => {
    if (params?.id !== ':id') {
      let idToQuery = store.studentList.find(
        (item) => item.student_id.toString() === params?.id
      )?.id;
      idToQuery &&
        counselorServices.getPartnerById(idToQuery).then((res: any) => {
          const loadedCheckModule =
            checkEnableParseJSON(res?.notes_array, {
              checkModule,
              notes: [],
            })?.checkModule ?? checkModule;
          setCheckedData(loadedCheckModule);
          dispatch(setCheckModule(loadedCheckModule));

          counselorServices.getPartner(studentId).then((res: any) => {
            dispatch(setParent(res));
          });

        });
      
    }
  }

  React.useEffect(() => {
    getPartnerDataById();
  }, [params?.id, store.studentList]);

  React.useEffect(() => {
    setValue(1); // set default tab
  }, [params?.id]);
  

  const checkActiveModule = (startModule: string) => {
    return !(Object.keys(checkedData) ?? [])
      ?.filter((item) => item.includes(startModule))
      .map((key) => checkedData[key]?.[1])
      .includes(false);
  };
  return (
    <div className="pt-1">
      <Grid container sx={{ width: '100%' }}>
        <Grid item xs={12} md={8}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            className="mb-2"
          >
            <Tab
              value={1}
              label="Personal Information"
              className={checkActiveModule('1.') ? 'styled-tab-active' : 'styled-tab'}
            />
            <Tab
              value={2}
              label="Family"
              className={checkActiveModule('2.') ? 'styled-tab-active' : 'styled-tab'}
            />
            <Tab
              value={3}
              label="Education"
              className={checkActiveModule('3.') ? 'styled-tab-active' : 'styled-tab'}
            />
            <Tab
              value={5}
              label="Courses & Grades"
              className={checkActiveModule('5.') ? 'styled-tab-active' : 'styled-tab'}
            />
            <Tab
              value={4}
              label="Tests"
              className={checkActiveModule('4.') ? 'styled-tab-active' : 'styled-tab'}
            />
            <Tab
              value={6}
              label="Activities & Honors"
              className={checkActiveModule('6.') ? 'styled-tab-active' : 'styled-tab'}
            />
            <Tab
              value={7}
              label="Additional Info"
              className={checkActiveModule('7.') ? 'styled-tab-active' : 'styled-tab'}
            />
            <Tab
              value={9}
              label="GPA Forecaster"
              className={checkActiveModule('9.') ? 'styled-tab-active' : 'styled-tab'}
            />
            <Tab value={8} label="Colleges" className="styled-tab" />
          </Tabs>
          <CustomTabPanel value={value} index={1}>
            <RPersonalInformation setCurrentModuleId={setCurrentModuleId} isCounselor />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <RFamily setCurrentModuleId={setCurrentModuleId} isCounselor />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={3}>
            <REducation setCurrentModuleId={setCurrentModuleId} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={4}>
            <RTests setCurrentModuleId={setCurrentModuleId} isCounselor />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={5}>
            <RCoursesAndGrades setCurrentModuleId={setCurrentModuleId} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={6}>
            <RActivitiesAndHonors setCurrentModuleId={setCurrentModuleId} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={7}>
            <RAdditionalInfo setCurrentModuleId={setCurrentModuleId} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={8}>
            <RCollege setCurrentModuleId={setCurrentModuleId} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={9}>
            <RGPAForecaster setCurrentModuleId={setCurrentModuleId} />
          </CustomTabPanel>
        </Grid>
        <Grid item xs={12} md={4} sx={{ marginTop: { md: '48px' } }}>
          <ConversationCard checkModule={checkedData} currentModuleId={currentModuleId} tabValue={value} updateCheckModule={setCheckedData} />
        </Grid>
      </Grid>

      <Modal show={showModalNoStudent} onHide={() => setShowModalNoStudent(false)}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>There is not any student to review</Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => setShowModalNoStudent(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};


type ConversationCardProps = {
  checkModule: any
  currentModuleId: any;
  tabValue: number;
  updateCheckModule: (e: any) => void;
};

export const ConversationCard = ({checkModule, currentModuleId, tabValue, updateCheckModule}: ConversationCardProps) => {
  const params: { id: string } = useParams();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [checkedData, setCheckedData] = React.useState(checkModule);  
  const [partnerInfo, setParnerInfo] = useState<any>(null);  
  const [notesData, setNotesData] = React.useState<NoteModal[]>([]);
  const [counselorApproved, setCounselorApproved] = React.useState(false);  
  const [runCount, setRunCount] = useState(0);  
  const personalStore = useAppSelector((store) => store.basicInformation.myBasicInformation);  
  const store = useAppSelector((store) => store.counselor); 
  const studentList = useAppSelector((store) => store.counselor.studentList);
  const studentInfo = studentList.find(
    (item) => item.student_id.toString() === params?.id
  )?.student_info;

  let token = Cookies.get(authenticateSettings.tokenName);
  let studentId = Cookies.get('userId');

  const formik = useFormik({
    initialValues: initValues,
    onSubmit: (values) => {
      token = Cookies.get(authenticateSettings.tokenName);
      if (token) {
        var creatorName = [
          personalStore.first_name,
          personalStore.middle_name,
          personalStore.last_name,
        ].join(' ');
  
  
        if (studentInfo?.email) {
          collegeServices.sendMailChat({
            content: values.content,
            sender: creatorName,
            email: studentInfo?.email,
            receiver: studentInfo?.first_name,
            linkPage: `${tabContent[tabValue]} > ${moduleName[currentModuleId]}`,
          });
        }

        let arr = {
          ...checkedData,
          [currentModuleId]: [checkedData?.[currentModuleId]?.[0], counselorApproved],
        };

        let idToQuery = store.studentList.find(
          (item) => item.student_id.toString() === params?.id
        )?.id;
  
        counselorServices.updatePartnerById({
          id: idToQuery,
          notes_array: JSON.stringify({
            checkModule: arr,
          }),
        }).then((res:any)=>{
              counselorServices.sendChatNotesMessage({
                module_id: currentModuleId, 
                couserlor_id: studentId,
                student_id: studentInfo.student_id,
                creator: creatorName,
                from_id: studentId,
                to_id: studentInfo.student_id,
                content: values.content,
                status: values.status
              }).then((res2:any)=>{
                counselorServices.getChatNotesMessage(`${currentModuleId}|${studentId}|${studentInfo.student_id}`)
                  .then((arr:any) => {
                    setNotesData(arr);
                    //if (currentModuleId.includes("2.")){
                      // referesh Household tabs.
                      dispatch(getHousehold(params.id));
                    //}
                    //if (currentModuleId.includes("4.")) {
                      dispatch(getGeneral(params.id));
                    //}
                })
            });
        });
        formik.resetForm();
        getPartnerDataById(false);
      }
      else {
        handleLogout();
      }
    },
  });

  const handleLogout = () => {
    dispatch(logout());
    Cookies.remove(authenticateSettings.tokenName);
    Cookies.remove('userId');
    history.push(routers.login.path);
  };

  const getPartnerDataById = async (onInit: boolean) => {
    if (params?.id !== ':id') {
      let idToQuery = store.studentList.find(
        (item) => item.student_id.toString() === params?.id
      )?.id;
      idToQuery &&
        counselorServices.getPartnerById(idToQuery).then((res: any) => {
          setParnerInfo(res);

          counselorServices.getChatNotesMessage(`${currentModuleId}|${res.partner_id}|${res.student_id}`)
            .then((arr:any) => {
              setNotesData(arr);
              //if (currentModuleId.includes("2.")){
                // referesh Household tabs.
                 dispatch(getHousehold(params.id));
              //}

              //if (currentModuleId.includes("4.")) {
                dispatch(getGeneral(params.id));
              //}
          });

          const loadedCheckModule =
            checkEnableParseJSON(res?.notes_array, {
              checkModule,
              notes: [],
            })?.checkModule ?? checkModule;
          setCheckedData(loadedCheckModule);
          updateCheckModule(loadedCheckModule);
          dispatch(setCheckModule(loadedCheckModule));

          counselorServices.getPartner(studentId).then((res: any) => {
            dispatch(setParent(res));
          });

          if (onInit) {
            setCounselorApproved(loadedCheckModule?.[currentModuleId]?.[1]);
          }   

        });
      
    }
  }

  React.useEffect(() => {
    getPartnerDataById(true);
  }, [params?.id, store.studentList]);

  React.useEffect(() => {
    getPartnerDataById(true);
  }, [currentModuleId]);

  function refreshChatNotes(){
    setTimeout(function(){
      setRunCount(runCount+1);
      if (partnerInfo) {
        getPartnerDataById(false);
      }
    }, 1000);
  }

  React.useEffect(() => {
    refreshChatNotes();
  }, [runCount]);

  return (
    <Card sx={{ backgroundColor: 'white', m: 2, p: 2 }}>
    <div className="w-100 ">
      <Typography className="fw-bold mx-1 d-block w-100">
        Student: {[studentInfo?.first_name, studentInfo?.last_name].join(' ')}
      </Typography>
      <Typography className="fw-bold mx-1 d-block w-100">
        Counselor: {[personalStore?.first_name, personalStore?.last_name].join(' ')}
      </Typography>
    </div>
    <Typography className=" mx-1 d-block w-100">
      {moduleName[currentModuleId]} Status
    </Typography>
    <Row>
      <CheckboxInput
        disabled
        label={'Student reviewed'}
        name={'isStudentReviewed'}
        values={checkedData?.[currentModuleId]?.[0]} 
      />
      <CheckboxInput
        label={'Counselor approved'}
        name={'isCounselorApproved'}
        values={counselorApproved}
        onChange={(e) => {
          setCounselorApproved(e.target.checked);          
          formik.setFieldValue(
            'content',
            e.target.checked ? 'Counselor Marked Approved' : 'Counselor UN-checked Approved'
          );
        }}        
      />
      <TextAreaInput
        onChange={formik.handleChange}
        name="content"
        values={formik.values.content}
        mdCol="12"
        rows={2}
      />
      <Col md={12}>
        <Button
          variant="primary"
          className="py2 w-100"
          type="submit"
          onClick={() => formik.handleSubmit()}
        >
          Submit
        </Button>
      </Col>
    </Row>
    <Typography className="fw-bold fs-5 mx-2">Notes</Typography>
    <Card sx={{ backgroundColor: '#F7F8FA', m: 1, p: 2 }}>
      {(notesData ?? [])
        .filter((item) => item.conversation_id.split('|')[0] === currentModuleId)
        .map((item, index) => (
          <Row key={index} className="my-2">
            <Col md={12}>
              <Typography className="fw-bold fs-6">{item.creator}</Typography>
            </Col>

            <Col md={12}>
              <Typography className="text-field-light d-inline">
                {moment(item.created_at).format('MM/DD/YYYY - hh:mm A')}
              </Typography>
            </Col>
            <Col md={12}>
              <Typography
                dangerouslySetInnerHTML={{
                  __html: item.content.replace(/\n/g, ' <br> '),
                }}
              />
            </Col>
          </Row>
        ))}
    </Card>
  </Card>
  )
}
export default ReviewStudent;
