/* eslint-disable react-hooks/exhaustive-deps */
import { checkEnableParseJSON, formatDate, setArrayLength } from 'components/helper/helper';
import React from 'react';
import ItemContent, { CheckboxContent } from './ItemContent';
import moment from 'moment';

interface Props {
  dataValues: any;
  label?: string;
  isLegal?: boolean;
}

const StepParentSum = (props: Props) => {
  const dataValues = props.isLegal
    ? checkEnableParseJSON(props.dataValues)
    : {
        ...props.dataValues,
        csu_info: checkEnableParseJSON(props.dataValues?.csu_info),
      };
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    if (dataValues) setLoading(false);
  }, [dataValues]);
  const [institutions, setInstitutions] = React.useState<string[]>([]);
  React.useEffect(() => {
    const collegeArray =
      checkEnableParseJSON(dataValues?.parent_graduation_college_array ?? []) ?? [];
    const result = collegeArray.map((e: any, index: number) => {
      if (!e) return '';
      const degreeList = setArrayLength(
        [
          e?.degreesReceived1,
          e?.degreesReceived2,
          e?.degreesReceived3,
          e?.degreesReceived4,
          e?.degreesReceived5,
        ],
        e?.degreesNumber
      );
      return {
        isNew: e?.school?.id ? false : true,
        name: `${e?.school?.name} - ${degreeList.join(',')} `,
      };
    });
    setInstitutions(result);
  }, [props.dataValues]);
  return loading ? (
    <></>
  ) : (
    <>
      <div className="border w-100 my-3" />
      {!props.isLegal && (
        <>
          <ItemContent
            label={`${props.label} is married to*`}
            values={dataValues?.step_parent_is_married}
          />
          <div />

          <ItemContent label={`${props.label} type*`} values={dataValues?.step_parent_type} />
          <div />
          <ItemContent
            label={`Is ${props.label} living?*`}
            values={dataValues?.step_parent_is_living}
          />
          <ItemContent label="Date deceased*" values={dataValues?.date_deceased} />
        </>
      )}
      <div />
      <ItemContent label="Prefix" values={dataValues?.prefix} />

      <ItemContent label="First/Given name" values={dataValues?.first_name} />
      <ItemContent label="Middle Initial" values={dataValues?.middle_name} />
      <ItemContent label="Last Name" values={dataValues?.last_name} />
      <ItemContent label="Former Last Name" values={dataValues?.former_last_name} />

      <ItemContent label="Suffix" values={dataValues?.suffix} />
      <ItemContent label="Relationship to you*" values={dataValues?.step_parent_relationship} />
      <ItemContent label="Email Address *" values={dataValues?.step_parent_email} />
      <ItemContent label="Preferred phone" values={dataValues?.phone_number_type} />
      <ItemContent label="Country Code" values={dataValues?.country_code} />
      <ItemContent values={dataValues?.phone_number} label="Phone Number" />
      <div className="fs-5">Authorization</div>
      <ItemContent
        mdCol={12}
        values={dataValues?.csu_info?.is_give_permission_communicate_to}
        label="Do you give permission for the admission office to communicate with your Parent/Guardian regarding your application?"
      />
      <ItemContent
        mdCol={12}
        values={dataValues?.csu_info?.is_give_permission_to_receive_information}
        label="Do you give permission for your parent/guardian to receive university communications? These may include new student and housing information, parent activities, volunteer and charitable opportunities, and general campus information."
      />

      <ItemContent
        mdCol={12}
        values={dataValues?.csu_info?.authorize_parent_guardian_as_emergency_contact}
        label="I authorize the use of this parent/guardian information to be used as my emergency contact when I enroll at a CSU."
      />
      <CheckboxContent
        values={dataValues?.csu_info?.is_authorized_UC_to_share_contact_information}
        label="I authorize the University of California to share my parent's contact information."
      />

      <div className="border w-100 my-3" />
      <div className="my-2 fs-5">Employment</div>

      <>
        <ItemContent
          mdCol={6}
          label="What is this parent's current job status?"
          values={dataValues?.step_parent_current_job_status}
        />
        <ItemContent mdCol={6} values={dataValues?.employment_status} label="Employment status*" />
        <ItemContent
          mdCol={6}
          label="How many years has this parent been a stay-at-home parent?"
          values={dataValues?.number_of_year_at_home}
        />
        <ItemContent mdCol={6} label="Current Employer" values={dataValues?.current_employer} />

        <div className="m-2 fs-5 text-field-light">
          Most recent/Former job held by this parent info:
        </div>
        <ItemContent
          mdCol={6}
          label="Occupation (former occupation, if retire)"
          values={dataValues?.occupation}
        />
        <ItemContent mdCol={6} label="Job Category" values={dataValues?.job_category} />
        <ItemContent mdCol={6} label="Job title" values={dataValues?.job_title} />
        <ItemContent
          mdCol={6}
          label="Number of years at job"
          values={dataValues?.number_of_year_at_job}
        />
        <ItemContent
          mdCol={6}
          label="Is parent currently employed by or retired from a college or university?"
          values={dataValues?.parent_currently_employed_or_retire_from_college}
        />
        <ItemContent
          mdCol={6}
          values={
            checkEnableParseJSON(dataValues?.college_or_university)
              ? checkEnableParseJSON(dataValues?.college_or_university)?.name
              : dataValues?.college_or_university
          }
          label="College/University employer lookup"
          textColor={checkEnableParseJSON(dataValues?.college_or_university)?.id ? '#4d88f0' : 'black'}
        />
        <div className="border w-100 my-3" />
        <div className="fs-5 text-field-light">Education</div>
        <ItemContent values={dataValues?.education_level} mdCol={6} label="Education Level" />
        {institutions && (
          <>
            <ItemContent
              values={institutions?.length}
              mdCol={6}
              label="Total number of institutions attended"
            />
            {
              // @ts-ignore
              <>
                <div className="m-2 text-field-light">
                  Institutions attended and degrees received
                </div>
                {institutions.map((e: any, index: number) => (
                  <ItemContent
                    key={index}
                    mdCol={12}
                    label={``}
                    values={e.name}
                    textColor={e.isNew ? 'black' : '#4d88f0'}
                  />
                ))}
              </>
            }
          </>
        )}
        {/* {dataValues?.occupation === 'Other' && (
          <ItemContent
            mdCol={6}
            label="Other Occupation Details"
            values={dataValues?.other_occupation_detail}
          />
        )}

        {dataValues?.parent_current_job_status === 'Not-employed' && (
          <ItemContent
            mdCol={6}
            label="How many years has this parent not been employed?"
            values={dataValues?.number_of_year_not_employed}
          />
        )}
        {dataValues?.parent_current_job_status === 'Retired' && (
          <ItemContent
            mdCol={6}
            label="How many years has this parent been retired?"
            values={dataValues?.number_of_year_retired}
          />
        )} */}
      </>

      {/* <ItemContent
        label="Occupation (former occupation, if retired or deceased)"
        values={dataValues?.occupation}
      />
      <ItemContent label="Other Occupation Details" values={dataValues?.other_occupation_detail} />
      <ItemContent values={dataValues?.employment_status} label="Employment status*" />
      <ItemContent
        label="Is parent currently employed by or retired from a college or university?"
        values={dataValues?.currently_employed_or_retire_from_college}
        mdCol={12}
      />
      <ItemContent
        values={dataValues?.college_or_university}
        label="College/University employer lookup"
      />
      <ItemContent label="Name of Current Employer" values={dataValues?.current_employer} />

      <ItemContent label="Institutions attended" values={institutions} mdCol={12} /> */}

      <div className="border w-100 my-3" />

      <div className="my-2 fs-5">Residency</div>
      <ItemContent
        mdCol={6}
        label="Is your parent/guardian a current permanent resident of California?"
        values={dataValues?.csu_info?.isCurrentPermanentResidentOfCalifornia}
      />
      <ItemContent
        hidden={dataValues?.csu_info?.isCurrentPermanentResidentOfCalifornia !== 'true'}
        mdCol={6}
        label="Has your parent/guardian lived in California continuously since birth?"
        values={dataValues?.csu_info?.isLivedInCaliforniaContinuouslySinceBirth}
      />
      <ItemContent
        hidden={dataValues?.csu_info?.isLivedInCaliforniaContinuouslySinceBirth !== 'false'}
        mdCol={6}
        label="When did his/her current stay in California begin?"
        values={formatDate(dataValues?.csu_info?.whenStayInCaliforniaBegin)}
      />
      <ItemContent
        hidden={dataValues?.csu_info?.isLivedInCaliforniaContinuouslySinceBirth !== 'false'}
        mdCol={6}
        label="Prior Country"
        values={dataValues?.csu_info?.priorCountry}
      />
      <ItemContent
        hidden={dataValues?.csu_info?.isLivedInCaliforniaContinuouslySinceBirth !== 'false'}
        mdCol={6}
        label="Prior State/Territory"
        values={dataValues?.csu_info?.priorState}
      />
      <ItemContent
        mdCol={6}
        hidden={dataValues?.csu_info?.isLivedInCaliforniaContinuouslySinceBirth !== 'false'}
        label="From when to when did he/she live there"
        values={formatDate(dataValues?.csu_info?.fromWhenToWhenLiveThere)}
      />
      <ItemContent
        hidden={dataValues?.csu_info?.isLivedInCaliforniaContinuouslySinceBirth !== 'false'}
        mdCol={6}
        label="To"
        values={formatDate(dataValues?.csu_info?.ToWhenToWhenLiveThere)}
      />
      <ItemContent
        mdCol={6}
        hidden={dataValues?.csu_info?.isCurrentPermanentResidentOfCalifornia !== 'false'}
        label="Which U.S. State/possession does your parent/guardian regard as her permanent home?"
        values={dataValues?.csu_info?.stateRegardAsPermanentHome}
      />
      {dataValues.csu_info?.isCurrentPermanentResidentOfCalifornia === 'false' &&
        moment(dataValues.csu_info?.ToWhenToWhenLiveThere)
          .subtract(6, 'months')
          .isBefore(moment(dataValues.csu_info?.fromWhenToWhenLiveThere)) &&
        moment(dataValues.csu_info?.ToWhenToWhenLiveThere).isAfter(
          moment(dataValues.csu_info?.fromWhenToWhenLiveThere)
        ) && (
          <>
            <div>Second Prior Residency (For prior residencies lasting less than 6 months)</div>
            <ItemContent
              mdCol={6}
              label="Prior Country"
              values={dataValues.csu_info?.priorCountry_secondPriorResidency}
            />
            <ItemContent
              mdCol={6}
              label="Prior State/Territory"
              values={dataValues.csu_info?.priorState_secondPriorResidency}
            />
            <ItemContent
              mdCol={6}
              label="From when to when did he/she live there"
              values={formatDate(dataValues.csu_info?.fromWhenToWhenLiveThere_secondPriorResidency)}
            />
            <ItemContent
              mdCol={6}
              label="To"
              values={formatDate(dataValues.csu_info?.ToWhenToWhenLiveThere_secondPriorResidency)}
            />
          </>
        )}
    </>
  );
};

export default StepParentSum;
