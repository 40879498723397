/* eslint-disable react-hooks/exhaustive-deps */
import LoadingContent from 'components/LoadingContent';
import { useFormik } from 'formik';
import React from 'react';
import TopProcessBar from 'screen/selectCollege/TopProcessBar';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import { Col, Container, Form, Row, Spinner, Button } from 'react-bootstrap';
import YesNoSwitch from 'components/input/YesNoSwitch';
import SelectInput from 'components/input/SelectInput';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import BasicContact from './components/BasicContact';
import Employment from './components/Employment';
import Education from './components/Education';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { routers } from 'router/router';
import { logout } from 'app/basicInformationSlice/basicInformationSlice';
import { authenticateSettings } from 'components/helper/appConfig';
import { getHousehold, getParent2, updateParent2 } from 'app/family/familySlice';
import { BoolToString, checkEnableParseJSON, formatBoolean } from 'components/helper/helper';
import { parent1Rules } from './validation';
import TextInput from 'components/input/textInput';
import DatePickerInput from 'components/input/DatePicker';
import { debounce } from 'lodash';
import Residency from './components/Residency';
import GroupRadioButton from 'components/input/GroupRadioButton';
import collegeServices from 'services/collegeServices';
import familyServices from 'services/familyServices';

interface Props { }

const Parent2 = (props: Props) => {
  const [loading, setLoading] = React.useState(true);
  const dispatch = useAppDispatch();
  const history = useHistory();
  let studentId = Cookies.get('userId');
  let token = Cookies.get(authenticateSettings.tokenName);
  const [currentParentCollege, setCurrentParentCollege] = React.useState<any[]>([]);

  const family = useAppSelector((store) => store.family);
  const formik = useFormik({
    initialValues: {
      is_parent_2_living: '',
      date_deceased: '',
      relationship_type: '',
      relationship_to_you: '',
      prefix: '',
      first_name: '',
      middle_name: '',
      last_name: '',
      former_last_name: '',
      suffix: '',
      birth_country: '',
      prefered_email: '',
      country_code: '',
      phone_number: '',
      phone_number_type: '',
      parent_address_is: '',
      parent_current_job_status: '',
      job_category: '',
      employment_status: '',
      place_of_work: '',
      ocupation_and_position_title: '',
      occupation: '',
      parent_currently_employed_or_retire_from_college: 'Never employed at a college/university',
      education_level: '',
      highest_level_education: '',
      other_relationship_to_you_free_text: '',
      different_parent_address_country: '',
      different_parent_address_line_1: '',
      different_parent_address_line_2: '',
      different_parent_address_city: '',
      different_parent_address_state: '',
      different_parent_address_zip_code: '',
      college_or_university: {
        name: '',
        country: '',
        address_line_1: '',
        address_line_2: '',
        city: '',
        state: '',
        zip_code: '',
        province: '',
        state_or_province: '',
      },
      current_employer: '',
      job_title: '',
      number_of_year_at_home: '',
      number_of_year_not_employed: '',
      number_of_year_retired: '',
      csu_info: {
        is_give_permission_communicate_to: '',
        is_give_permission_to_receive_information: '',
        isCurrentPermanentResidentOfCalifornia: '',
        isLivedInCaliforniaContinuouslySinceBirth: '',
        whenStayInCaliforniaBegin: '',
        priorCountry: '',
        priorState: '',
        fromWhenToWhenLiveThere: '',
        ToWhenToWhenLiveThere: '',
        stateRegardAsPermanentHome: '',
        priorCountry_secondPriorResidency: '',
        priorState_secondPriorResidency: '',
        fromWhenToWhenLiveThere_secondPriorResidency: '',
        ToWhenToWhenLiveThere_secondPriorResidency: '',
        is_authorized_UC_to_share_contact_information: false,
      },
    },
    validationSchema: parent1Rules,
    onSubmit: async (values) => {
      try {
        studentId = Cookies.get('userId');
        token = Cookies.get(authenticateSettings.tokenName);
        if (token && studentId) {
          const newCollegeArray = formItem.values.parent_graduation_college_array.filter((item) => {
            const noSchoolId = !item?.school?.id;

            const allNull = Object.values(item).every(value => {
              if (value && typeof value === 'object') {
                return Object.values(value).every(val => !val);
              }
              return !value;
            });

            const notInCurrentParentCollege = !currentParentCollege.some(
              (currentItem) => currentItem.school.name === item.school.name
            );
            return noSchoolId && notInCurrentParentCollege && !allNull;
          });

          let newValues:any = {
            ...values,
            is_parent_2_living: formatBoolean(values.is_parent_2_living),
            total_number_institutions_attended: formItem.values.total_number_institutions_attended,
            parent_graduation_college_array: JSON.stringify(formItem.values.parent_graduation_college_array),
            college_or_university: JSON.stringify(values.college_or_university),
            csu_info: JSON.stringify(values.csu_info),
            student_id: parseInt(studentId || ''),
          };

          
          //#region Cleanup data before save
          if (newValues) {
            if (newValues.parent_currently_employed_or_retire_from_college === "Never employed at a college/university") {
               newValues.college_or_university = null;
            }
           }
          //#endregion

          await dispatch(updateParent2(newValues));

          if (newCollegeArray.length > 0) {
            const dataSendmail = {
              student_id: parseInt(studentId || ''),
              parent_name: 'Parent No.2',
              collegesInfo: newCollegeArray,
            }
            await collegeServices.sendMailNewParentCollege(dataSendmail);
          }

        }
        else {
          handleLogout();
        }
    }
    catch(ex){
      console.log('Error:', ex);
    }

    },
  });
  const formItem = useFormik({
    initialValues: {
      total_number_institutions_attended: '0',
      parent_graduation_college_array: [
        {
          degreesNumber: '',
          degreesReceived1: '',
          graduationYear1: '',
          degreesReceived2: '',
          graduationYear2: '',
          degreesReceived3: '',
          graduationYear3: '',
          degreesReceived4: '',
          graduationYear4: '',
          degreesReceived5: '',
          graduationYear5: '',
          school: {
            ceeb: '',
            fib_code: '',
            name: '',
            school_type: null,
            city: '',
            state: '',
            zip: '',
            country: '',
            csu_name: '',
            uc_name: '',
            cm_name: '',
            match_all: false,
            label: '',
            code: null,
            is_manual: false,
            id: '',
            // old fields :
            //   address_line_1: '',
            //   address_line_2: '',
            //   address_line_3: '',
            province: '',
            state_or_province: '',
            //   zip_code: '',
          } as any,
        },
      ],
      
    },
    onSubmit: async (values) => {
      formik.handleSubmit();
    },
  });

  const handleLogout = () => {
    dispatch(logout());
    Cookies.remove(authenticateSettings.tokenName);
    Cookies.remove('userId');
    history.push(routers.login.path);
  };

  const handleContinue = () => {
    const nextRoute = Object.keys(family.roleFamily).find(
      (key, index) =>
        index > Object.keys(family.roleFamily).indexOf('parentNo2') &&
        family.roleFamily[key] === true
    );
    formik.handleSubmit();
    history.push(nextRoute ? routers?.[nextRoute]?.path : routers.enrolimentStatus.path);
  };

  const getDetail = async () => {
    try {
      studentId = Cookies.get('userId');
      token = Cookies.get(authenticateSettings.tokenName);
      if (token && studentId) {
        setLoading(true);
        await dispatch(getHousehold(studentId));
        let res = await dispatch(getParent2(studentId));
        const dataLoaded: any = res.payload;
        if (dataLoaded) {
          formik.resetForm({
            values: {
              ...dataLoaded,
              is_parent_2_living: BoolToString(dataLoaded.is_parent_2_living),
              csu_info: {
                ...formik.values.csu_info,
                ...checkEnableParseJSON(dataLoaded.csu_info),
              },
              college_or_university: checkEnableParseJSON(dataLoaded.college_or_university) || {
                name: dataLoaded.college_or_university,
                id: dataLoaded.college_or_university,
                country: '',
                address_line_1: '',
                address_line_2: '',
                city: '',
                state: '',
                zip_code: '',
              },
            },
          });

          formItem.resetForm({
            values: {
              ...formItem.values,
              total_number_institutions_attended: dataLoaded.total_number_institutions_attended.toString(),
              parent_graduation_college_array: checkEnableParseJSON(dataLoaded.parent_graduation_college_array),
            },
          });

          if (dataLoaded.parent_graduation_college_array) {
            setCurrentParentCollege(checkEnableParseJSON(dataLoaded.parent_graduation_college_array));
          }
        }

        if (formik.values.csu_info?.isLivedInCaliforniaContinuouslySinceBirth !== 'false') {
          getContries();
        }
        setLoading(false);
        formik.setStatus('idle');

      }
      else {
        handleLogout();
      }
    }
    catch(ex){
      console.log('Error:', ex);
    }
  };

  const getContries = () => {
    familyServices.getContriesCSU()
      .then((res: any) => {
        let csuCountryTemp: any[] = [];
        res.data.map((item: any) => {
          csuCountryTemp.push(item.name);
        })
        formik.setFieldValue('csu_info.priorCountryOptions', csuCountryTemp);
        formik.setFieldValue('csu_info.objectCountries', res.data);
      })
      .catch((err: any) => {
        return err;
      });
  };

  React.useEffect(() => {
    getDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Auto save function ==========
  React.useEffect(() => {
    if (family.parent2!==null) {
        formik.dirty && debounceSaving();
    }
  }, [formik.values]);
  const debounceSaving = React.useCallback(
    debounce(() => {
      if (!loading) formik.handleSubmit();
    }, 1500),
    []
  );
  // Auto save function ==========
  return loading ? (
    <LoadingContent />
  ) : (
    <>
      <TopProcessBar
        stepProcess={[
          { title: 'General', percentOnProcess: 25 },
          { title: 'Relationship', percentOnProcess: 40 },
          { title: 'Basic', percentOnProcess: 55 },
          { title: 'Employment', percentOnProcess: 70 },
          { title: 'Education', percentOnProcess: 88 },
        ]}
        title="Family Information"
        process={1}
        icon={<PeopleAltOutlinedIcon />}
      />

      <Container className="main-container">
        <div className="px-2" id="General">
          <Form style={{ maxWidth: '900px' }} onSubmit={formik.handleSubmit}>
            <Row className="m-0">
              <div className="text-field-mid fs-3 mb-4">Parent No. 2</div>
              <Col md={6}>
                <div className="text-field-light">Is “Parent No.2” living?</div>
                <YesNoSwitch
                  // label="Is “Parent No.2” living?"
                  name="is_parent_2_living"
                  values={formik.values.is_parent_2_living}
                  onChange={formik.handleChange}
                />
              </Col>
              {formik.values.is_parent_2_living === 'false' && (
                <DatePickerInput
                  label="Date deceased*"
                  name="date_deceased"
                  values={formik.values.date_deceased}
                  onChange={(value) => formik.setFieldValue('date_deceased', value)}
                />
              )}
              <div className="border w-100 my-3" id="Relationship" />
              <div className="fs-5 p-1">Relationship</div>
              <GroupRadioButton
                label="Type"
                logo="span-cm-logo"
                values={formik.values.relationship_type}
                name="relationship_type"
                onChange={formik.handleChange}
                options={[
                  { value: 'Mother' },
                  { value: 'Father' },
                  { value: 'I have limited information about this parent', mdCol: 6 },
                  { value: 'I do not have another parent to list', mdCol: 6 },
                ]}
              />
              <SelectInput
                form={formik}
                option={[
                  { value: 'Father' },
                  { value: 'Mother' },
                  { value: 'Brother' },
                  { value: 'Sister' },
                  { value: 'Grandfather' },
                  { value: 'Grandmother' },
                  { value: 'Step Father' },
                  { value: 'Step Mother' },
                  { value: 'Legal Guardian' },
                  { value: 'Other' },
                ]}
                label="Relationship to you"
                name="relationship_to_you"
                values={formik.values.relationship_to_you}
                onChange={formik.handleChange}
              />
              {formik.values.relationship_to_you === 'Other' && (
                <>
                  <TextInput
                    label="Other*"
                    countText={64}
                    name="other_relationship_to_you_free_text"
                    values={formik.values.other_relationship_to_you_free_text}
                    onChange={formik.handleChange}
                  />
                </>
              )}
              <div className="border w-100 my-3" id="Basic" />
              <BasicContact formik={formik} />

              <div className="border w-100 my-3" id="Employment" />
              <Employment formik={formik} parentNumber={2} />

              <div className="border w-100 my-3" id="Education" />
              <Education formik={formik} formItem={formItem} />
              <Residency formik={formik} />
            </Row>
            <div className="my-3 d-flex justify-content-end border-top pt-3">
              <div className="col col-md-6 text-center text-md-end">
                <Button variant="primary" type="submit" className="p-2 px-5 mx-2">
                  {family.status === 'loading' ? (
                    <Spinner size="sm" animation="border" variant="light" />
                  ) : (
                    'Save'
                  )}
                </Button>
                <Button variant="primary" className="p-2 px-5" onClick={() => handleContinue()}>
                  Continue
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Container>
    </>
  );
};

export default Parent2;
