import React from 'react';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import { Button, Dropdown, ButtonGroup } from 'react-bootstrap';
import { routers } from 'router/router';
import { useHistory } from 'react-router';
import { useAppDispatch } from 'app/hooks';
import { getStripeSubscription, logout } from 'app/basicInformationSlice/basicInformationSlice';
import MobileMenu from './MobileMenu';
import MenuIcon from '@mui/icons-material/Menu';
import toast from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import { authenticateSettings } from 'components/helper/appConfig';
import Cookies from 'js-cookie';
import { renderTrialMessage } from './header';

interface Props {}
const MobileHeader = (props: Props) => {
  let location = useLocation();
  let history = useHistory();
  const dispatch = useAppDispatch();
  const [stripeSubscription, setStripeSubscription] = React.useState<any>(null);
  const handleLogout = () => {
    dispatch(logout());
    toast.success("You have been successfully logged out!");
    history.push(routers.login.path);
  };

  React.useEffect(() => {
    const getData = async() => {
      let id = Cookies.get('userId');
      let token = Cookies.get(authenticateSettings.tokenName);
      if (token && id) {
        let ss:any=  await dispatch(getStripeSubscription(id)).unwrap();
        setStripeSubscription(ss);
      }
    }

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <>
    <div className="gm-topbar">
      <span>
        <Dropdown as={ButtonGroup}>
          <Dropdown.Toggle variant="white" id="dropdown-split-basic">
            <MenuIcon />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => history.push(routers.settings.path)}>
              Settings
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleLogout()}>Logout</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </span>
      <span>
        <MobileMenu />
      </span>
      <span className="mx-3">
        <Button variant="outline-dart" className="border rounded" size="sm">
          <NotificationsOutlinedIcon />
        </Button>
      </span>
    </div>
    <div className="d-flex w-100 justify-content-center text-danger">
    { stripeSubscription && stripeSubscription?.status === 'trialing' && (
      <div className="d-flex text-danger text-center pl-3 align-self-center " style={{margin:'8px'}} >
        {`${renderTrialMessage(stripeSubscription)}`}
        {!location.pathname.includes('/payment') && (
          <>
          &nbsp;<a href="/#/payment">Click here to subscribe!</a>
          </>
        )}
      </div>
    )}
    </div>
    </>
  );
};

export default MobileHeader;
