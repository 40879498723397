/* eslint-disable react-hooks/exhaustive-deps */
import { style, width } from '@mui/system';
import { getStudent } from 'app/authorization/authorizationSlice';
import { getCurrentBasicDetail, getStripeSubscription, getStripeSubscriptionInfo, logout } from 'app/basicInformationSlice/basicInformationSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { authenticateSettings } from 'components/helper/appConfig';
import BillingOutlineIcon from 'components/icons/BillingOutlineIcon';
import SelectInput from 'components/input/SelectInput';
import TextIconInput from 'components/input/TextIconInput';
import TextInput from 'components/input/textInput';
import { STRIPE_PRICE_NAME, USER_ROLE } from 'constants/common';

import { useFormik } from 'formik';
import Cookies from 'js-cookie';
import { get } from 'lodash';
import React, { useEffect, useState } from "react";
import { Button, Form, Row } from "react-bootstrap";
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { routers } from 'router/router';
import PaymentServices from 'services/payment';


export const statusOptions = [
    { value: 'active', name: 'Active' },
    { value: 'canceled', name: 'Canceled' },
    { value: 'incomplete', name: 'Incomplete' },
    { value: 'incomplete_expired', name: 'Incomplete Expired' },
    { value: 'past_due', name: 'Past Due' },
    { value: 'paused', name: 'Paused' },
    { value: 'trialing', name: 'Trial Mode' },
    { value: 'unpaid', name: 'Unpaid' },
    { value: null, name: 'N/A' },
    { value: '', name: 'N/A' },
  ];

  
interface Props {}

const SettingBilling = (props: Props) => {
    let history = useHistory();
    const dispatch = useAppDispatch();
    const [paymentPlan, setPaymentPlan] = useState<any>(null);
    const personalStore = useAppSelector((store) => store.basicInformation.myBasicInformation);
    const subscriptInfo = useAppSelector((store) => store.basicInformation.stripeSubscriptionInfo);
    const userRole: string = (personalStore?.user_role ?? '').toLowerCase()?.trim();

    console.log('personalStore:', personalStore);
    console.log('userRole:', userRole);
    console.log('subscriptInfo:', subscriptInfo);

    function getPlanName(ret:any) {
        if (ret && ret.subcription_plan) {
            switch (ret.subcription_plan) {
              case STRIPE_PRICE_NAME.SilverMonth:
                return "Silver Month";
              case STRIPE_PRICE_NAME.SilverYear:
                return "Silver Month";
              case STRIPE_PRICE_NAME.GoldMonth:
                return "Gold Month";
              case STRIPE_PRICE_NAME.GoldYear:
                return "Gold Year";
              case STRIPE_PRICE_NAME.SilverPlusMonth:
                return "Silver Plus Month";
              case STRIPE_PRICE_NAME.SilverPlusYear:
                return "Silver Plus Month";
              default:
                return 'N/A'; //not applicable, not available
            }
        }
        return '';
    }
    
    function getStatusName(value: any) {
      if (value) {
        let ret:any =  statusOptions.filter( o=> o.value === value);
        return ret ? ret[0].name: '';
      }
      return '';
    }

    useEffect(() => {
        if (subscriptInfo) {
            setPaymentPlan(subscriptInfo);
        }

    }, [subscriptInfo]);

    useEffect(() => {
      async function getData() {
        let id = personalStore?.student_id;
        let token = Cookies.get(authenticateSettings.tokenName);
        try {
          if (token && id) {
            if (personalStore?.status !== 'loading') {
              try {
                await dispatch(getStudent(id)).unwrap();
                await dispatch(getCurrentBasicDetail(id)).unwrap();
                await dispatch(getStripeSubscription(id)).unwrap();
                await dispatch(getStripeSubscriptionInfo(id)).unwrap();
              }
              catch(ex){
                console.log('error:', ex);
                // force logout
                handleLogout(false);
              }
            }
        }
        else {
          // force logout
          handleLogout(false);
        }
        }
        catch(ex){
          console.log('Error: ', ex);      
        }
      }
      getData();
    },[]);

    const handleLogout = (showMsg:boolean) => {
      dispatch(logout());
      if (showMsg) {
        toast.success("You have been successfully logged out!");
      }
      history.push(routers.login.path);
    };

    async function getUserBillPortalUrl() {
        let returnUrl = `${window.location.origin}/#/settings?active-tab=5`;       
        let billUrl: any = await PaymentServices.getUserBillPortalUrl(returnUrl, personalStore.student_id);
        return billUrl;
    }
 
    const gotoUserPayment = () => {
        try {
            let path = `${routers.userPayment.path}`;
            history.push(path);
          return true;
          }
          catch(ex){
            console.log('Error: ', ex);
          }
          return false;
      }   

  const onClickStripeBilling = async() => {
      let billUrl:any = await getUserBillPortalUrl();
      if (billUrl) {
        window.location.href = `${billUrl}`;
      }
  }

  const onClickUpdatePaymentPlan = async () => {
      if (paymentPlan?.subcription_status === "canceled") {
        return gotoUserPayment();
      }
      let billUrl:any = await getUserBillPortalUrl();
      if (billUrl) {
        window.location.href = `${billUrl}`;
      }
      else {
        return gotoUserPayment();
      }
    }

    console.log('paymentPlan?.subcription_status', paymentPlan?.subcription_status);
    return (
        <div className="tab-wrap-panel">
            <div className="tab-panel2">
            <div className="tab-panel2-content">
                <Row className="p-0">
                  <div className="col-md-12">
                    <div className="d-flex align-items-center">
                      <div className="text-primary p-2 rounded-circle bg-primary-light d-flex align-items-center justify-content-center"
                        style={{ width: '35px', height: '35px', marginRight: '8px' }}
                      >
                        <BillingOutlineIcon
                          className="text-primray-light"
                          width={'18px'}
                          height={'18px'}
                        />
                      </div>
                      <h2 className="text-primary fs-4 fw-bold mx-1">Billing</h2>
                    </div>
                  </div>
                </Row>

                <Row className="m-3 p-0">
                    <TextIconInput
                        icon={''}
                        values={getPlanName(paymentPlan)}
                        name={'subcription_plan'}
                        label={'Payment Plan'}
                        readOnly={true}
                    />
                    <TextIconInput
                        icon={<></>}
                        className="txt-payment-status"
                        values={getStatusName(paymentPlan?.subcription_status)}
                        name={'subcription_status'}
                        label={'Payment Subcription Status'}
                        readOnly={true}
                    />

                    <div className="col-md-6 d-flex align-items-center">
                        <Button
                          className="pr-4 me-4"
                          variant="primary"
                          onClick={() => {
                              onClickStripeBilling();
                          }}
                          >
                          <span className="h5 fw-bold">Stripe Billing</span>
                        </Button>
                        <Button
                          variant="primary"
                          onClick={() => {
                              onClickUpdatePaymentPlan();
                          }}
                          >
                          <span className="h5 fw-bold">Update Plan</span>
                        </Button>
                    </div>

                </Row>

            </div>
            </div>
            <style scoped>{` 
.txt-payment-status{
  width: 210px;
}
      `}</style>
        </div>
  );
}

export default SettingBilling;