/* eslint-disable no-empty-pattern */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { checkEnableParseJSON } from 'components/helper/helper';
import toast from 'react-hot-toast';
import testingServices from 'services/testingServices';

const buildRole = (general: any) => {
  const all_tests_wish_report_array = checkEnableParseJSON(
    general?.all_tests_wish_report_array ?? {}
  );
  return {
    satTest: all_tests_wish_report_array?.is_SAT,                     //'SAT Tests'
    actTest: all_tests_wish_report_array?.is_ACT,                     //'ACT Tests'
    actSubjectTest: all_tests_wish_report_array?.is_AP_Subject,       //'AP Subject Tests' 
    ibSubjectTest: all_tests_wish_report_array?.is_IB_Subject,        //'IB Subject Tests'
    ieltsAndPte: all_tests_wish_report_array?.is_PTE || all_tests_wish_report_array?.is_IELTS,
    toeflTest: all_tests_wish_report_array?.is_TOEFL_Paper || all_tests_wish_report_array?.is_TOEFL,
    douLingoTest: all_tests_wish_report_array?.is_DuoLingo,
    clepTest: all_tests_wish_report_array?.is_CLEP,
  };
};
export interface TestingState {
  roleTest: any;
  myGeneral: any;
  mySATTest: any;
  myCLEP: any;
  mySATSubjectTest: any;
  myACTTest: any;
  myACTSubjectTest: any;
  myIELTSAndPTE: any;
  myTOEFL: any;
  myOtherTest: any;
  myIBSubjectTest: any;
  myDoulingo: any;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: TestingState = {
  roleTest: {
    satTest: false,
    actTest: false,
    actSubjectTest: false,
    ibSubjectTest: false,
    ieltsAndPte: false,
    toeflTest: false,
    douLingoTest: false,
    clepTest: false,
  },
  myGeneral: null,
  myCLEP: null,
  mySATTest: null,
  mySATSubjectTest: null,
  myACTTest: null,
  myIELTSAndPTE: null,
  myACTSubjectTest: null,
  myTOEFL: null,
  myOtherTest: null,
  myIBSubjectTest: null,
  myDoulingo: null,
  status: 'idle',
};
export const getGeneral: any = createAsyncThunk('testing/getGeneral', async (studentId: any, {rejectWithValue}) => {
  try {
    const response = await testingServices.getGeneral(studentId);
    return response;
  }
  catch (err) {
    const error = err as AxiosError; 
    if (!error.response) {
      throw err;
    }
    return rejectWithValue(error.response.data);
  }
});
export const updateGeneral: any = createAsyncThunk(
  'testing/updateGeneral',
  async (payload: any, { getState, rejectWithValue }) => {
    const state: any = await getState();
    const myGeneral = state.testing.myGeneral;
    try {
      if (myGeneral) {
        const response = await testingServices.updateGeneral(payload);
        return response.message ? { ...payload, message: response.message } : response;
      } else {
        const response = await testingServices.createGeneral(payload);
        return response.message ? { ...payload, message: response.message } : response;
      }
    }
    catch (err) {
      const error = err as AxiosError; 
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSATTest: any = createAsyncThunk('testing/getSATTest', async (studentId: any, {rejectWithValue}) => {
  try {
    const response = await testingServices.getSATTest(studentId);
    return response;
  }
  catch (err) {
    const error = err as AxiosError; 
    if (!error.response) {
      throw err;
    }
    return rejectWithValue(error.response.data);
  }
});

export const updateSATTest: any = createAsyncThunk(
  'testing/updateSATTest',
  async (payload: any, { getState, rejectWithValue }) => {
    const state: any = await getState();
    const mySATTest = state.testing.mySATTest;
    try {
      if (mySATTest) {
        const response = await testingServices.updateSATTest(payload);
        return response;
      } else {
        const response = await testingServices.createSATTest(payload);
        return response;
      }
    }
    catch (err) {
      const error = err as AxiosError; 
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCLEPTest: any = createAsyncThunk('testing/getCLEPTest', async (studentId: any, {rejectWithValue}) => {
  try {
    const response = await testingServices.getCLEPTest(studentId);
    return response;
    }
  catch (err) {
    const error = err as AxiosError; 
    if (!error.response) {
      throw err;
    }
    return rejectWithValue(error.response.data);
  }
});

export const updateCLEPTest: any = createAsyncThunk(
  'testing/updateCLEPTest',
  async (payload: any, { getState, rejectWithValue }) => {
    const state: any = await getState();
    const myCLEP = state.testing.myCLEP;
    try {
      if (myCLEP) {
        const response = await testingServices.updateCLEPTest(payload);
        return response;
      } else {
        const response = await testingServices.createCLEPTest(payload);
        return response;
      }
    }
    catch (err) {
      const error = err as AxiosError; 
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSATSubjectTest: any = createAsyncThunk(
  'testing/getSATSubjectTest',
  async (studentId: any, {rejectWithValue}) => {
    try {
      const response = await testingServices.getSATSubjectTest(studentId);
      return response;
    }
    catch (err) {
      const error = err as AxiosError; 
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateSATSubjectTest: any = createAsyncThunk(
  'testing/updateSATSubjectTest',
  async (payload: any, { getState, rejectWithValue }) => {
    const state: any = await getState();
    const mySATSubjectTest = state.testing.mySATSubjectTest;
    try {
      if (mySATSubjectTest) {
        const response = await testingServices.updateSATSubjectTest(payload);
        return response;
      } else {
        const response = await testingServices.createSATSubjectTest(payload);
        return response;
      }
    }
    catch (err) {
      const error = err as AxiosError; 
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getACTTest: any = createAsyncThunk('testing/getACTTest', async (studentId: any, {rejectWithValue}) => {
  try {
    const response = await testingServices.getACTTest(studentId);
    return response;
  }
  catch (err) {
    const error = err as AxiosError; 
    if (!error.response) {
      throw err;
    }
    return rejectWithValue(error.response.data);
  }
});
export const updateACTTest: any = createAsyncThunk(
  'testing/updateACTTest',
  async (payload: any, { getState, rejectWithValue }) => {
    const state: any = await getState();
    const myACTTest = state.testing.myACTTest;
    try {
      if (myACTTest) {
        const response = await testingServices.updateACTTest(payload);
        return response;
      } else {
        const response = await testingServices.createACTTest(payload);
        return response;
      }
    }
    catch (err) {
      const error = err as AxiosError; 
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getIELTSAndPTE: any = createAsyncThunk(
  'testing/getIELTSAndPTE',
  async (studentId: any, {rejectWithValue}) => {
    try {
      const response = await testingServices.getIELTSAndPTE(studentId);
      return response;
    }
    catch (err) {
      const error = err as AxiosError; 
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateIELTSAndPTE: any = createAsyncThunk(
  'testing/updateIELTSAndPTE',
  async (payload: any, { getState, rejectWithValue }) => {
    const state: any = await getState();
    const myIELTSAndPTE = state.testing.myIELTSAndPTE;
    try {
      if (myIELTSAndPTE) {
        const response = await testingServices.updateIELTSAndPTE(payload);
        return response;
      } else {
        const response = await testingServices.createIELTSAndPTE(payload);
        return response;
      }
    }
    catch (err) {
      const error = err as AxiosError; 
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const getACTSubjectTest: any = createAsyncThunk(
  'testing/getACTSubjectTest',
  async (studentId: any, {rejectWithValue}) => {
    try {
      const response = await testingServices.getACTSubjectTest(studentId);
      return response;
    }
    catch (err) {
      const error = err as AxiosError; 
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateACTSubjectTest: any = createAsyncThunk(
  'testing/updateACTSubjectTest',
  async (payload: any, { getState, rejectWithValue }) => {
    const state: any = await getState();
    const myACTSubjectTest = state.testing.myACTSubjectTest;
    try {
      if (myACTSubjectTest) {
        const response = await testingServices.updateACTSubjectTest(payload);
        return response;
      } else {
        const response = await testingServices.createACTSubjectTest(payload);
        return response;
      }
    }
    catch (err) {
      const error = err as AxiosError; 
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getIBSubjectTest: any = createAsyncThunk(
  'testing/getIBSubjectTest',
  async (studentId: any, {rejectWithValue}) => {
    try {
      const response = await testingServices.getIBSubjectTest(studentId);
      return response;
    }
    catch (err) {
      const error = err as AxiosError; 
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateIBSubjectTest: any = createAsyncThunk(
  'testing/updateIBSubjectTest',
  async (payload: any, { getState, rejectWithValue }) => {
    const state: any = await getState();
    const myIBSubjectTest = state.testing.myIBSubjectTest;
    try {
      if (myIBSubjectTest) {
        const response = await testingServices.updateIBSubjectTest(payload);
        return response;
      } else {
        const response = await testingServices.createIBSubjectTest(payload);
        return response;
      }
    }
    catch (err) {
      const error = err as AxiosError; 
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const getTOEFL: any = createAsyncThunk('testing/getTOEFL', async (studentId: any, {rejectWithValue}) => {
  try {
    const response = await testingServices.getTOEFL(studentId);
    return response;
  }
  catch (err) {
    const error = err as AxiosError; 
    if (!error.response) {
      throw err;
    }
    return rejectWithValue(error.response.data);
  }
});
export const updateTOEFL: any = createAsyncThunk(
  'testing/updateTOEFL',
  async (payload: any, { getState, rejectWithValue }) => {
    const state: any = await getState();
    const myTOEFL = state.testing.myTOEFL;
    try {
      if (myTOEFL) {
        const response = await testingServices.updateTOEFL(payload);
        return response;
      } else {
        const response = await testingServices.createTOEFL(payload);
        return response;
      }
    }
    catch (err) {
      const error = err as AxiosError; 
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getOtherTest: any = createAsyncThunk(
  'testing/getOtherTest',
  async (studentId: any, {rejectWithValue}) => {
    try {
      const response = await testingServices.getOtherTest(studentId);
      return response;
    }
    catch (err) {
      const error = err as AxiosError; 
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateOtherTest: any = createAsyncThunk(
  'testing/updateOtherTest',
  async (payload: any, { getState, rejectWithValue }) => {
    const state: any = await getState();
    const myOtherTest = state.testing.myOtherTest;
    try {
      if (myOtherTest) {
        const response = await testingServices.updateOtherTest(payload);
        return response;
      } else {
        const response = await testingServices.createOtherTest(payload);
        return response;
      }
    }
    catch (err) {
      const error = err as AxiosError; 
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const getDoulingo: any = createAsyncThunk('testing/getDoulingo', async (studentId: any) => {
  const response = await testingServices.getDoulingo(studentId);
  return response;
});
export const updateDoulingo: any = createAsyncThunk(
  'testing/updateDoulingo',
  async (payload: any, { getState, rejectWithValue }) => {
    const state: any = await getState();
    const myDoulingo = state.testing.myDoulingo;
    try {
      if (myDoulingo) {
        const response = await testingServices.updateDoulingo(payload);
        return response;
      } else {
        const response = await testingServices.createDoulingo(payload);
        return response;
      }
    }
    catch (err) {
      const error = err as AxiosError; 
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const testingSlice = createSlice({
  name: 'testing',
  initialState,
  reducers: {},
  extraReducers: {
    [getGeneral.pending]: (state) => {
      state.status = 'loading';
    },
    [getGeneral.rejected]: (state) => {
      state.status = 'failed';
    },
    [getGeneral.fulfilled]: (state, action) => {
      state.myGeneral = action.payload;
      state.roleTest = buildRole(action.payload);
      state.status = 'idle';
    },
    //
    [updateGeneral.pending]: (state) => {
      state.status = 'loading';
    },
    [updateGeneral.rejected]: (state) => {
      state.status = 'failed';
    },
    [updateGeneral.fulfilled]: (state, action) => {
      toast.success(action.payload.message);
      state.myGeneral = action.payload;
      state.roleTest = buildRole(action.payload);
      state.status = 'idle';
    },
    //
    [getSATTest.pending]: (state) => {
      state.status = 'loading';
    },
    [getSATTest.rejected]: (state) => {
      state.status = 'failed';
    },
    [getSATTest.fulfilled]: (state, action) => {
      state.mySATTest = action.payload;
      state.status = 'idle';
    },
    //
    [updateSATTest.pending]: (state) => {
      state.status = 'loading';
    },
    [updateSATTest.rejected]: (state) => {
      state.status = 'failed';
    },
    [updateSATTest.fulfilled]: (state, action) => {
      toast.success(action.payload.message);
      state.mySATTest = action.payload;
      state.status = 'idle';
    },
    //
    [getCLEPTest.pending]: (state) => {
      state.status = 'loading';
    },
    [getCLEPTest.rejected]: (state) => {
      state.status = 'failed';
    },
    [getCLEPTest.fulfilled]: (state, action) => {
      state.myCLEP = action.payload;
      state.status = 'idle';
    },
    //
    [updateCLEPTest.pending]: (state) => {
      state.status = 'loading';
    },
    [updateCLEPTest.rejected]: (state) => {
      state.status = 'failed';
    },
    [updateCLEPTest.fulfilled]: (state, action) => {
      toast.success(action.payload.message);
      state.myCLEP = action.payload;
      state.status = 'idle';
    },
    //
    [getSATSubjectTest.pending]: (state) => {
      state.status = 'loading';
    },
    [getSATSubjectTest.rejected]: (state) => {
      state.status = 'failed';
    },
    [getSATSubjectTest.fulfilled]: (state, action) => {
      state.mySATSubjectTest = action.payload;
      state.status = 'idle';
    },
    //
    [updateSATSubjectTest.pending]: (state) => {
      state.status = 'loading';
    },
    [updateSATSubjectTest.rejected]: (state) => {
      state.status = 'failed';
    },
    [updateSATSubjectTest.fulfilled]: (state, action) => {
      toast.success(action.payload.message);
      state.mySATSubjectTest = action.payload;
      state.status = 'idle';
    },
    //
    [getACTTest.pending]: (state) => {
      state.status = 'loading';
    },
    [getACTTest.rejected]: (state) => {
      state.status = 'failed';
    },
    [getACTTest.fulfilled]: (state, action) => {
      state.myACTTest = action.payload;
      state.status = 'idle';
    },
    //
    [updateACTTest.pending]: (state) => {
      state.status = 'loading';
    },
    [updateACTTest.rejected]: (state) => {
      state.status = 'failed';
    },
    [updateACTTest.fulfilled]: (state, action) => {
      toast.success(action.payload.message);
      state.myACTTest = action.payload;
      state.status = 'idle';
    },
    //
    [getACTSubjectTest.pending]: (state) => {
      state.status = 'loading';
    },
    [getACTSubjectTest.rejected]: (state) => {
      state.status = 'failed';
    },
    [getACTSubjectTest.fulfilled]: (state, action) => {
      state.myACTSubjectTest = action.payload;
      state.status = 'idle';
    },
    //
    [updateACTSubjectTest.pending]: (state) => {
      state.status = 'loading';
    },
    [updateACTSubjectTest.rejected]: (state) => {
      state.status = 'failed';
    },
    [updateACTSubjectTest.fulfilled]: (state, action) => {
      toast.success(action.payload.message);
      state.myACTSubjectTest = action.payload;
      state.status = 'idle';
    },
    //

    [getIBSubjectTest.pending]: (state) => {
      state.status = 'loading';
    },
    [getIBSubjectTest.rejected]: (state) => {
      state.status = 'failed';
    },
    [getIBSubjectTest.fulfilled]: (state, action) => {
      state.myIBSubjectTest = action.payload;
      state.status = 'idle';
    },
    //
    [updateIBSubjectTest.pending]: (state) => {
      state.status = 'loading';
    },
    [updateIBSubjectTest.rejected]: (state) => {
      state.status = 'failed';
    },
    [updateIBSubjectTest.fulfilled]: (state, action) => {
      toast.success(action.payload.message);
      state.myIBSubjectTest = action.payload;
      state.status = 'idle';
    },

    //

    [getIELTSAndPTE.pending]: (state) => {
      state.status = 'loading';
    },
    [getIELTSAndPTE.rejected]: (state) => {
      state.status = 'failed';
    },
    [getIELTSAndPTE.fulfilled]: (state, action) => {
      state.myIELTSAndPTE = action.payload;
      state.status = 'idle';
    },
    //
    [updateIELTSAndPTE.pending]: (state) => {
      state.status = 'loading';
    },
    [updateIELTSAndPTE.rejected]: (state) => {
      state.status = 'failed';
    },
    [updateIELTSAndPTE.fulfilled]: (state, action) => {
      toast.success(action.payload.message);
      state.myIELTSAndPTE = action.payload;
      state.status = 'idle';
    },
    //
    [getTOEFL.pending]: (state) => {
      state.status = 'loading';
    },
    [getTOEFL.rejected]: (state) => {
      state.status = 'failed';
    },
    [getTOEFL.fulfilled]: (state, action) => {
      state.myTOEFL = action.payload;
      state.status = 'idle';
    },
    //
    [updateTOEFL.pending]: (state) => {
      state.status = 'loading';
    },
    [updateTOEFL.rejected]: (state) => {
      state.status = 'failed';
    },
    [updateTOEFL.fulfilled]: (state, action) => {
      toast.success(action.payload.message);
      state.myTOEFL = action.payload;
      state.status = 'idle';
    },
    //
    [getOtherTest.pending]: (state) => {
      state.status = 'loading';
    },
    [getOtherTest.rejected]: (state) => {
      state.status = 'failed';
    },
    [getOtherTest.fulfilled]: (state, action) => {
      state.myOtherTest = action.payload;
      state.status = 'idle';
    },
    //
    [updateOtherTest.pending]: (state) => {
      state.status = 'loading';
    },
    [updateOtherTest.rejected]: (state) => {
      state.status = 'failed';
    },
    [updateOtherTest.fulfilled]: (state, action) => {
      toast.success(action.payload.message);
      state.myOtherTest = action.payload;
      state.status = 'idle';
    },
    //
    [getDoulingo.pending]: (state) => {
      state.status = 'loading';
    },
    [getDoulingo.rejected]: (state) => {
      state.status = 'failed';
    },
    [getDoulingo.fulfilled]: (state, action) => {
      state.myDoulingo = action.payload;
      state.status = 'idle';
    },
    //
    [updateDoulingo.pending]: (state) => {
      state.status = 'loading';
    },
    [updateDoulingo.rejected]: (state) => {
      state.status = 'failed';
    },
    [updateDoulingo.fulfilled]: (state, action) => {
      toast.success(action.payload.message);
      state.myDoulingo = action.payload;
      state.status = 'idle';
    },
  },
});

export const {} = testingSlice.actions;
