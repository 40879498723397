import PasswordInput from 'components/input/PasswordInput';
import TextIconInput from 'components/input/TextIconInput';
import SelectInput from 'components/input/SelectInput';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import React from 'react';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Form, Row, Button, Card, CloseButton, Col, Spinner } from 'react-bootstrap';
import { mediumPasswordRegexp, strongPasswordRegexp } from 'components/helper/helper';
import CheckboxInput from 'components/input/CheckboxInput';
import { useAppSelector } from 'app/hooks';
import TextInput from 'components/input/textInput';
import { USER_ROLE } from 'constants/common';
interface Props {
  onNextStep: (step: number) => void;
  formik: any;
}

export interface passwordStrengthProps {
  title: string;
  content: string;
  status: string;
}

const FormRegister = ({ formik, onNextStep }: Props) => {
  const finishYearOptions = [1, 2, 3, 4, 5].map((i) => i + new Date().getFullYear());
  const authorizationStore = useAppSelector((store) => store.authorization);
  const [passwordStrength, setPasswordStrength] = React.useState<passwordStrengthProps | undefined>(
    undefined
  );
  const [agreeTerm, setAgreeTerm] = React.useState(false);
  
  React.useEffect(() => {
    if (formik.values.password.length > 1) {
      if (strongPasswordRegexp.test(formik.values.password)) {
        setPasswordStrength({
          title: 'Strong Password',
          content: 'Great! This would do, now just don’t forget it.',
          status: 'text-success',
        });
      } else if (mediumPasswordRegexp.test(formik.values.password)) {
        setPasswordStrength({
          title: 'Medium Password',
          content:
            'This password look good. Add one lowercase letter , one uppercase letter , one digit , one special character for stronger.',
          status: 'text-warning',
        });
      } else {
        setPasswordStrength({
          title: 'Weak Password',
          content: 'You password is too weak.',
          status: 'text-danger',
        });
      }
    }
  }, [formik.values.password]);
  return (
    <>
      <Form>
        <Row className="m-3 p-0">
          <TextIconInput
            icon={<PersonOutlineIcon />}
            mdCol={'6'}
            label={'First name'}
            name={'first_name'}
            values={formik.values.first_name}
            onChange={formik.handleChange}
            feedback={
              formik.errors.first_name && (
                <span className="text-danger">{formik.errors.first_name}</span>
              )
            }
          />
          <TextIconInput
            icon={<PersonOutlineIcon />}
            mdCol={'6'}
            label={'Last Name'}
            name={'last_name'}
            values={formik.values.last_name}
            onChange={formik.handleChange}
            feedback={
              formik.errors.last_name && (
                <span className="text-danger">{formik.errors.last_name}</span>
              )
            }
          />
          <SelectInput
            form={formik}
            values={formik.values.user_role}
            onChange={formik.handleChange}
            name={'user_role'}
            label={'User Role'}
            option={[
              { value: USER_ROLE.student, name: 'Student' },
              { value: USER_ROLE.counselor, name: 'Counselor' },
            ]}
            feedback={
              formik.errors.user_role && (
                <span className="text-danger">{formik.errors.user_role}</span>
              )
            }
          />
          <SelectInput
            hidden={formik.values.user_role !== USER_ROLE.student}
            form={formik}
            values={formik.values.year_finish_high_school}
            onChange={formik.handleChange}
            name={'year_finish_high_school'}
            label={'When will you finish highschool?'}
            option={finishYearOptions}
            feedback={
              formik.errors.year_finish_high_school && (
                <span className="text-danger">{formik.errors.year_finish_high_school}</span>
              )
            }
          />
          <TextInput
            hidden={formik.values.user_role !== USER_ROLE.counselor}
            mdCol={'6'}
            label={'What school or organization do work with?'}
            name={'school_or_organization_work_with'}
            values={formik.values.school_or_organization_work_with}
            onChange={formik.handleChange}
            feedback={
              formik.errors.school_or_organization_work_with && (
                <span className="text-danger">
                  {formik.errors.school_or_organization_work_with}
                </span>
              )
            }
          />

          <TextIconInput
            icon={<MailOutlineIcon />}
            mdCol={'6'}
            label={'Email'}
            name={'email'}
            values={formik.values.email}
            onChange={formik.handleChange}
            feedback={
              formik.errors.email && <span className="text-danger">{formik.errors.email}</span>
            }
          />
          <div />

          <PasswordInput
            mdCol={'6'}
            label={'Password'}
            name={'password'}
            values={formik.values.password}
            onChange={formik.handleChange}
            feedback={
              formik.errors.password && (
                <span className="text-danger">{formik.errors.password}</span>
              )
            }
          />
          <PasswordInput
            mdCol={'6'}
            label={'Password Confirmation'}
            name={'password_confirm'}
            values={formik.values.password_confirm}
            onChange={formik.handleChange}
            feedback={
              formik.errors.password_confirm && (
                <span className="text-danger">{formik.errors.password_confirm}</span>
              )
            }
          />
          {passwordStrength && (
            <Col sm={12}>
              <Card className="my-3 p-1 py-0 border" body>
                <div className="d-flex justify-content-between">
                  <span className={`h5 ${passwordStrength.status}`}>{passwordStrength.title}</span>
                  <CloseButton onClick={() => setPasswordStrength(undefined)} />
                </div>
                <p className="h6">{passwordStrength.content}</p>
              </Card>
            </Col>
          )}
        </Row>
        {/* <div className="w-100 d-flex align-items-center my-5">
          <div className="border mb-1" style={{ width: 'calc(50% - 20px)' }} />
          <div className="text-field-light text-center" style={{ width: '40px' }}>
            OR
          </div>
          <div className="border mb-1" style={{ width: 'calc(50% - 20px)' }} />
        </div>
        <div className="w-100 text-center my-4">
          <GoogleLogin
            clientId="822424685897-0gq4kp64obvug9k3odsmttmb4vmg9hei.apps.googleusercontent.com"
            buttonText="Create account with Google"
            cookiePolicy={'single_host_origin'}
            onSuccess={(response) => {}}
            onFailure={(response) => {}}
            style={{ padding: '3rem', borderRadius: '20px' }}
          />
        </div> */}
        <div className="mx-3">
          <CheckboxInput
            mdCol={'6'}
            label={
              <span>
                By creating an account you agree with our
                <a
                  className="p-0 mx-1 pb-1"
                  href="#/public/privacy-policies"
                  target="_blank"
                  style={{ textDecoration: 'none' }}
                >
                  terms and conditions.
                </a>
              </span>
            }
            name={'password_confirm'}
            values={agreeTerm}
            onChange={(e) => setAgreeTerm(e.target.checked)}
          />
        </div>
        <div className="mx-3 mb-5">
          {authorizationStore.status ? (
            <Button
              disabled={!agreeTerm}
              className="w-100 p-2 my-3"
              variant="primary"
              onClick={() => {
                  if (formik.values.user_role === USER_ROLE.counselor) {
                    formik.handleSubmit();
                    onNextStep(4); 
                  }
                  else {
                    onNextStep(2)
                  }
              }}
            >
              <span className="h5 fw-bold">Next</span>
            </Button>
          ) : (
            <Button className="w-100 p-2 my-3" variant="primary">
              <Spinner size="sm" animation="border" variant="light" />
            </Button>
          )}
        </div>
      </Form>
    </>
  );
};

export default FormRegister;
