/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Col, Form } from 'react-bootstrap';

interface Props {
  hidden?: boolean;
  tooltip?: string;
  mdCol?: string;
  label?: string;
  name: string;
  values: any;
  onChange?: (e: any) => void;
  isValid?: boolean;
  feedback?: any;
  placeholder?: string;
  disabled?: boolean;
  countText?: number;
  isOverCount?: boolean;
  rows?: number;
  className?: string;
  countWord?: number;
  maxLenght?: number;
  logo?: string;
}

const TextAreaInput = (props: Props) => {
  const [counter, setCounter] = React.useState(0);
  const [counterWord, setCounterWord] = React.useState(0);
  const [classOverText, setClassOverText] = React.useState(''); 
 
  const changeColorOverCount = () => {
    setClassOverText('');
    if (props.isOverCount && props.countText && (props.values.length ?? 0) > props.countText) {
      setClassOverText('text-danger');
    }
  }

  const getInfo = () =>{
    const wordCount = (props.values || "").split(/\s+/).filter(Boolean).length;
      if (props.values?.length && props.countText) {
        setCounter(props.values.length ?? 0);
      } else if (props.countWord) {
        setCounterWord(wordCount ?? 0);
      }
      changeColorOverCount();
  }
  React.useEffect(() => {
    getInfo();
  }, [props.values]);

  React.useEffect(() => {
    changeColorOverCount();
  }, []);


  const handleChange = async (e: any) => {
    try {
        if (!props.isOverCount && props.countText && e.target.value.length > props.countText) {
            return;
        }

      const wordCount = (e.target.value|| "").split(/\s+/).filter(Boolean).length;
      if (!props.isOverCount && props.countWord && wordCount > props.countWord) return;
  
      // prevent add more space
      if (!props.isOverCount && props.countWord && wordCount === props.countWord) {
        if (e.target.value[e.target.value.length-1] === ' ') return;
      }
    
      props.onChange && props.onChange(e);
    }
    catch(ex){
      console.log('Error:', ex);
    }

  };
  return props.hidden ? null : (
    <Form.Group
      name={props.name}
      className={`p-1 p-md-1 ${props.className ?? ''}`.trim()}
      as={Col}
      md={props.mdCol || '6'}
    >
      {props.label && (
        <Form.Label style={{ fontSize: '1rem', color: '#4E5A6F' }} className={props.logo}>
          {props.label}
        </Form.Label>
      )}
      <div className="text-field-light" style={{ fontSize: '0.8rem' }}>
        {props.tooltip}
      </div>
      <Form.Control
        className={`${classOverText}`.trim()}
        disabled={props.disabled}
        placeholder={props.placeholder}
        name={props.name}
        value={props.values ?? ''}
        maxLength={props.isOverCount ? undefined: props.countText}
        onChange={handleChange}
        isValid={props.isValid}
        as="textarea"
        rows={props.rows || 3}
      />
      <div className="sized70 text-field-light w-100 d-flex justify-content-between mh-1rem">
        <span>{props.feedback}</span>
        {props.countText && (
          <span title={`${counter}/${props.countText} characters`}>
            {counter}/{props.countText} characters 
          </span>
        )}
        {props.countWord && (
          <span title={`${counterWord}/${props.countWord} words`}>
            {counterWord}/{props.countWord} words 
          </span>
        )}
      </div>
    </Form.Group>
  );
};

export default TextAreaInput;
